<template>
  <div>
    <div v-if="!expand" class="bg-menu" @click.stop="expand = true">
      <div v-if="compact" class="my-1 px-4">
        <p class="ma-0">
          Mais opções
          <span
            ><v-icon color="white" size="small"
              >mdi-plus</v-icon
            ></span
          >
        </p>
      </div>
      <div v-else class="py-1 px-3">
        <p class="ma-0">
          <span
            ><v-icon color="white" size="small"
              >mdi-plus</v-icon
            ></span
          >
        </p>
      </div>
    </div>

    <div v-else class="mt-n8">
      <div class="bg-menu float-right" @click.stop="expand = false">
        <div class="py-1 px-3">
          <p class="ma-0">
            <span
              ><v-icon color="white" size="small"
                >mdi-chevron-up</v-icon
              ></span
            >
          </p>
        </div>
      </div>
      <div class="clear pa-1"></div>
      <div
        v-for="(menu, i) in menus"
        @mouseleave="over = null"
        @mouseover="over = i"
        @click.stop="handleClick(menu.id)"
        :key="i"
        class="float-right mt-3 ml-2 menu-option px-3"
      >
        <p class="ma-0 font-weight-bold">
          <span class="mr-2"
            ><v-icon :color="over == i ? 'white' : 'blue'">{{
              menu.icon
            }}</v-icon></span
          >
          {{ menu.title }}
        </p>
      </div>
    </div>
    <div class="clear pb-5"></div>
  </div>
</template>

<script>
export default {
  name: "ExpandMenu",
  props: {
    menus: Array,
    // [{
    //     title: String,
    //     icon: String
    // }]
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      over: null,
    };
  },
  methods: {
    handleClick(i) {
      this.$emit("click-menu", i);
      this.expand = false;
    },
  },
};
</script>

<style scoped>
.bg-menu {
  margin-top: -20px;
  cursor: pointer;
  background: linear-gradient(135deg, #0346b6, #1491ff);
  background-color: #1491ff;
  display: table;
  color: white;
  border-radius: 14px;
}

.clear {
  clear: both;
}

/* .mt-n10 {
  margin-top: -12px;
} */

.menu-option {
  border: 2px solid #1491ff;
  color: #1491ff;
  border-radius: 6px;
  cursor: pointer;
}

.menu-option:hover {
  background: linear-gradient(135deg, #0346b6, #1491ff);
  background-color: #1491ff;
  display: table;
  color: white;
}
</style>
