export const departaments = (option, short) => {

    let list = [
        {
          name: "CBIOTEC - DEPARTAMENTO DE BIOLOGIA CELULAR E MOLECULAR - João Pessoa",
          short: "CBIOTEC - DBCM",
          id: 1863
        },
        {
          name: "CBIOTEC - DEPARTAMENTO DE BIOTECNOLOGIA - João Pessoa",
          short: "CBIOTEC - DB",
          id: 1862
        },
        {
          name: "CCA - DEPARTAMENTO DE BIOCIÊNCIAS - Areia",
          short: "CCA-DB",
          id: 1624
        },
        {
          name: "CCA - DEPARTAMENTO DE CIÊNCIAS FUNDAMENTAIS E SOCIAIS - Areia",
          short: "CCA-DCFS",
          id: 1471
        },
        {
          name: "CCA - DEPARTAMENTO DE CIÊNCIAS VETERINÁRIAS - Areia",
          short: "CCA-DCV",
          id: 1625
        },
        {
          name: "CCA - DEPARTAMENTO DE FITOTECNIA E CIÊNCIAS AMBIENTAIS - Areia",
          short: "CCA-DFCA",
          id: 1469
        },
        {
          name: "CCA - DEPARTAMENTO DE QUÍMICA E FÍSICA - Areia",
          short: "CCA - DQF",
          id: 3197
        },
        {
          name: "CCA - DEPARTAMENTO DE SOLOS E ENGENHARIA RURAL - Areia",
          short: "CCA-DSER",
          id: 1468
        },
        {
          name: "CCA - DEPARTAMENTO DE ZOOTECNIA - Areia",
          short: "CCA-DZOOT",
          id: 1470
        },
        {
          name: "CCA - PROGRAMA DE PÓS-GRADUAÇÃO EM ZOOTECNIA - Areia",
          short: "PPGZ",
          id: 3605
        },
        {
          name: "CCAE - COORDENAÇÃO DE HOTELARIA E GASTRONOMIA - Rio Tinto",
          short: "CCAE - CHG",
          id: 2664
        },
        {
          name: "CCAE - DEPARTAMENTO DE CIÊNCIAS EXATAS - Rio Tinto",
          short: "CCAE - DCE",
          id: 1637
        },
        {
          name: "CCAE - DEPARTAMENTO DE CIÊNCIAS SOCIAIS - Rio Tinto",
          short: "CCAE - DCS",
          id: 1634
        },
        {
          name: "CCAE - DEPARTAMENTO DE CIÊNCIAS SOCIAIS APLICADAS - Rio Tinto",
          short: "CCAE - DCSA",
          id: 1766
        },
        {
          name: "CCAE - DEPARTAMENTO DE DESIGN - Rio Tinto",
          short: "CCAE - DD",
          id: 1865
        },
        {
          name: "CCAE - DEPARTAMENTO DE EDUCAÇÃO - Rio Tinto",
          short: "CCAE - DED",
          id: 1635
        },
        {
          name: "CCAE - DEPARTAMENTO DE ENGENHARIA E MEIO AMBIENTE - Rio Tinto",
          short: "CCAE - DEMA",
          id: 1636
        },
        {
          name: "CCAE - DEPARTAMENTO DE HOTELARIA E GASTRONOMIA - Rio Tinto",
          short: "CCAE - DHG",
          id: 1866
        },
        {
          name: "CCAE - DEPARTAMENTO DE LETRAS - Rio Tinto",
          short: "CCAE - DL",
          id: 1764
        },
        {
          name: "CCEN - COORDENAÇÃO DE CIÊNCIAS BIOLÓGICAS (BACHARELADO) - João Pessoa",
          short: "CCEN - CBB",
          id: 3185
        },
        {
          name: "CCEN - DEPARTAMENTO DE BIOLOGIA MOLECULAR - João Pessoa",
          short: "CCEN-DBM",
          id: 1339
        },
        {
          name: "CCEN - DEPARTAMENTO DE ESTATÍSTICA - João Pessoa",
          short: "CCEN-DE",
          id: 1341
        },
        {
          name: "CCEN - DEPARTAMENTO DE FÍSICA - João Pessoa",
          short: "CCEN-DF",
          id: 1335
        },
        {
          name: "CCEN - DEPARTAMENTO DE GEOCIÊNCIAS - João Pessoa",
          short: "CCEN-DG",
          id: 1336
        },
        {
          name: "CCEN - DEPARTAMENTO DE MATEMÁTICA - João Pessoa",
          short: "CCEN-DM",
          id: 1337
        },
        {
          name: "CCEN - DEPARTAMENTO DE QUÍMICA - João Pessoa",
          short: "CCEN-DQ",
          id: 1338
        },
        {
          name: "CCEN - DEPARTAMENTO DE SISTEMÁTICA E ECOLOGIA - João Pessoa",
          short: "CCEN-DSE",
          id: 1342
        },
        {
          name: "CCHLA - DEPARTAMENTO DE CIÊNCIAS SOCIAIS - João Pessoa",
          short: "CCHLA - DCS",
          id: 1347
        },
        {
          name: "CCHLA - DEPARTAMENTO DE FILOSOFIA - João Pessoa",
          short: "CCHLA - DF",
          id: 1348
        },
        {
          name: "CCHLA - DEPARTAMENTO DE HISTÓRIA - João Pessoa",
          short: "CCHLA - DH",
          id: 1354
        },
        {
          name: "CCHLA - DEPARTAMENTO DE LETRAS CLÁSSICAS E VERNÁCULAS - João Pessoa",
          short: "CCHLA - DLCV",
          id: 1349
        },
        {
          name: "CCHLA - DEPARTAMENTO DE LETRAS ESTRANGEIRAS E MODERNAS - João Pessoa",
          short: "CCHLA - DLEM",
          id: 1350
        },
        {
          name: "CCHLA - DEPARTAMENTO DE LÍNGUA PORTUGUESA E LINGUÍSTICA - João Pessoa",
          short: "CCHLA - DLPL",
          id: 3398
        },
        {
          name: "CCHLA - DEPARTAMENTO DE LÍNGUAS DE SINAIS - João Pessoa",
          short: "CCHLA - DLS",
          id: 3290
        },
        {
          name: "CCHLA - DEPARTAMENTO DE MEDIAÇÕES INTERCULTURAIS - João Pessoa",
          short: "CCHLA - DMI",
          id: 3081
        },
        {
          name: "CCHLA - DEPARTAMENTO DE MÍDIAS DIGITAIS - João Pessoa",
          short: "CCHLA - DEMID",
          id: 1627
        },
        {
          name: "CCHLA - DEPARTAMENTO DE PSICOLOGIA - João Pessoa",
          short: "CCHLA - DP",
          id: 1351
        },
        {
          name: "CCHLA - DEPARTAMENTO DE SERVIÇO SOCIAL - João Pessoa",
          short: "CCHLA - DSS",
          id: 1353
        },
        {
          name: "CCHSA - CAVN - COLÉGIO AGRÍCOLA VIDAL DE NEGREIROS - Bananeiras",
          short: "CCHSA - CAVN",
          id: 1768
        },
        {
          name: "CCHSA - COORDENAÇÃO DE CIÊNCIAS AGRÁRIAS - Bananeiras",
          short: "CCHSA - CCCA",
          id: 2397
        },
        {
          name: "CCHSA - DEPARTAMENTO DE AGRICULTURA - Bananeiras",
          short: "CCHSA - DA",
          id: 2876
        },
        {
          name: "CCHSA - DEPARTAMENTO DE CIÊNCIA ANIMAL - Bananeiras",
          short: "CCHSA - DCA",
          id: 2877
        },
        {
          name: "CCHSA - DEPARTAMENTO DE CIÊNCIAS BÁSICAS E SOCIAIS - Bananeiras",
          short: "CCHSA-DCBS",
          id: 1475
        },
        {
          name: "CCHSA - DEPARTAMENTO DE CIÊNCIAS SOCIAIS APLICADAS - Bananeiras",
          short: "CCHSA-DCSA",
          id: 1609
        },
        {
          name: "CCHSA - DEPARTAMENTO DE EDUCAÇÃO - Bananeiras",
          short: "CCHSA-DE",
          id: 1767
        },
        {
          name: "CCHSA - DEPARTAMENTO DE GESTÃO E TECNOLOGIA AGROINDUSTRIAL - Bananeiras",
          short: "CCHSA-DGTA",
          id: 1623
        },
        {
          name: "CCJ - COORDENAÇÃO DE DIREITO (SANTA RITA) - João Pessoa",
          short: "CCJ- CDSR",
          id: 1821
        },
        {
          name: "CCJ - DEPARTAMENTO DE CIÊNCIAS JURÍDICAS - João Pessoa",
          short: "CCJ-DCJUR",
          id: 1578
        },
        {
          name: "CCJ - DEPARTAMENTO DE DIREITO PRIVADO - João Pessoa",
          short: "CCJ-DDP",
          id: 1391
        },
        {
          name: "CCJ - DEPARTAMENTO DE DIREITO PROCESSUAL E PRÁTICA JURÍDICA - João Pessoa",
          short: "CCJ-DPPF",
          id: 1566
        },
        {
          name: "CCJ - DEPARTAMENTO DE DIREITO PÚBLICO - João Pessoa",
          short: "CCJ - DDP",
          id: 1390
        },
        {
          name: "CCM - DEPARTAMENTO DE CIRURGIA - João Pessoa",
          short: "CCM-DCI",
          id: 1617
        },
        {
          name: "CCM - DEPARTAMENTO DE DOENÇAS INFECCIOSAS, PARASITÁRIAS E INFLAMATÓRIAS - João Pessoa",
          short: "CCM - DIPI",
          id: 3572
        },
        {
          name: "CCM - DEPARTAMENTO DE MEDICINA INTERNA - João Pessoa",
          short: "CCM-DMI",
          id: 1616
        },
        {
          name: "CCM - DEPARTAMENTO DE OBSTETRÍCIA E GINECOLOGIA - João Pessoa",
          short: "CCM-DOG",
          id: 1618
        },
        {
          name: "CCM - DEPARTAMENTO DE PEDIATRIA E GENÉTICA - João Pessoa",
          short: "CCM-DPG",
          id: 1619
        },
        {
          name: "CCM - DEPARTAMENTO DE PROMOÇÃO DA SAÚDE - João Pessoa",
          short: "CCM-DPS",
          id: 1620
        },
        {
          name: "CCS - COORDENAÇÃO DE ODONTOLOGIA - João Pessoa",
          short: "CCS - ODONTO",
          id: 2175
        },
        {
          name: "CCS - CURSO DE ESPECIALIZAÇÃO EM CUIDADOS PALIATIVOS - João Pessoa",
          short: "CCS - CECP",
          id: 3317
        },
        {
          name: "CCS - DEPARTAMENTO DE CIÊNCIAS FARMACÊUTICAS - João Pessoa",
          short: "CCS - DCF",
          id: 1364
        },
        {
          name: "CCS - DEPARTAMENTO DE CLÍNICA E ODONTOLOGIA SOCIAL - João Pessoa",
          short: "CCS - DCOS",
          id: 1359
        },
        {
          name: "CCS - DEPARTAMENTO DE EDUCAÇÃO FÍSICA - João Pessoa",
          short: "CCS - DEF",
          id: 1369
        },
        {
          name: "CCS - DEPARTAMENTO DE ENFERMAGEM CLÍNICA - João Pessoa",
          short: "CCS - DENC",
          id: 1371
        },
        {
          name: "CCS - DEPARTAMENTO DE ENFERMAGEM EM SAÚDE COLETIVA - João Pessoa",
          short: "CCS - DESC",
          id: 1372
        },
        {
          name: "CCS - DEPARTAMENTO DE FISIOLOGIA E PATOLOGIA - João Pessoa",
          short: "CCS - DFP",
          id: 1365
        },
        {
          name: "CCS - DEPARTAMENTO DE FISIOTERAPIA - João Pessoa",
          short: "CCS - DFISIO",
          id: 1370
        },
        {
          name: "CCS - DEPARTAMENTO DE FONOAUDIOLOGIA - João Pessoa",
          short: "CCS - DFONO",
          id: 1608
        },
        {
          name: "CCS - DEPARTAMENTO DE MORFOLOGIA - João Pessoa",
          short: "CCS - DMORF",
          id: 1366
        },
        {
          name: "CCS - DEPARTAMENTO DE NUTRIÇÃO - João Pessoa",
          short: "CCS - DN",
          id: 1368
        },
        {
          name: "CCS - DEPARTAMENTO DE ODONTOLOGIA RESTAURADORA - João Pessoa",
          short: "CCS - DOR",
          id: 1360
        },
        {
          name: "CCS - DEPARTAMENTO DE TERAPIA OCUPACIONAL - João Pessoa",
          short: "CCS - DTO",
          id: 1629
        },
        {
          name: "CCSA - COORDENAÇÃO DE GESTÃO PÚBLICA - João Pessoa",
          short: "CCSA - CGP",
          id: 1607
        },
        {
          name: "CCSA - DEPARTAMENTO DE ADMINISTRACAO - João Pessoa",
          short: "CCSA - DA",
          id: 1330
        },
        {
          name: "CCSA - DEPARTAMENTO DE CIÊNCIA DA INFORMAÇÃO - João Pessoa",
          short: "CCSA - DCI",
          id: 1331
        },
        {
          name: "CCSA - DEPARTAMENTO DE ECONOMIA - João Pessoa",
          short: "CCSA - DE",
          id: 1329
        },
        {
          name: "CCSA - DEPARTAMENTO DE FINANÇAS E CONTABILIDADE - João Pessoa",
          short: "CCSA - DFC.",
          id: 1332
        },
        {
          name: "CCSA - DEPARTAMENTO DE GESTÃO PÚBLICA - João Pessoa",
          short: "CCSA-DGP",
          id: 1603
        },
        {
          name: "CCSA - DEPARTAMENTO DE RELACÕES INTERNACIONAIS - João Pessoa",
          short: "CCSA - DRI",
          id: 1612
        },
        {
          name: "CCTA - COORDENAÇÃO DE CINEMA E AUDIOVISUAL - João Pessoa",
          short: "CCTA - CCAV",
          id: 2805
        },
        {
          name: "CCTA - COORDENAÇÃO DE JORNALISMO - João Pessoa",
          short: "CCTA- CJ",
          id: 2650
        },
        {
          name: "CCTA - COORDENAÇÃO DE MÚSICA (BACHARELADO) - João Pessoa",
          short: "CCTA - CMUB",
          id: 2388
        },
        {
          name: "CCTA - COORDENAÇÃO DE REGÊNCIA DE BANDAS E FANFARRAS (SEQUENCIAL) - João Pessoa",
          short: "CCTA - CRBF",
          id: 2331
        },
        {
          name: "CCTA - COORDENAÇÃO DE RELAÇÕES PÚBLICAS - João Pessoa",
          short: "CCTA - CRP",
          id: 2651
        },
        {
          name: "CCTA - COORDENAÇÃO DE TEATRO (BACHARELADO) - João Pessoa",
          short: "CCTA - CT-B",
          id: 2585
        },
        {
          name: "CCTA - DEPARTAMENTO DE ARTES CÊNICAS - João Pessoa",
          short: "CCTA - DAC",
          id: 2612
        },
        {
          name: "CCTA - DEPARTAMENTO DE ARTES VISUAIS - João Pessoa",
          short: "CCTA-DAV",
          id: 2608
        },
        {
          name: "CCTA - DEPARTAMENTO DE COMUNICAÇÃO - João Pessoa",
          short: "CCTA - DECOM",
          id: 2384
        },
        {
          name: "CCTA - DEPARTAMENTO DE EDUCAÇÃO MUSICAL - João Pessoa",
          short: "CCTA - DEM",
          id: 2607
        },
        {
          name: "CCTA - DEPARTAMENTO DE JORNALISMO - João Pessoa",
          short: "CCTA - DJ",
          id: 3136
        },
        {
          name: "CCTA - DEPARTAMENTO DE MÚSICA - João Pessoa",
          short: "CCTA - DM",
          id: 2605
        },
        {
          name: "CCTA - DEPARTAMENTO DE TURISMO E HOTELARIA - João Pessoa",
          short: "CCTA - DTH",
          id: 2834
        },
        {
          name: "CE - COORDENAÇÃO DE PEDAGOGIA (EAD) - João Pessoa",
          short: "CE - CP",
          id: 2247
        },
        {
          name: "CE - DEPARTAMENTO DE CIÊNCIAS DAS RELIGIÕES - João Pessoa",
          short: "CE - DCR",
          id: 1622
        },
        {
          name: "CE - DEPARTAMENTO DE EDUCAÇÃO DO CAMPO - João Pessoa",
          short: "CE - DEC",
          id: 2883
        },
        {
          name: "CE - DEPARTAMENTO DE FUNDAMENTAÇÃO DA EDUCAÇÃO - João Pessoa",
          short: "CE - DFE",
          id: 1387
        },
        {
          name: "CE - DEPARTAMENTO DE HABILITAÇÃO PEDAGÓGICA - João Pessoa",
          short: "CE - DHP",
          id: 1386
        },
        {
          name: "CE - DEPARTAMENTO DE METODOLOGIA DA EDUCAÇÃO - João Pessoa",
          short: "CE - DME",
          id: 1385
        },
        {
          name: "CE - DEPARTAMENTO DE MÍDIAS NA EDUCAÇÃO - João Pessoa",
          short: "CE - DMED",
          id: 1630
        },
        {
          name: "CE - DEPARTAMENTO DE PSICOPEDAGOGIA - João Pessoa",
          short: "CE - DPSICO",
          id: 1628
        },
        {
          name: "CE - ESCOLA DE EDUCAÇÃO BÁSICA (CRECHE ESCOLA) - João Pessoa",
          short: "CE-CREESCOLA",
          id: 2242
        },
        {
          name: "CEAR - DEPARTAMENTO DE ENGENHARIA DE ENERGIAS RENOVÁVEIS - João Pessoa",
          short: "CEAR - DEER",
          id: 1855
        },
        {
          name: "CEAR - DEPARTAMENTO DE ENGENHARIA ELÉTRICA - João Pessoa",
          short: "CEAR - DEE",
          id: 2616
        },
        {
          name: "CENTRO PROFISSIONAL E TECNOLÓGICO - ESCOLA TÉCNICA DE SAÚDE - João Pessoa",
          short: "CPT-ETS",
          id: 1373
        },
        {
          name: "CI - DEPARTAMENTO DE COMPUTAÇÃO CIENTÍFICA - João Pessoa",
          short: "CI-DCC",
          id: 1859
        },
        {
          name: "CI - DEPARTAMENTO DE INFORMÁTICA - João Pessoa",
          short: "CI - DI",
          id: 2151
        },
        {
          name: "CI - DEPARTAMENTO DE SISTEMAS DE COMPUTAÇÃO - João Pessoa",
          short: "CI-DSC",
          id: 1858
        },
        {
          name: "CT - COORDENAÇÃO DE ENGENHARIA DE MATERIAIS - João Pessoa",
          short: "CT - CEMat",
          id: 2369
        },
        {
          name: "CT - DEPARTAMENTO DE ARQUITETURA E URBANISMO - João Pessoa",
          short: "CT - DAU",
          id: 1379
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA CIVIL E AMBIENTAL - João Pessoa",
          short: "CT-DECA",
          id: 1583
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA DE ALIMENTOS - João Pessoa",
          short: "CT-DEA",
          id: 1779
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA DE MATERIAIS - João Pessoa",
          short: "CT-DEMAT",
          id: 1575
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA DE PRODUÇÃO - João Pessoa",
          short: "CT - DEP",
          id: 1380
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA MECÂNICA - João Pessoa",
          short: "CT-DEM",
          id: 1377
        },
        {
          name: "CT - DEPARTAMENTO DE ENGENHARIA QUÍMICA - João Pessoa",
          short: "CT-DEQ",
          id: 1610
        },
        {
          name: "CTDR - COORDENAÇÃO DE TECNOLOGIA DE ALIMENTOS - João Pessoa",
          short: "CTDR - CTA",
          id: 2158
        },
        {
          name: "CTDR - DEPARTAMENTO DE GASTRONOMIA - João Pessoa",
          short: "CTDR - DGA",
          id: 2840
        },
        {
          name: "CTDR - DEPARTAMENTO DE TECNOLOGIA DE ALIMENTOS - João Pessoa",
          short: "CTDR-DTA",
          id: 2610
        },
        {
          name: "CTDR - DEPARTAMENTO DE TECNOLOGIA SUCROALCOOLEIRA - João Pessoa",
          short: "CTDR-DTS",
          id: 1602
        },
        {
          name: "DEPARTAMENTO DE ENGENHARIA CIVIL - CAMPINA GRANDE - João Pessoa",
          short: "DECCG",
          id: 3630
        },
        {
          name: "DEPARTAMENTO DE HISTÓRIA- CAMPINA GRANDE - Campina Grande",
          short: "DHCG",
          id: 3628
        },
        {
          name: "DEPARTAMENTO DE LETRAS - CAMPINA GRANDE - Campina Grande",
          short: "DCG",
          id: 3626
        },
        {
          name: "DEPARTAMENTO DE MEDICINA - CAMPINA GRANDE - Campina Grande",
          short: "DMDCCG",
          id: 3664
        },
        {
          name: "E-TEC - COLÉGIO AGRÍCOLA VIDAL DE NEGREIROS - Bananeiras",
          short: "E-TEC - CAVN",
          id: 3259
        },
        {
          name: "E-TEC - ESCOLA TÉCNICA DE SAÚDE - João Pessoa",
          short: "E-TEC - ETS",
          id: 3258
        },
        {
          name: "PROEX - NÚCLEO INTERDISCIPLINAR DE PESQUISA E EXTENSÃO EM ECONOMIA SOLIDÁRIA E EDUCAÇÃO POPULAR (NUPLAR) - João Pessoa",
          short: "PROEX - NUPLAR",
          id: 2874
        },
        {
          name: "PROGRAMA ASSOCIADO DE PÓS-GRADUAÇÃO EM ARTES VISUAIS - João Pessoa",
          short: "PPGAV",
          id: 1880
        },
        {
          name: "PROGRAMA ASSOCIADO DE PÓS-GRADUAÇÃO EM EDUCAÇÃO FÍSICA - João Pessoa",
          short: "PAPGEF",
          id: 2620
        },
        {
          name: "PROGRAMA ASSOCIADO DE PÓS-GRADUAÇÃO EM FONOAUDIOLOGIA - João Pessoa",
          short: "PPGFON",
          id: 3188
        },
        {
          name: "PROGRAMA ASSOCIADO DE PÓS-GRADUAÇÃO EM MATEMÁTICA - João Pessoa",
          short: "PAPGM",
          id: 1909
        },
        {
          name: "PROGRAMA DE DOUTORADO INTEGRADO EM ZOOTECNIA - Areia",
          short: "PDIZ",
          id: 2622
        },
        {
          name: "PROGRAMA DE MESTRADO PROFISSIONAL EM ARTES EM REDE NACIONAL - João Pessoa",
          short: "PROFARTES",
          id: 2862
        },
        {
          name: "PROGRAMA DE MESTRADO PROFISSIONAL EM ENSINO DE HISTÓRIA - João Pessoa",
          short: "PROFHISTORIA",
          id: 3487
        },
        {
          name: "PROGRAMA DE MESTRADO PROFISSIONAL EM GERONTOLOGIA - João Pessoa",
          short: "PMPG",
          id: 3142
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ADMINISTRAÇÃO - João Pessoa",
          short: "CCSA - PPGA",
          id: 1898
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM AGRONOMIA - Areia",
          short: "CCA - PPGA",
          id: 1869
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ANTROPOLOGIA - Rio Tinto",
          short: "PPGA",
          id: 1918
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ARQUITETURA E URBANISMO - João Pessoa",
          short: "PPGAU",
          id: 1872
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM BIODIVERSIDADE - Areia",
          short: "PPGBIO",
          id: 2833
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM BIOLOGIA CELULAR E MOLECULAR - João Pessoa",
          short: "PPBCM",
          id: 1873
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM BIOTECNOLOGIA (RENORBIO) - João Pessoa",
          short: "RENORBIO",
          id: 1924
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM BIOTECNOLOGIA - João Pessoa",
          short: "PGBiotecM",
          id: 2888
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA ANIMAL - Areia",
          short: "PPGCAN",
          id: 1925
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA DA INFORMAÇÃO - João Pessoa",
          short: "PPGCI",
          id: 1871
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA DO SOLO - Areia",
          short: "CCA - PPGCS",
          id: 1896
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA E ENGENHARIA DE MATERIAIS - João Pessoa",
          short: "PPCEM",
          id: 1919
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA E TECNOLOGIA DE ALIMENTOS - João Pessoa",
          short: "PPGCTA",
          id: 1874
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIA POLÍTICA E RELAÇÕES INTERNACIONAIS - João Pessoa",
          short: "CPRI",
          id: 3139
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS AGRÁRIAS (AGROECOLOGIA) - Bananeiras",
          short: "PPGCAG",
          id: 1926
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS BIOLÓGICAS - João Pessoa",
          short: "PPGCB",
          id: 1911
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS CONTÁBEIS - João Pessoa",
          short: "PPGCC",
          id: 3060
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS DA NUTRIÇÃO - João Pessoa",
          short: "PPGCN",
          id: 1887
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS DAS RELIGIÕES - João Pessoa",
          short: "PPGCR",
          id: 1902
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM CIÊNCIAS JURÍDICAS - João Pessoa",
          short: "PPGCJ",
          id: 1891
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM COMPUTAÇÃO, COMUNICAÇÃO E ARTES - João Pessoa",
          short: "PPGCCA",
          id: 3128
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM COMUNICAÇÃO - João Pessoa",
          short: "PPGC",
          id: 1890
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM DESENVOLVIMENTO E INOVAÇÃO TECNOLÓGICA EM MEDICAMENTOS - João Pessoa",
          short: "PPgDITM",
          id: 2302
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM DESENVOLVIMENTO E MEIO AMBIENTE - João Pessoa",
          short: "PRODEMA - MEST",
          id: 1878
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM DESENVOLVIMENTO E MEIO AMBIENTE EM ASSOCIAÇÃO A REDE PRODEMA - João Pessoa",
          short: "PRODEMA - DOUT",
          id: 3338
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM DIREITOS HUMANOS, CIDADANIA E POLITICAS PÚBLICAS - João Pessoa",
          short: "PPGDH",
          id: 1931
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ECOLOGIA E MONITORAMENTO AMBIENTAL - Rio Tinto",
          short: "PPGEMA",
          id: 1897
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ECONOMIA - João Pessoa",
          short: "PPGE",
          id: 1875
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ECONOMIA DO SETOR PÚBLICO - João Pessoa",
          short: "PPESP",
          id: 2707
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM EDUCAÇÃO - João Pessoa",
          short: "CE - PPGE",
          id: 1906
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENERGIAS RENOVÁVEIS - João Pessoa",
          short: "PPGER",
          id: 2706
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENFERMAGEM - João Pessoa",
          short: "PPGENF",
          id: 1913
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA CIVIL E AMBIENTAL - João Pessoa",
          short: "PPGECAM",
          id: 2619
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA DE PRODUÇÃO - João Pessoa",
          short: "PPGEP",
          id: 1868
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA DE PRODUÇÃO E SISTEMAS - João Pessoa",
          short: "PPGEPS",
          id: 3491
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA ELÉTRICA - João Pessoa",
          short: "PPGEE",
          id: 1885
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA MECÂNICA - João Pessoa",
          short: "PPGEM",
          id: 1870
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA QUÍMICA - João Pessoa",
          short: "PPGEQ",
          id: 3059
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENGENHARIA URBANA E AMBIENTAL - João Pessoa",
          short: "PPGEUA",
          id: 1899
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ENSINO DE BIOLOGIA EM REDE NACIONAL - João Pessoa",
          short: "PROFBIO",
          id: 3253
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM FILOSOFIA - João Pessoa",
          short: "PPGF",
          id: 1884
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM FISIOTERAPIA - João Pessoa",
          short: "PPGFIS",
          id: 3387
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM FÍSICA - João Pessoa",
          short: "CCEN - PPGF",
          id: 1892
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM GEOGRAFIA - João Pessoa",
          short: "CCEN - PPGG",
          id: 1889
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM GESTÃO NAS ORGANIZAÇÕES APRENDENTES - João Pessoa",
          short: "MPGOA",
          id: 1917
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM GESTÃO PÚBLICA E COOPERAÇÃO INTERNACIONAL - João Pessoa",
          short: "PGPCI",
          id: 3144
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM HISTÓRIA - João Pessoa",
          short: "PPGH",
          id: 1908
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM INFORMÁTICA - João Pessoa",
          short: "PPGI",
          id: 1879
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM JORNALISMO - João Pessoa",
          short: "PPJ",
          id: 2618
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM LETRAS - João Pessoa",
          short: "PPGL",
          id: 1877
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM LINGUÍSTICA - João Pessoa",
          short: "PROLING",
          id: 1901
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM LINGUÍSTICA E ENSINO - João Pessoa",
          short: "MPLE",
          id: 1932
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM MANEJO DE SOLO E ÁGUA - Areia",
          short: "PPGMSA",
          id: 1907
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM MATEMÁTICA - João Pessoa",
          short: "PPGMAT",
          id: 1914
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM MODELAGEM MATEMÁTICA E COMPUTACIONAL - João Pessoa",
          short: "PPGMMC",
          id: 2831
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM MODELOS DE DECISÃO E SAÚDE - João Pessoa",
          short: "PPGMDS",
          id: 1895
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM MÚSICA - João Pessoa",
          short: "PPGM",
          id: 1894
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM NEUROCIÊNCIA COGNITIVA E COMPORTAMENTO - João Pessoa",
          short: "PPGNEC",
          id: 2621
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ODONTOLOGIA - João Pessoa",
          short: "PPGO",
          id: 1876
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM POLÍTICAS PÚBLICAS, GESTÃO E AVALIAÇÃO DA EDUCAÇÃO SUPERIOR - João Pessoa",
          short: "MPPGAV",
          id: 2847
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM PRODUTOS NATURAIS E SINTÉTICOS BIOATIVOS - João Pessoa",
          short: "PPGPN",
          id: 1882
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM PSICOLOGIA - João Pessoa",
          short: "PPGP",
          id: 1888
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM PSICOLOGIA SOCIAL - João Pessoa",
          short: "PPGPS",
          id: 2626
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM QUÍMICA - João Pessoa",
          short: "PPGQ",
          id: 1883
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM SAÚDE COLETIVA, EM NÍVEL DE MESTRADO ACADÊMICO - João Pessoa",
          short: "PPGSC",
          id: 3385
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM SAÚDE DA FAMÍLIA - João Pessoa",
          short: "PPGSF",
          id: 2839
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM SAÚDE DA FAMÍLIA EM REDE NACIONAL - João Pessoa",
          short: "PROFSAÚDE",
          id: 3215
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM SERVIÇO SOCIAL - João Pessoa",
          short: "PPGSS",
          id: 1905
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM SOCIOLOGIA - João Pessoa",
          short: "PPGS",
          id: 1912
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM TECNOLOGIA AGROALIMENTAR - Bananeiras",
          short: "PPGTA",
          id: 1903
        },
        {
          name: "PROGRAMA DE PÓS-GRADUAÇÃO EM ZOOTECNIA - Areia",
          short: "PPGZOO",
          id: 1893
        },
        {
          name: "PROGRAMA EJA EPT DA ESCOLA TÉCNICA DE SAÚDE - João Pessoa",
          short: "EJAEPT - ETS",
          id: 3639
        },
        {
          name: "PROGRAMA EM LETRAS EM REDE NACIONAL - Rio Tinto",
          short: "PROFLETRAS",
          id: 2627
        },
        {
          name: "PROGRAMA EM MATEMÁTICA EM REDE NACIONAL - João Pessoa",
          short: "PROFMAT",
          id: 1910
        },
        {
          name: "PROGRAMA INTEGRADO DE DOUTORADO EM ODONTOLOGIA - João Pessoa",
          short: "PIDO",
          id: 2624
        },
        {
          name: "PROGRAMA INTEGRADO DE PÓS-GRADUAÇÃO EM FILOSOFIA - João Pessoa",
          short: "PIPGF",
          id: 2623
        },
        {
          name: "PROGRAMA INTEGRADO DE PÓS-GRADUAÇÃO EM PSICOLOGIA SOCIAL - João Pessoa",
          short: "PIPPS",
          id: 1900
        },
        {
          name: "PROGRAMA MULTI-INSTITUCIONAL E INTER-REGIONAL DE PÓS-GRADUAÇÃO EM CIÊNCIAS CONTÁBEIS - João Pessoa",
          short: "PMIPCC",
          id: 1930
        },
        {
          name: "PROGRAMA MULTICÊNTRICO DE PÓS-GRADUAÇÃO EM CIÊNCIAS FISIOLÓGICAS - João Pessoa",
          short: "PMPGCF",
          id: 2864
        },
        {
          name: "PROGRAMA NOVOS CAMINHOS DA ESCOLA TÉCNICA DE SAÚDE - João Pessoa",
          short: "PROGNOVCAM-ETS",
          id: 3533
        },
        {
          name: "PROGRAMA PÓS TÉCNICO COFEN/ETS/UFPB - João Pessoa",
          short: "PPT-COFEN-ETS",
          id: 3623
        },
        {
          name: "PRONATEC-ETS - João Pessoa",
          short: "ETS - PRONATEC",
          id: 2628
        },
        {
          name: "QUALIFICA MAIS ENERGIFE ESCOLA TÉCNICA DE SAÚDE - João Pessoa",
          short: "ENERGIFE-ETS",
          id: 3666
        }
      ];

    switch (option) {
        case 'list-full-name':
            return list.map(e => e.name);
        case 'list-short-name':
            return list.map(e => e.short);
        case 'find-id-by-short': {
            let found = list.find(e => e.short == short);
            return found !== undefined ? found.id : -1;  
        }
        default:
            return list
    }


}


// var options = document.getElementById('formTurma:inputDepto').options;
// var text = ''
// for (let i = 0; i < options.length; i++) {
//     text += ` { nname: '${options[i].text}', short: '', id: ${options[i].value}},`;
// }
// console.log(`[ ${text} ]`);

