<template>
  <div>
    <section :style="`height: ${height}px; padding-top: ${padding}px`" class="main-t">
      <!-- Login card -->
      <v-card class="cd-login">
        <v-img class="img-t mb-10" width="180" src="../assets/logo.png"></v-img>
        <p class="text-h5 mb-16">
          Utilize suas
          <span class="font-weight-black c-blue">credenciais</span> do
          <span class="font-weight-black c-blue">SIGAA</span>
          para entrar no sistema
        </p>
        <PrimaryButton :onClick="login" class="px-16 py-6">Log In</PrimaryButton>
        <v-card-text class="py-12 text-caption">
            O SACI precisa armazenar o seu nome, e-mail e número de vínculo com a universidade. Ao fazer o login, você estará concordando com o armazenamento desses dados.
        </v-card-text>
      </v-card>

      <div class="cd-logos" :style="`width: ${width}px`">
        <div class="cd-logos-content">
          <a href="https://www.ufpb.br/" target="_blank">
            <v-avatar tile size="66" class="my-4 mr-4">
              <v-img src="../assets/ufpb.png" />
            </v-avatar>
          </a>
          <a href="http://laser.ci.ufpb.br/" target="_blank">
            <v-avatar tile size="66" class="my-4 mr-4">
              <v-img src="../assets/laser.png" />
            </v-avatar>
          </a>
          <a href="https://linktr.ee/log.ufpb" target="_blank">
            <v-avatar tile size="66" class="my-4 mr-4">
              <v-img src="../assets/log.png" />
            </v-avatar>
          </a>
          <a href="https://ci.ufpb.br/" target="_blank">
            <v-avatar tile size="66" class="my-4 mr-4">
              <v-img src="../assets/ci.jpeg" />
            </v-avatar>
          </a>
          <p class="credits">
            SACI | UFPB | 2023 |
            <a class="credits" href="https://forms.gle/G1zUrtJriExafCDs8" target="_blank">
              Relatar um problema
            </a>
          </p>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import PrimaryButton from "@/components/PrimaryButton";
import { loadLoginScreen } from "@/api/oidc.js";

export default {
  name: "LoginScreen",

  components: {
    PrimaryButton,
  },
  methods: {
    login() {
      loadLoginScreen();
    },
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.height;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
    padding() {
      return this.$vuetify.breakpoint.height < 900
        ? 50
        : (this.$vuetify.breakpoint.height - 544 - 119) / 2;
    },
  },
};
</script>

<style scoped>
.img-t {
  margin: 0 auto;
}

.cd-login {
  position: relative;
  background-color: white;
  padding-top: 100px;
  text-align: center;
  color: black;
  margin: 0 auto 0 auto;
  width: 450px;
  z-index: 2;
  padding: 50px 50px 0 50px;
}

.main-t {
  min-height: 900px;
  background-image: linear-gradient(135deg, #1491ff, #0346b6);
}

.c-blue {
  color: #1491ff;
}

.saiba-mais {
  color: #1565C0;
  cursor: pointer;
}


.cd-logos {
  background-color: white;
  position: fixed;
  bottom: 0;
  text-align: center;
  z-index: 4;
}

.cd-logos-content {
  max-width: 1920px;
  margin: 0 auto;
}

.credits {
  color: #7a7a7a;
  font-size: 14px;
  margin-bottom: 0;
}

/* Only mobile screen */

@media only screen and (max-width: 500px) {
  .cd-login {
    width: 85%;
    padding: 50px 40px 0 40px;
  }
}
</style>
