<template>
  <v-card
    :class="`mt-2 ml-2 text-center center ${isMobile ? '' : 'max-w-1'} ${
      color == 'blue' ? 'bg-color-blue' : 'bg-color-grey'
    }`"
    dark
  >
    <v-card-title class="title-card"> {{ name }} </v-card-title>
    <v-card-subtitle class="caption">
      {{ code }}
      {{
        time == null || time == "-" || time == "0" ? "" : "- " + time
      }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "ClassCard",
  props: {
    name: String,
    code: String,
    time: String,
    color: String,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
  }
};
</script>

<style scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
.title-card {
  text-align: center;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 20px;
}
.max-w-1 {
  max-width: 190px;
}

.bg-color-blue {
  background-color: var(--v-primary-base);
}
.bg-color-grey {
  background-color: var(--v-accent-darken4);
}
</style>