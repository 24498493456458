<template>
  <v-tooltip top max-width="20%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :small="small" v-bind="attrs" v-on="on" class="mb-1">
        mdi-help-circle-outline
      </v-icon>
    </template>
    <span> {{ text }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "HelpIcon",
  props: {
    text: String,
    small: Boolean
  },
};
</script>