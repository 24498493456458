<template>
  <v-btn depressed text @click="doLogout">
    <v-icon>mdi-logout</v-icon>
    <span class="ml-7">Log Out</span>
  </v-btn>
</template>

<script>
import { logout } from "@/api/oidc";

export default {
  name: "LogoutButton",
  methods: {
    doLogout() {
      logout();
      if (this.$route.name != "Home") {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>