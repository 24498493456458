<template>
  <div class="px-10 pt-1">
    <p class="text-h6">
      <span
        ><v-icon
          @click="close"
          class="mr-3 mt-n1 font-weight-bold pointer"
          color="blue"
          >mdi-arrow-left</v-icon
        ></span
      >{{ title }}
    </p>

    <p class="mt-4 mb-4 text-body-2">
      {{ qtd_turmas }} nesse horário, isto representa
      {{ rate_percent }}% do horário mais ocupado ({{ max_turmas }}).
    </p>

    <v-progress-linear
      v-model="rate_percent"
      height="50"
      color="#0D73E1"
      dark
    >
      <strong>{{ rate_percent }}%</strong>
    </v-progress-linear>

    <div class="my-6">
      <v-btn rounded :outlined="option == 0" :text="option != 0" small :color="option == 0 ? 'blue' : 'grey'" @click.stop="option = 0">Turmas alocadas</v-btn>
      <v-btn rounded :outlined="option == 1" :text="option != 1" small :color="option == 1 ? 'blue' : 'grey'" class="ml-3" @click.stop="option = 1">Salas disponíveis</v-btn>
    </div>
    

    <v-data-table
      v-if="option == 0"
      :headers="headers"
      :items="classes"
      :items-per-page="-1"
      item-key="idx"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.nome="{ item }">
        <p class="mt-4">
          {{
            item.nome
              .toLowerCase()
              .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) =>
                match.toUpperCase()
              )
          }}
        </p>
      </template>
    </v-data-table>

    <HandleRoomTable v-if="option == 1" :rooms="rooms" @click-plus="handleClickPlus"/>
  
  </div>
</template>

<script>
import HandleRoomTable from "@/handles/paas/HandleRoomTable.vue";

export default {
  name: "HandletimeSummary",
  components: {
    HandleRoomTable
  },
  props: {
    rate: Number,
    title: String,
    classes: Array,
    rooms: Array,
    max: Number,
  },
  data() {
    return {
      headers: [
        { text: "Código", value: "codigo", align: "start" },
        { text: "Nome", value: "nome" },
        { text: "Sala alocada", value: "sala" },
        // { text: "", value: "visualizar", align: "end" },
      ],
      option: 0,
    };
  },
  computed: {
    rate_percent() {
      return Math.ceil(this.rate * 100);
    },
    qtd_turmas() {
      let t = this.classes.length;
      return t > 1 ? t + " turmas alocadas" : t + " turma alocada";
    },
    max_turmas() {
      let t = this.max;
      return t > 1 ? t + " turmas" : t + " turma";
    },
  },
  methods: {
    close() {
      this.$emit("close", -1, -1);
    },
    handleClickPlus(idx) {
      this.$emit('click-plus', idx)
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}
</style>
