<template>
  <div class="grid">
    <div
      v-for="(square, index) in squares"
      :key="index"
      :style="{
        width: squareSize + 'px',
        height: squareSize + 'px',
        backgroundColor: calculateColor(index),
      }"
      class="square"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      squares: [],
      minSquareSize: 40,
      maxSquareSize: 50,
      resizeObserver: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.updateGrid();
      this.resizeObserver = new ResizeObserver(this.updateGrid);
      this.resizeObserver.observe(this.$el.parentElement);
    });
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    updateGrid() {
      let minus =
        this.$el?.parentElement?.clientWidth > 1500
          ? this.$el?.parentElement?.clientWidth * 0.13
          : this.$el?.parentElement?.clientWidth * 0.19;

      const containerWidth =
        this.$el?.parentElement?.clientWidth - minus;

      let squareSize = Math.min(
        Math.max(
          this.minSquareSize,
          containerWidth /
            Math.floor(containerWidth / this.minSquareSize)
        ),
        this.maxSquareSize
      );
      const squareCount = Math.floor(containerWidth / squareSize);

      if (squareCount > 0) {
        this.squares = Array(squareCount).fill(null);
        this.squareSize = squareSize;
      }
    },
    calculateColor(index) {
      const startColor = [245, 245, 245]; // rgba(245,245,245,1)
      const endColor = [7, 87, 199]; // rgba(7,87,199,1)
      const n = this.squares.length;

      const r = Math.round(
        startColor[0] +
          ((endColor[0] - startColor[0]) * index) / (n - 1)
      );
      const g = Math.round(
        startColor[1] +
          ((endColor[1] - startColor[1]) * index) / (n - 1)
      );
      const b = Math.round(
        startColor[2] +
          ((endColor[2] - startColor[2]) * index) / (n - 1)
      );

      return `rgb(${r},${g},${b})`;
    },
  },
};
</script>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.square {
  background-color: #3498db;
  margin: 2px;
}
</style>
