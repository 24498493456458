<template>
    <div>

        <div v-if="edit.showTab == 'advanced'" class="pointer clear-both float-left"
            @click="() => edit.showTab = 'classes'">
            <v-icon color="blue" class="pt-n2 float-left">
                mdi-arrow-left
            </v-icon>
            <p class="float-left ml-2">Voltar</p>
        </div>
        <div v-else class="pointer clear-both float-left" @click="goBack">
            <v-icon color="blue" class="pt-n2 float-left">
                mdi-arrow-left
            </v-icon>
            <p class="float-left ml-2">Voltar</p>
        </div>


        <section v-if="select.show">
            <div class="py-12">
                <v-btn class="float-right blue" dark @click="handleEditSimulador">Ir para o Simulador</v-btn>
                <p class="text-caption">Selecione as alocações que permanecerão fixas. Ao clicar em ir para o simulador você
                    será redirecionado para a página de configuração.</p>
                <!-- Search box -->
                <div class="search pt-1 mb-2">
                    <v-text-field v-model="select.search" outlined dense append-icon="mdi-magnify" label="Pesquisar"
                        single-line hide-details></v-text-field>
                </div>
                <!-- List of classes -->
                <v-data-table :single-select="false" v-model="select.selected" show-select :search="select.search"
                    :headers="select.headers" :items-per-page="-1" :items="select.items" item-key="idx" hide-default-footer
                    class="elevation-1">
                    <!-- allow search without special characters -->
                    <template v-slot:item.all="{ item }">
                        <p class="mb-0">{{ item.codigo }}
                        </p>
                    </template>
                </v-data-table>
            </div>
        </section>

        <section v-else-if="edit.show">
            <div class="py-12">
                <v-btn v-if="edit.showTab != 'advanced'" class="btn mt-4 mb-10 float-right" color="blue" :outlined="waitSave" dark @click="handleSaveEdit">
                    <v-progress-circular :width="2" :size="20" v-if="waitSave" indeterminate
                        color="primary"></v-progress-circular>
                    <p class="my-0 pl-2">
                        {{ waitSave
                            ? 'Aguarde enquanto as alterações são salvas... ' : 'Salvar Alterações' }}
                    </p>
                </v-btn>
                <!-- <v-btn v-if="edit.showTab != 'advanced'" class="float-right blue" dark @click="handleSaveEdit">Salvar
                    Alterações</v-btn> -->

                <div v-if="edit.showTab == 'classes'">

                    <div v-if="!edit.showSalas" class="pointer float-left" @click="() => edit.showTab = 'rooms'">
                        <v-icon small class="mr-2 float-left">
                            mdi-door-closed
                        </v-icon>
                        <p class="text-caption float-left">Edite Salas</p>
                    </div>

                    <!-- Search box -->
                    <div class="search pt-1 mb-2 mt-5 clear-both">
                        <v-text-field v-model="edit.searchClasses" outlined dense append-icon="mdi-magnify"
                            label="Pesquisar" single-line hide-details></v-text-field>
                        <v-btn class="float-left blue mb-2 mt-5" dark small @click="() => edit.add = true">Nova
                            Turma</v-btn>
                        <v-btn class="float-left mb-2 mt-5 ml-2" outlined color="blue" small
                            @click="() => edit.showTab = 'advanced'">Opções
                            avançadas</v-btn>
                    </div>

                    <!-- Add new class -->
                    <v-dialog v-model="edit.add" persistent max-width="700">
                        <PaasForms option="classes" editPublic noPreferences :add="addNewClass" :roomTypes="[]"
                            :addDep="() => { }" :rooms="roomList" :close="() => edit.add = false" />
                    </v-dialog>

                    <!-- List of classes -->
                    <v-data-table :search="edit.searchClasses" :headers="edit.headersClasses" :items-per-page="-1"
                        :items="edit.classes" item-key="id" hide-default-footer class="elevation-1 clear-both">
                        <!-- allow search without special characters -->
                        <template v-slot:item.all="{ item }">
                            <p class="mb-0">{{ item.codigo }}
                            </p>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="() => handleEdit(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="() => handleDelete(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>

                    <!-- Edit class -->
                    <v-dialog v-model="edit.dialog" persistent max-width="700">
                        <PaasForms option="classes" edit editPublic :editObject="edit.placeholder" noPreferences
                            :add="saveEditClass" :roomTypes="[]" :rooms="roomList" :close="() => edit.dialog = false" />
                    </v-dialog>

                    <!-- Delete class -->
                    <v-dialog v-model="edit.delete" persistent max-width="700">
                        <v-card>
                            <v-card-title>
                                Deletar Turma
                            </v-card-title>
                            <v-card-text>
                                Você tem certeza que deseja deletar a turma [{{ edit.placeholder.codigo }}-{{
                                    edit.placeholder.turma }}] {{ edit.placeholder.nome }}?
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="grey" text @click="() => edit.delete = false">
                                    Cancelar
                                </v-btn>

                                <v-btn color="blue" text @click="deleteClass">
                                    Confirmar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <div v-if="edit.showTab == 'rooms'">

                    <div class="pointer" @click="() => edit.showTab = 'classes'">
                        <v-icon small class="mr-2 float-left">
                            mdi-format-list-bulleted-square
                        </v-icon>
                        <p class="text-caption float-left">Edite Turmas</p>
                    </div>

                    <!-- Search box -->
                    <div class="search pt-1 mb-2 clear-both">
                        <v-text-field v-model="edit.searchRooms" outlined dense append-icon="mdi-magnify" label="Pesquisar"
                            single-line hide-details></v-text-field>
                        <v-btn class="float-left blue mb-2 mt-5" dark small @click="() => edit.add = true">Nova Sala</v-btn>
                    </div>

                    <!-- Add new room -->
                    <v-dialog v-model="edit.add" persistent max-width="700">
                        <PaasForms option="rooms" editPublic noPreferences :add="addNewRoom" :roomTypes="edit.roomTypes"
                            :addRoomType="() => { }" :rooms="roomList" :close="() => edit.add = false" />
                    </v-dialog>

                    <!-- List of Rooms -->
                    <v-data-table :search="edit.searchRooms" :headers="edit.headersRooms" :items-per-page="-1"
                        :items="edit.rooms" item-key="idx" hide-default-footer class="elevation-1 clear-both">
                        <!-- allow search without special characters -->
                        <template v-slot:item.all="{ item }">
                            <p class="mb-0">{{ item.bloco }}
                            </p>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="handleEdit(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="handleDelete(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>

                    <!-- Edit room -->
                    <v-dialog v-model="edit.dialog" persistent max-width="700">
                        <PaasForms option="rooms" edit :editObject="edit.placeholder" noPreferences :add="saveEditRoom"
                            :roomTypes="edit.roomTypes" :rooms="[]" :close="() => edit.dialog = false" />
                    </v-dialog>

                    <!-- Delete Room -->
                    <v-dialog v-model="edit.delete" persistent max-width="700">
                        <v-card>
                            <v-card-title>
                                Deletar Sala
                            </v-card-title>
                            <v-card-text>
                                <div>Você tem certeza que deseja deletar a sala: {{ edit.placeholder.bloco }} {{
                                    edit.placeholder.nome }} ({{ edit.placeholder.tipo }})?</div>
                                <div class="py-2"> </div>
                                <div>Caso haja turmas alocadas a esta sala, <strong>você precisará realocar manualmente as
                                        turmas afetadas</strong> antes de salvar as alterações.</div>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="blue" text @click="() => edit.delete = false">
                                    Cancelar
                                </v-btn>

                                <v-btn color="grey" text @click="deleteRoom">
                                    Confirmar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <div v-if="edit.showTab == 'advanced'">
                    <AdvancedOptions splitClasses :paasInput="{ classes: edit.classes, rooms: edit.rooms, departaments: edit.deptos }"
                        @updateSplitClass="updateSplitClass" 
                        updatePaas @updateClasses="handleUpdateClasses"
                        updatePreAloc
                        />
                </div>

            </div>
        </section>

        <section v-else>
            <div class="py-12">
                <div class="pa-4">
                    <v-row>
                        <v-col sm="12" md="3">
                            <LargeButton type="basic" title="Editar dados e gerar nova simulação"
                                subtitle="Ideal para acrescentar novas turmas ou salas. Você poderá selecionar as alocações desta solução que permanecerão fixas."
                                @click.native="openSelect" />
                        </v-col>
                        <v-col sm="12" md="3">
                            <LargeButton type="basic" title="Alterar dados e manter solução"
                                subtitle="Ideal para pequenas mudanças. As alterações serão realizadas sem uma nova simulação."
                                @click.native="openEdit" />
                        </v-col>
                        <v-col sm="12" md="6">
                            <NotFound type="setup" />
                        </v-col>
                    </v-row>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import LargeButton from '../LargeButton.vue';
import PaasForms from './PaasForms.vue';
import NotFound from '../NotFound.vue';
import AdvancedOptions from './AdvancedOptions.vue';
import { handleEditSolution, validateEditSolution } from '@/api/paas';
import latinize from 'latinize';

export default {
    name: 'EditSolution',
    components: {
        LargeButton,
        NotFound,
        PaasForms,
        AdvancedOptions
    },
    props: {
        solution: Object,
        back: Function,
        save: Function,
    },
    data() {
        return {
            dialog: {
                open: true,
            },
            waitSave: false,
            edit: {
                classes: [],
                rooms: [],
                roomTypes: [],
                deptos: [],
                show: false,
                dialog: false,
                add: false,
                showTab: 'classes',
                delete: false,
                placeholder: {},
                headersClasses: [
                    { align: 'start', text: 'Código', value: 'all' },
                    { text: 'Turma', value: 'turma' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Docente', value: 'docente' },
                    { text: 'Horário', value: 'horario' },
                    { text: 'Discentes', value: 'alunos'},
                    { text: 'Sala alocada', value: 'pre_alocacao' },
                    { text: 'Editar', value: 'actions' },
                ],
                headersRooms: [
                    { align: 'start', text: 'Bloco', value: 'all' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Tipo', value: 'tipo' },
                    { text: 'Capacidade', value: 'capacidade' },
                    { text: 'Editar', value: 'actions' },
                ],
                searchClasses: '',
                searchRooms: '',
                changed: false,
            },
            select: {
                show: false,
                items: [],
                search: '',
                selected: [],
                headers: [
                    { align: 'start', text: 'Código', value: 'all' },
                    { text: 'Turma', value: 'turma' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Sala alocada', value: 'sala_alocada' },
                ],
            },

        }
    },
    computed: {
        roomList() {
            return this.edit.rooms.map((el) => `${el.bloco} ${el.nome}`)
        }
    },
    methods: {
        openEdit() {
            this.select.show = false;
            this.edit.show = true;
        },
        openSelect() {
            this.edit.show = false;
            this.select.show = true;
        },
        handleEditSimulador() {
            handleEditSolution(this.solution, "Alocação pública.", this.select.selected);
            this.$router.push({ path: "/paas-simulador" });
        },
        handleSaveEdit() {
            if (this.edit.changed) {
                let error = validateEditSolution({ classes: this.edit.classes, rooms: this.edit.rooms });
                if (error != '') {
                    alert(error);
                } else {
                    this.waitSave = true;
                    this.save(this.edit.rooms, this.edit.classes);
                }
            }
        },
        goBack() {
            this.back();
        },
        saveEditRoom(e) {
            //find index and update object
            let idx = this.edit.rooms.findIndex(el => el.id == e.id);

            let oldName = `${this.edit.rooms[idx].bloco} ${this.edit.rooms[idx].nome}`
            let newName = `${e.bloco} ${e.nome}`

            if (oldName != newName) {

                console.log(this.roomList)
                //finds if new name already exists
                if (this.roomList.indexOf(newName) != -1) {
                    alert('O nome para a sala já existe, a alteração não será realizada.')
                } else {
                    this.edit.changed = true;
                    //updates classes allocated with new name
                    this.edit.classes.forEach(el => {
                        if (el.pre_alocacao == oldName)
                            el.pre_alocacao = newName;
                    })

                    //save edit
                    this.edit.rooms[idx] = JSON.parse(JSON.stringify(e));
                    //triggers data table update
                    this.edit.rooms = JSON.parse(JSON.stringify(this.edit.rooms));
                }
            } else {
                this.edit.changed = true;
                this.edit.rooms[idx] = JSON.parse(JSON.stringify(e));
                //triggers data table update
                this.edit.rooms = JSON.parse(JSON.stringify(this.edit.rooms))
            }

        },
        //save edited class
        saveEditClass(e) {
            this.edit.changed = true;
            //find index and update object
            let idx = this.edit.classes.findIndex(el => el.id == e.id);
            this.edit.classes[idx] = JSON.parse(JSON.stringify(e));
            //triggers data table update
            this.edit.classes = JSON.parse(JSON.stringify(this.edit.classes))
        },
        addNewRoom(e) {
            let newName = `${e.bloco} ${e.nome}`

            if (this.roomList.indexOf(newName) != -1) {
                alert('O nome para a sala já existe, a alteração não será realizada.')
            } else {
                this.edit.changed = true;
                e.id = this.edit.rooms[this.edit.rooms.length - 1].id + 1;
                e.preferencias = this.edit.rooms[this.edit.rooms.length - 1].preferencias
                this.edit.rooms.push(e);
                //triggers data table update
                this.edit.rooms = JSON.parse(JSON.stringify(this.edit.rooms));
            }
        },
        addNewClass(e) {
            if (e.pre_alocacao == "") {
                alert('Adicione uma alocação, a nova turma não será adicionada.')
            } else {
                this.edit.changed = true;
                e.id = this.edit.classes[this.edit.classes.length - 1].id + 1;
                e.preferencias = this.edit.classes[this.edit.classes.length - 1].preferencias
                this.edit.classes.push(e);
                //triggers data table update
                this.edit.classes = JSON.parse(JSON.stringify(this.edit.classes));
            }
        },
        handleEdit(e) {
            this.edit.placeholder = JSON.parse(JSON.stringify(e));
            this.edit.dialog = true;
        },
        handleDelete(e) {
            this.edit.placeholder = e;
            this.edit.delete = true;
        },
        deleteClass() {
            this.edit.changed = true;

            //find index and update object
            let idx = this.edit.classes.findIndex(el => el.id == this.edit.placeholder.id);
            this.edit.classes.splice(idx, 1);
            //triggers data table update
            this.edit.classes = JSON.parse(JSON.stringify(this.edit.classes))

            this.edit.delete = false;
        },
        deleteRoom() {
            this.edit.changed = true;
            console.log('deleteRoom', this.edit.placeholder);

            let roomName = `${this.edit.placeholder.bloco} ${this.edit.placeholder.nome}`
            this.edit.classes.filter(el => el.pre_alocacao == roomName).forEach(el => {
                el.pre_alocacao = '';
            });


            //find index and update object
            let idx = this.edit.rooms.findIndex(el => el.id == this.edit.placeholder.id);
            this.edit.rooms.splice(idx, 1);
            //triggers data table update
            this.edit.rooms = JSON.parse(JSON.stringify(this.edit.rooms))


            this.edit.delete = false;
        },
        updateSplitClass(e) {

            e.new[0].id = this.edit.classes[this.edit.classes.length - 1].id + 1;
            e.new[1].id = this.edit.classes[this.edit.classes.length - 1].id + 2;

            let idx = this.edit.classes.indexOf(e.old)
            this.edit.classes.splice(idx, 1);

            this.edit.classes.push(e.new[0]);
            this.edit.classes.push(e.new[1]);
            //triggers data table update
            this.edit.classes = JSON.parse(JSON.stringify(this.edit.classes));
            this.edit.changed = true;
            console.log(this.edit.classes)

            this.edit.showTab = 'classes';


        },
        handleUpdateClasses(newClasses) {
            this.edit.changed = true;
            
            this.edit.classes = JSON.parse(JSON.stringify(newClasses));
            this.edit.showTab = 'classes';
        },
    },
    mounted() {

        let solution = JSON.parse(JSON.stringify(this.solution.solution));
        let roomTypes = [];
        let deptos = [];

        solution.forEach((room) => {

            room.classes.forEach((e) => {

                let sala = `${room.bloco} ${room.nome}`
                //allow search without special characters
                let dataLatinaze = latinize(`${sala} ${e.nome} ${e.docente}`);
                let all = `${e.codigo} ${sala} ${e.nome} ${e.docente} ${dataLatinaze}`

                e.all = all;

                this.select.items.push({
                    ...e,
                    idx: `${e.codigo}-${e.turma}`,
                    sala_alocada: sala,
                });

                e.pre_alocacao = `${room.bloco} ${room.nome}`;

                if (deptos.indexOf(e.departamento) == -1)
                    deptos.push(e.departamento);

                this.edit.classes.push(e);

            })

            //allow search without special characters
            let dataLatinaze = latinize(`${room.bloco} ${room.nome} ${room.tipo}`);
            let all = `${room.bloco} ${room.nome} ${room.tipo} ${dataLatinaze}`


            room.all = all;

            if (roomTypes.indexOf(room.tipo) == -1)
                roomTypes.push(room.tipo);

            delete room.classes;
            this.edit.rooms.push(room)


        });

        this.edit.classes.forEach((e, idx) => {
            e.id = idx;
        });
        this.edit.roomTypes = roomTypes;
        this.edit.deptos = deptos;
    }
}
</script>

<style scoped>
.test {
    background-color: aqua;
}

.main-edit {
    width: 100%;
    min-height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.clear-both {
    position: relative;
    clear: both;
}

.search {
    width: 30%;
}</style>