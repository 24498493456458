import axios from "axios";
import store from "@/store/vuex";

const server_uri = process.env.VUE_APP_SERVER_URI;

//Get report from backend
export const getReport = async () => {
    try {
        return await axios
        .get(`${server_uri}/analytics/getReport?id=${store.getters.getIdVinculo}`);
    } catch(e) {
        console.log("error: ", e);
    }
}

//Set default poh params
export const getAdminPohData = async () => {
    return new Promise((resolve, reject) => {
        axios
        .get(
            `${server_uri}/analytics/getAdminPohData?id=${store.getters.getIdVinculo}`
        )
        .then(response => {

            const { studentData, curriculumData } = response.data;

            store.commit("setPohData", {
                studentData: studentData,
                curriculumData: curriculumData
            });

            resolve(true);
        })
        .catch(e => {
            console.log(e);
            reject(false);
        });
    });
}