import axios from "axios";
import store from "@/store/vuex";

const server_uri = process.env.VUE_APP_SERVER_URI;

// Check if user is in database, if not it will be added
export const checkUser = async () => {

  let idVinculo = store.getters.getIdVinculo;

  if(idVinculo != undefined){
    axios
    .get(
      `${server_uri}/db/users?idVinculo=${idVinculo}`
    )
    .catch((error) => {
      throw error;
    });
  }
}

export const createNewPlanDB = async (body) => {

  // idVinculo: number,
  // hash: string,
  // name: string,
  // date: string,
  // taken_classes: number,
  // taken_semesters: number

  try {
    // Create new plan in database
    const res = await axios
    .post(
      `${server_uri}/db/plans/poh`, body
    );
    return await res.data;
  } catch (e) {
    console.log("error: ", e);
  }
};

// Delete plan in database
export const deletePlanDB = async (hash) => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .delete(
      `${server_uri}/db/plans/poh?idVinculo=${idVinculo}&hash=${hash}`
    );
    return await res.data;
  } catch(e) {
    console.log("error: ", e);
  }

};

// Rename plan in database
export const renamePlanDB = async (hash, rename) => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .put(
      `${server_uri}/db/plans/poh?idVinculo=${idVinculo}&hash=${hash}&rename=${rename}`
    );
    return await res.data;
  } catch(e) {
    console.log("error: ", e);
  }
};

// get list db
export const getPlansDB = async () => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    // send get request 
    const res = await axios
    .get(
      `${server_uri}/db/plans/poh?idVinculo=${idVinculo}`
    );
    return await res.data;
  } catch (e) {
    console.log("error: ", e);
  }
}

// Get solution from database
export const getSolutionPOH = async (hash) => {
  try {
    const res = await axios
    .get(`${server_uri}/db/solutions/poh?hash=${hash}`);
    // return solution
    return res.data;
  } catch (e) {
    console("error: ", e);
  }
}



// PAAS REQUESTS



//Return a list of public and private rooms
export const getRoomGroupList = async () => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .get(`${server_uri}/db/paas/roomsInfo?idVinculo=${idVinculo}`)

    return res.data;

  } catch (e) {
    console.log("error: ", e)
  }
}

export const getRoomGroup = async (id) => {
  try {

    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .get(`${server_uri}/db/paas/rooms?idVinculo=${idVinculo}&id=${id}`)

    return res.data;

  } catch (e) {
    console.log("error: ", e)
  }
}

export const postNewRoomGroup = async (rooms, name, isPublic) => {

  try {

    let idVinculo = store.getters.getIdVinculo;
    let userName = store.getters.getUserName;
    let centro = store.getters.getCentro;

    const body = {
      name, 
      idVinculo,
      isPublic,
      rooms,
      creater_user: userName,
      create_date: new Date().toLocaleDateString(),
      centro,
  }

  await axios
  .post(`${server_uri}/db/paas/rooms`, body)


  } catch (error) {
    console.log('error', error);
  }

}

export const deleteRoomGroup = async (id) => {
  try {

    let idVinculo = store.getters.getIdVinculo;

    const res = await axios
    .delete(
      `${server_uri}/db/paas/rooms?idVinculo=${idVinculo}&id=${id}`
    );

    return res;


  } catch (error) {
    console.log(error);
  }
}

export const getSolutionPaas = async (hash) => {
  try {

    const res = await axios
      .get(`${server_uri}/db/solutions/paas?hash=${hash}`);

    return res.data;

  } catch (e) {
    console("error: ", e);
  }
}

// PAAS PLAN CRUD

export const createNewPaasPlanDB = async (body) => {

  // idVinculo: number,
  // hash: string,
  // name: string,
  // date: string,
  // numClasses: number,
  // numRooms: number

  try {
    // Create new plan in database
    const res = await axios
    .post(
      `${server_uri}/db/plans/paas`, body
    );
    return await res.data;
  } catch (e) {
    console.log("error: ", e);
  }
};

// get paas plans list db
export const getPaasPlansDB = async () => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    // send get request 
    const res = await axios
    .get(
      `${server_uri}/db/plans/paas?idVinculo=${idVinculo}`
    );
    return await res.data;
  } catch (e) {
    console.log("error: ", e);
  }
}

// Rename plan in database
export const renamePaasPlanDB = async (hash, rename) => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .put(
      `${server_uri}/db/plans/paas?idVinculo=${idVinculo}&hash=${hash}&rename=${rename}`
    );
    return await res.data;
  } catch(e) {
    console.log("error: ", e);
  }
};

// Delete plan in database
export const deletePaasPlanDB = async (hash) => {
  try {
    let idVinculo = store.getters.getIdVinculo;
    const res = await axios
    .delete(
      `${server_uri}/db/plans/paas?idVinculo=${idVinculo}&hash=${hash}`
    );
    return await res.data;
  } catch(e) {
    console.log("error: ", e);
  }

};

// PAAS public 

export const handlePublishPaasSolution = async (plan) => {

  try {

    let idVinculo = store.getters.getIdVinculo;
    let centro = store.getters.getCentro;

    let res = axios
    .put(
      `${server_uri}/db/public/paas?type=setNewSolution&idVinculo=${idVinculo}`,
      {
        centro,
        hash: plan.hash
      }
    );

    return await res.data;

  } catch(e) {
    console.log("error: ", e);
  }
}

export const handleRemovePaasSolutionPublic = async () => {

  try {

    let idVinculo = store.getters.getIdVinculo;
    let centro = store.getters.getCentro;

    let res = axios
    .put(
      `${server_uri}/db/public/paas?type=removeSolution&idVinculo=${idVinculo}`,
      {
        centro,
      }
    );

    return await res.data;

  } catch(e) {
    console.log("error: ", e);
  }
}
//edit solution
export const editPaasPublic = async (salas, turmas, hash) => {
  try {

    let idVinculo = store.getters.getIdVinculo;
    let centro = store.getters.getCentro;

    let res = axios
    .put(
      `${server_uri}/db/public/paas?type=editSolution&idVinculo=${idVinculo}`,
      {
        centro,
        salas,
        turmas,
        hash
      }
    );

    return await res;

  } catch(e) {
    console.log("error: ", e);
  }

};

//edit description
export const editDescriptionPaasPublic = async (description) => {
  try {

    let idVinculo = store.getters.getIdVinculo;
    let centro = store.getters.getCentro;

    let res = axios
    .put(
      `${server_uri}/db/public/paas?type=editDescription&idVinculo=${idVinculo}`,
      {
        centro,
        description
      }
    );

    return await res.data;

  } catch(e) {
    console.log("error: ", e);
  }

};

//  PAAS REQUEST CRUD

export const getRequestsAndClasses = async () => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    axios
    .get(`${server_uri}/db/paas/request?idVinculo=${idVinculo}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const getRequestsRoomGroup = async () => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    axios
    .get(`${server_uri}/db/paas/request/roomGroup?idVinculo=${idVinculo}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const getRequestInfo = async (hash) => {
  return new Promise((resolve, reject) => {

    axios
    .get(`${server_uri}/db/paas/request/validateLink?hash=${hash}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const createPaasRequest = async (name) => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    const body = {
      idVinculo: idVinculo,
      name: name,
    }

    axios
    .post(`${server_uri}/db/paas/request/add`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const editRoomGroupRequest = async (id) => {
  return new Promise((resolve, reject) => {
    const idVinculo = store.getters.getIdVinculo;

    const body = {
      idVinculo: idVinculo,
      id: id,
    }

    axios
    .post(`${server_uri}/db/paas/request/addRoomGroup`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const sendPaasClassesRequest = async (_classes, hash, _rooms) => {
  return new Promise((resolve, reject) => {
    const author = store.getters.getUserName;

    let rooms = _rooms.map(e => {
      return {
        bloco: e.bloco,
        nome: e.nome,
        tipo: e.tipo,
      }
    });

    let classes = _classes.map(e => {
      return {
        codigo: e.codigo,
        nome: e.nome,
        turma: e.turma,
        docente: e.docente,
        departamento: e.departamento,
        horario: e.horario,
        alunos: e.alunos,
        pcd: e.pcd,
        author: author,
        hash: hash,
        preferencias: e.preferencias,
      };
    });

    axios
    .post(`${server_uri}/db/paas/request/addClasses`,
      { classes, rooms },
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });


  })
  
}

export const resetRequest = async(hash) => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    const body = {
      idVinculo: idVinculo,
      hash: hash,
    }

    axios
    .post(`${server_uri}/db/paas/request/reset`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const deletePaasRequest = async (hash) => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    const body = {
      idVinculo: idVinculo,
      hash: hash,
    }

    axios
    .post(`${server_uri}/db/paas/request/remove`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const deletePaasClassesRequest = async (hash) => {
  return new Promise((resolve, reject) => {
    const body = {
      hash: hash,
    }

    axios
    .post(`${server_uri}/db/paas/request/removeClassesRequest`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}


export const deleteOnePaasClassRequest = async (id) => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    const body = {
     id: id,
     idVinculo: idVinculo,
    }

    axios
    .post(`${server_uri}/db/paas/request/removeOneClassRequest`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}

export const duplicateRequest = async (request) => {
  return new Promise((resolve, reject) => {

    const idVinculo = store.getters.getIdVinculo;

    const body = {
     ...request,
     idVinculo: idVinculo,
    }

    axios
    .post(`${server_uri}/db/paas/request/duplicate`,
      body,
    )
    .then((res) => {
      resolve(res.data);
    })
    .catch(err => {
      console.log(err);
      reject([]);
    });
  });
}
