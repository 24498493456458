<template>
  <div>
    <Header title="Otimização de Horário" />

    <v-container class="all">

      <div class="main">
        <v-row>
          <v-col>
            <div class="card-m">
              <v-container class="d-flex flex-no-wrap justify-space-between">
                <v-row>
                  <v-col :class="isMobile ? 'pa-5' : 'pa-12'">
                    <v-card-title class="text-h4 wb">Olá, {{ userName }}.</v-card-title>

                    <v-card-subtitle class="subtitle-1 mt-2 wb">
                      Escolha <span class="font-weight-black">Simulador</span> para
                      planejar seus próximos períodos,
                      ou <span class="font-weight-black">Histórico</span> para ver
                      simulações passadas. <span @click="dialog = true" class="saiba-mais">Saiba mais.</span>
                    </v-card-subtitle>

                  </v-col>
                  <v-col v-if="!isMobile" class="img">
                    <v-avatar class="ma-3" :size="250" tile>
                      <v-img :src="require('../assets/paas-dash.svg')"></v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12" sm="6">
            <LargeButton type="primary" title="Simulador" subtitle="Planeje as disciplinas de seus próximos períodos."
              @click.native="openSimulador" />
          </v-col>
          <v-col cols="12" sm="6">
            <LargeButton type="secondary" title="Histórico" subtitle="Consulte planejamentos passados."
              @click.native="openHistorico" />
          </v-col>
        </v-row>

      </div>

    </v-container>

    <v-dialog v-model="dialog" width="500">
      <UpdateCard type="poh" :action="setUpdateDisplay"/>
    </v-dialog>

  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import LargeButton from "@/components/LargeButton.vue";
import UpdateCard from "@/components/UpdateCard.vue";

export default {
  name: "PohDashboard",
  components: {
    Header,
    LargeButton,
    UpdateCard,
  },
  data() {
    return {
      dialog: false,
    }
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Poh",
    });
  },
  computed: {
    userName() {
      return this.$store.getters.getUserFirstName;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
  },
  methods: {
    openSimulador() {
      this.$router.push({ path: "/poh-simulador" });
    },
    openHistorico() {
      this.$router.push({ path: "/poh-historico" });
    },
    setUpdateDisplay() {
      this.dialog = false;
      if(this.$store.getters.getUpdatePohDisplay){
        this.$store.dispatch("setUpdatePohDisplay");
      }
    }
  },
  mounted() {
    this.dialog = this.$store.getters.getUpdatePohDisplay;
  },
}
</script>

<style scoped>
.all {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.img {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  max-width: 1000px;
  /* background-color: aqua; */
  padding: 0 10px;
}

.card-m {
  width: 100%;
  border-radius: 10px;
  height: 250px;
  background-color: var(--v-accent-base);
}

.wb {
  word-break: keep-all;
}

.ma-mo {

  margin: -60px 0px 0px 5px;
}


.main-text {
  font-size: 1.7vw;
}

.padding-res {
  padding: 30px;
}

.saiba-mais {
  color: #1565C0;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {

  .main-text {
    font-size: 5vw;
  }

  .padding-res {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .main-text {
    font-size: 1.6rem;
  }
}
</style>