<template>
    <div>
        <div v-if="loading" class="full center">
            <NotFound title="Um Momento" type="loading" text="Preparando as identificações das salas" />
        </div>
        <template v-else-if="found">
            <div class="full" v-for="room in rooms" :key="room.idx">
                <div class="center full-20">
                    <img v-if="info.centro == 'Centro de Informática'" class="test1" width="80" src="../assets/ci_logo.png" />
                    <img v-else-if="info.centro == 'Centro de Tecnologia'" class="test1" width="80" src="../assets/ct.png" />
                    <p class="text-h6 float-right mt-5 ml-2"> {{ info.centro }}</p>
                </div>
                <div class="center full-80">
                    <div class="main">
                        <div class="card pt-8">
                            <p class="text-h4 font-weight-bold">{{ room.bloco }} {{ room.nome }}</p><br>
                            <div class="qr pt-5">
                                <div class="border b1"></div>
                                <div class="border b2"></div>
                                <div class="border b3"></div>
                                <div class="border b4"></div>
                                <qrcode-vue class="" :value="`https://sa.ci.ufpb.br/salas/${info.id}?sala=${room.sala}`"
                                    :size="200" level="H"></qrcode-vue>
                            </div>

                            <p class="text-caption mt-2">{{
                                `https://sa.ci.ufpb.br/salas/${info.id.toLocaleLowerCase()}?sala=${room.sala}` }}</p>
                            <br>

                            <p class="text-button">Encontre todas as salas em:</p>
                            <p class="text-body-2 mt-n6">{{ `https://sa.ci.ufpb.br/salas/${info.id.toLocaleLowerCase()}` }}
                            </p><br>

                            <div class="test">
                                <img class="test1" width="50" src="../assets/logo.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="full center">
            <NotFound title="Algo deu errado" type="ops" text="Tente novamente mais tarde" />
        </div>

    </div>
</template>
  
<script>
import { getPublicSolution } from '@/api/paas';
import NotFound from '@/components/NotFound.vue';
import QrcodeVue from 'qrcode.vue';

export default {
    name: "PrintRooms",
    components: {
        NotFound,
        QrcodeVue
    },
    data() {
        return {
            test: [
                "feef",
                "ewfwef",
            ],
            solution: [],
            info: {
                id: '',
                centro: '',
                date: '',
                description: '',
                link: ''
            },
            rooms: [],
            loading: true,
            found: false,
            notPublished: false,
            notFound: {
                show: false,
                title: 'Eita! Parece que não há uma alocação pública.',
                text: 'Tente simular uma alocação. Após o resultado, clique em publicar solução.'
            },
        }
    },
    created() {
        this.$store.commit("setCurrentComponent", {
            component: "Public",
        });
    },
    methods: {
        async init() {
            let center = this.$store.getters.getCentro;

            this.info.link = `/salas/${center}`.toLocaleLowerCase();

            //get solution on database
            let res = await getPublicSolution(center, '');

            switch (res.status) {
                //if is published
                case "published": {
                    //save the solution
                    this.solution = JSON.parse(JSON.stringify(res.solution));

                    //close loading screem

                    this.info.centro = res.centro;
                    this.info.description = res.description;
                    this.info.date = res.date;
                    this.info.id = res.id;

                    this.loading = false;
                    this.found = true;

                    let rooms = [];

                    this.solution.solution.forEach((el, idx) => {

                        // let sala = (el.bloco.replace(' ', '-').toLocaleLowerCase() + '-' + el.nome.replace(' ', '-').toLocaleLowerCase()).normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                        // sala = sala.replace(' ', '-');
                        let sala = `${el.bloco}-${el.nome}`;
                        sala = sala.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[\W_]+/g, '-').toLowerCase().replace(/^-+|-+$/g, '');
                        // console.log(sala)

                        if (sala != 'nao-alocado') {
                            rooms.push({
                                ...el,
                                idx,
                                sala,
                            });
                        }
                    });

                    this.rooms = rooms;
                    break
                }

                //if academic center exists, but there is no solution
                case "not-published":

                    this.info.centro = res.centro;
                    this.info.description = res.description;
                    this.info.date = res.date;
                    this.info.id = res.id;


                    this.loading = false;
                    this.notPublished = true;
                    break
                //if academic center is not found
                default:
                    this.loading = false;
                    this.notFound.show = true;
                    break
            }

            this.componentKey += 1;
        },
    },
    async mounted() {

        await this.init();

    }
};
</script>

<style scoped>
.full {
    height: 100vh;
    page-break-after: always;
}

.full-80 {
    height: 80vh;
}

.full-20 {
    height: 20vh;
}

.center {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.test1 {
    margin-top: 10px;
}

.test {
    margin: 0 auto;
}

.main {
    width: 1124px;
    height: 792px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.parent {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #0D73E2;
    font-size: 45px;
}

.card {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 50px 30px 50px;
    border: 3px solid #0D73E2;
    max-width: 80vw;
    text-align: center;
    border-radius: 5px;
    overflow-wrap: break-word;
}

.qr {
    margin: 0 auto;
    width: 240px;
    height: 240px;
    position: relative;
}

.qr-code {
    margin: auto 0;
}

.border {
    width: 40px;
    height: 40px;
    position: absolute;
    border: solid #0D73E2;
    z-index: 1;
}

.b1 {
    top: 0;
    left: 0;
    border-width: 4px 0 0 4px;
    border-radius: 6px 0 0 0;
}

.b2 {
    top: 0;
    right: 0;
    border-width: 4px 4px 0 0;
    border-radius: 0 6px 0 0;
}

.b3 {
    bottom: 0;
    right: 0;
    border-width: 0 4px 4px 0;
    border-radius: 0 0 6px 0;
}

.b4 {
    bottom: 0;
    left: 0;
    border-width: 0 0 4px 4px;
    border-radius: 0 0 0 6px;
}

.f-l {
    float: left;
}

.title-center {
    padding-left: 10px;
    font-size: 20px;
}
</style>