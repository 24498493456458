<template>
  <div class="px-md-16 px-2">
    <div class="px-md-16 px-0">
      <p class="text-h6">Resumo geral da ocupação</p>
      <AssistentCard
        text="Selecione um dia/horário na matriz para ter um resumo específico"
      />
      <p class="mt-8 mb-2 text-body-2">
        A maior quantidade de turmas alocadas em um horário é de:
        <span class="font-weight-bold">{{ max }}</span>
        . Os horários com maior ocupação são:
        {{ times.join(". ") }}.
      </p>
      <p class="mt-8 mb-2 text-body-2 font-weight-bold">
        Taxa de ocupação por horário:
      </p>
      <apexchart
        type="bar"
        height="250"
        :options="chart_data1.chartOptions"
        :series="chart_data1.series"
      ></apexchart>
      <p class="mt-8 mb-2 text-body-2 font-weight-bold">
        Por dia da semana:
      </p>
      <apexchart
        type="bar"
        height="250"
        :options="chart_data2.chartOptions"
        :series="chart_data2.series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import AssistentCard from "@/components/AssistentCard.vue";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  name: "HandleGeneralSummary",
  components: {
    AssistentCard,
  },
  props: {
    chart1: Object,
    chart2: Object,
    max: Number,
    times: Array,
  },
  data() {
    return {};
  },
  computed: {
    chart_data1() {
      return {
        series: [
          {
            name: "Ocupação",
            data: this.chart1.datasets,
          },
        ],
        chartOptions: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            categories: this.chart1.labels,
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#0757C7",
                  colorTo: "#0757C7",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      };
    },
    chart_data2() {
      return {
        series: [
          {
            name: "Ocupação",
            data: this.chart2.datasets,
          },
        ],
        chartOptions: {
          chart: {
            height: 250,
            type: "bar",
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              dataLabels: {
                position: "top", // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#304758"],
            },
          },

          xaxis: {
            categories: this.chart2.labels,
            position: "bottom",
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#0757C7",
                  colorTo: "#0757C7",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                },
              },
            },
            tooltip: {
              enabled: true,
            },
          },
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      };
    },
  },
};
</script>
