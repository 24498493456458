import store from "@/store/vuex";
import axios from "axios";

const server_uri = process.env.VUE_APP_SERVER_URI;

export const getCurriculoAndHistorico = async (
  /** @type String*/ matricula,
  access_token
) => {
  return new Promise((resolve, reject) => {
    store.commit('setStudentDataStatus', 'waiting')
    axios
      .get(
        `${server_uri}/soh/getSigaaData?Matricula=${matricula}&AuthToken=${access_token}`
      )
      .then(response => {
        store.commit('setStudentDataStatus', 'ready')

        const { studentData, curriculumData } = response.data;

        store.commit("setPohData", {
          studentData: studentData,
          curriculumData: curriculumData
        });

        resolve(true);
      })
      .catch(e => {
        store.commit('setStudentDataStatus', 'error')
        console.log(e);
        reject(false);
      });
  });
};

export const resetData = () => {
  return new Promise((resolve, reject) => {
    let matricula = store.getters.getMatricula;
    let token = store.getters.getAccessToken;
    getCurriculoAndHistorico(matricula, token)
    .then((_) => {
      resolve(_)
    })
    .catch(() => {
      reject("error getCurriculoAndHistorico");
    });
  })
};

export const solverPoh = async (pohInput, numTaken) => {
  return new Promise((resolve, reject) => {
    // Ask server for solution hash
    axios
      .post(
        `${server_uri}/solve/poh`,
        { instance: pohInput },
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then(res => {
        // Commit hash and status to store

        const numClasses = pohInput.classes.filter(e => e.cursada != -1).length;

        store.dispatch("addToHistoricoPOH", {
          hash: res.data.hash,
          status: res.data.status,
          numTaken: numTaken,
          numTakenSemesters: pohInput.numTakenSemesters,
          numClasses: numClasses
        });

        resolve(true);
      })
      .catch(e => {
        console.log("error: ", e);
        reject(false);
      });
  });
};

export const formatSolutionToDisplay = (solution) => {
  // Array of semesters
  let semesters = [];
  // For each class in response
  solution.classes.forEach((el) => {
    // Put each class in its appropriate semester
    if (semesters[el.semester] === undefined) {
      semesters[el.semester] = {
        semester: (el.semester + 1).toString(),
        data: [],
      };
    }
    semesters[el.semester].data.push(el);
    // Deleting unused semester property
    delete el.semester;
  });
  // Set this.solution to what we got
  return semesters;
}