import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store/vuex";
import VueGtag from "vue-gtag";
import VueClipboard from 'vue-clipboard2'


// import VueGapi from 'vue-gapi'

Vue.use(VueClipboard)
Vue.config.productionTip = false;

//Configuration VueAnalytics 
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_ANALYTICS_ID }
}, router);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
