var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.parsedHeaders,"items":_vm.items,"search":_vm.search,"items-per-page":5,"show-select":"","custom-filter":_vm.filerIgnoreAccents},on:{"item-selected":function($event){return _vm.$emit('item-selected', $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"baseline","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticClass:"filter rounded-lg",attrs:{"append-icon":"mdi-filter-outline","label":"Pesquise...","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(!_vm.fixed)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"color":"primary","large":""}},'v-btn',attrs,false),on),[_vm._v(" Adicionar ")])]}}],null,false,2460005746),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.parsedHeaders),function(header){return _c('v-col',{key:header.name,attrs:{"cols":"12","sm":"6","md":"4"}},[(header.type == 'bool')?_c('v-checkbox',{staticClass:"my-3",attrs:{"label":header.text},model:{value:(_vm.newItem[header.value]),callback:function ($$v) {_vm.$set(_vm.newItem, header.value, $$v)},expression:"newItem[header.value]"}}):(!header.ignore)?_c('v-text-field',{attrs:{"outlined":"","label":header.text},model:{value:(_vm.newItem[header.value]),callback:function ($$v) {_vm.$set(_vm.newItem, header.value, $$v)},expression:"newItem[header.value]"}}):_vm._e()],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancelAddItem}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addItem}},[_vm._v(" Salvar ")])],1)],1)],1)],1):_vm._e()],1)],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_vm._v(" "+_vm._s(header.text)+" "),(header.helpText != undefined)?_c('HelpIcon',{key:header.value,attrs:{"small":"","text":header.helpText}}):_vm._e()]},proxy:true}}),_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:header.value},[(_vm.checkHeaderType(header.value, 'bool'))?_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(
          _vm.checkHeaderType(header.value, 'select') ||
          _vm.checkHeaderType(header.value, 'select-mult')
        )?_c('v-select',{staticClass:"select",attrs:{"items":item[header.value],"multiple":_vm.checkHeaderType(header.value, 'select-mult'),"outlined":"","dense":""},on:{"change":_vm.updateSelected},scopedSlots:_vm._u([(_vm.checkHeaderType(header.value, 'select-mult'))?{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(" "+_vm._s(item.text.length > 10 ? item.text.substr(0, 9) + "…" : item.text)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-caption"},[_vm._v(" (+ outros) ")]):_vm._e()]}}:null],null,true),model:{value:(_vm.selectedSubitems[header.value][item.id]),callback:function ($$v) {_vm.$set(_vm.selectedSubitems[header.value], item.id, $$v)},expression:"selectedSubitems[header.value][item.id]"}}):(_vm.checkHeaderType(header.value, 'string'))?_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[
          _vm.validation.required,
          _vm.validation.specific[header.value] || undefined ]},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):(_vm.checkHeaderType(header.value, 'number'))?_c('v-text-field',{staticClass:"narrow",attrs:{"outlined":"","dense":"","rules":[_vm.validation.number, _vm.validation.required]},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])],1)]}}}),(!_vm.fixed)?{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("Remover")])],1)],1)],1)]}}:null],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }