<template>
  <div>
    <section v-if="isMobile" class="mainMobile">
      <div class="slider-container" :style="style">
        <div class="slide" v-for="semester in semesters" :id="semester.semester" :key="semester.semester"
          @touchstart="touchStart" @touchend="touchEnd" @touchmove="touchMove" @mousedown="touchStart"
          @mouseup="touchEnd" @mouseleave="touchEnd" @mousemove="touchMove">
          <Semester class="float-left" :data="semester.data" :semester="semester.semester"
            :done="semester.semester <= semestersTaken" />
        </div>
      </div>

    </section>
    <v-container v-else v-resize="handleChangedWidth" class="mb-10">

      <v-row>
        <v-col cols="1">
          <v-icon @click="handleLeft" x-large class="ml-xs-1 ml-md-4 mt-17" v-bind:class="[
            disableLeft ? 'icon-disable' : 'icon-enable',
          ]">
            mdi-chevron-left
          </v-icon>
        </v-col>

        <v-col :cols="isMobile ? 4 : 10 / columns" v-for="semester in semesters.slice(start, end)"
          :key="semester.semester" class="mg-m">
          <Semester class="float-left" :data="semester.data" :semester="semester.semester"
            :done="semester.semester <= semestersTaken" />
        </v-col>

        <v-col cols="1">
          <v-icon @click="handleRight" x-large class="mt-17" v-bind:class="[
            disableRight ? 'icon-disable' : 'icon-enable',
          ]">
            mdi-chevron-right
          </v-icon>
        </v-col>
      </v-row>
    </v-container>

  </div>

</template>

<script>
import Semester from "./Semester.vue";

export default {
  name: "Plan",

  components: {
    Semester,
  },

  props: {
    /** @type { {semester: string, data: {name: string, id: string, timeslot:string}[]}[] } */
    plan: Array,
    semestersTaken: Number,
  },

  data() {
    return {
      start: 0,
      end: 0,
      columns: 0,
      disableLeft: true,
      disableRight: false,
      widthBackUp: 0,
      mobileD: {
        isDragging: false,
        startPos: 0,
        currentTranslate: 0,
        prevTranslate: 0,
        animationId: 0,
        currentIndex: 0,
        translateX: 0,
      }
    };
  },

  methods: {
    handleRight() {
      if (this.end < this.plan.length) {
        this.start += this.columns;
        this.end += this.columns;
      }
      this.handleArrows();
    },

    handleLeft() {
      if (this.start) {
        this.start -= this.columns;
        this.end -= this.columns;
      }
      this.handleArrows();
    },

    // Update  number of columns and restart the start and end variables
    handleChangedWidth() {
      //if width is < 500 (mobile), these presets will happen only one time.
      if (!this.isMobile && this.widthBackUp != this.width) {
        // Component's (not window's) width divided by column width
        this.columns = Math.floor(this.$el.clientWidth / 275);
        this.widthBackUp = this.width;
      } else if (this.isMobile && this.end != 2) {
        this.columns = 2;
      }
      this.start = 0;
      this.end = this.columns;
      this.handleArrows();
    },

    handleArrows() {
      this.disableLeft = this.start == 0 ? true : false;
      this.disableRight =
        this.end >= this.plan.length ? true : false;
    },




    touchStart(e) {

      this.mobileD.currentIndex = Number(e.currentTarget.id) -1;
      this.mobileD.startPos = this.getPositionX(e);
      this.mobileD.isDragging = true;

      this.mobileD.animationId = window.requestAnimationFrame(this.animation)

      console.log("start", this.mobileD)

    },

    touchEnd() {
      console.log(this.mobileD)

      this.mobileD.isDragging = false;
      window.cancelAnimationFrame(this.mobileD.animationId);

      const movedBy = this.mobileD.currentTranslate - this.mobileD.prevTranslate;
      console.log("moved", movedBy)

      if(movedBy < -100 && this.mobileD.currentIndex < this.semesters.length -1)
        this.mobileD.currentIndex += 1;
      
      if(movedBy > 100 && this.mobileD.currentIndex > 0)
        this.mobileD.currentIndex -= 1;

      this.setPositionByIndex();
      console.log(this.mobileD.currentIndex)


    },

    touchMove(e) {
      if (this.mobileD.isDragging) {
        const currentPosition = this.getPositionX(e);
        this.mobileD.currentTranslate = this.mobileD.prevTranslate + currentPosition - this.mobileD.startPos;  
      }
    },

    getPositionX(e) {
      return e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
    },

    animation() {
      this.mobileD.translateX = this.mobileD.currentTranslate;
      if (this.mobileD.isDragging)
        window.requestAnimationFrame(this.animation)
    },

    setPositionByIndex() {
      console.log("translate", (this.mobileD.currentIndex) * (-1 * this.width))
      this.mobileD.currentTranslate = (this.mobileD.currentIndex) * (-1 * this.width);
      this.mobileD.prevTranslate = this.mobileD.currentTranslate;
      this.mobileD.translateX = this.mobileD.currentTranslate;
    }


  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
    width() {
      return 0.7 * this.$vuetify.breakpoint.width;
    },
    semesters() {
      return this.plan.filter(el => el.data != null && el.data.length > 0);
    },
    style() {
      return { transform: 'translateX(' + this.mobileD.translateX + 'px)' }
    }
  },
  mounted() {
    this.handleChangedWidth();
    if(this.isMobile){
      this.mobileD.currentIndex = this.semestersTaken;
      this.setPositionByIndex();
    }
  },
};
</script>

<style scoped>
.mt-17 {
  margin-top: 300px;
}

.icon-enable {
  color: var(--v-primary-base);
}

.icon-disable {
  color: var(--v-accent-darken3);
}


@media only screen and (max-width: 500px) {
  .mg-m {
    padding: 0;
    margin-left: 15px;
  }
}

/* .mainMobile {
  background-color: aqua;
} */

.teste {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to right, rgba(255,0,0,0) 70%, rgba(255,255,255,0.6));
  position: absolute;
  z-index: 3;
}

.slider-container {
  z-index: 1;
  display: inline-flex;
  overflow: hidden;
  transform: translate(0);
  transition: transform 0.3s ease-out;
  touch-action: pan-y;
  position: absolute;
}

.slide {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}
</style>