<template>
  <v-app>
    <div :class="showDrawer ? 'l-side-color' : ''">
      <div :class="showDrawer ? 'max-width' : ''">
        <NavDrawer v-if="showDrawer" />
        <v-main>
          <router-view />
        </v-main>
      </div>
    </div>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer.vue";

export default {
  name: "App",
  components: { NavDrawer },

  data: () => ({
    //
  }),
  computed: {
    /** @returns {boolean} */
    loggedin() {
      return this.$store.getters.isLoggedIn;
    },
    showDrawer() {
        let show = false;
        if(this.$store.getters.isLoggedIn)
          show = true;
        if(this.$store.getters.isPublic)
          show = false;
      return show;
    }
  },
  created() {
    // Restart timer on page reload
    if (this.loggedin) {
      this.$store.dispatch("startTimer");
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 1920px) {
  .max-width {
    width: 1920px;
    margin: 0 auto;
    background-color: white;
    min-height: 100%;
  }

  .max-width-nl {
    margin: 0 auto;
    min-height: 100%;
  }

  .l-side-color {
    height: 100%;
    background: linear-gradient(
      90deg,
      var(--v-accent-base) 50%,
      white 50%
    );
  }
}
</style>
