<template>
    <div>
        <div v-if="noData" class="no_data">
            <p>A configuração de preferências aparecerão ao adicionar turmas e salas.</p>
        </div>
        <div v-else>
            <!-- <p>{{ labels }}</p>
            <p>{{ preferences }}</p>
            <p>{{ cells }}</p> -->
            <table>
                <thead>
                    <th></th>
                    <th v-for="(label, idx) in labels" :key="idx" class="vertical-text">{{ label }}</th>
                </thead>
                <br>
                <tbody>
                    <tr v-for="(line, idx) in cells" :key="idx" class="line">
                        <td>{{ line.name.toUpperCase() }}</td>
                        <!-- <p>{{ line }}</p> -->

                        <td v-for="(col, i) in line.data " :key="i" @mousedown="startDrag(idx, i)" @mousemove="handleDrag"
                            @mouseup="stopDrag" @change="(e) => handleChange(e, line)" :ref="`cellRefs_${idx}_${i}`">
                            <v-text-field class="field" outlined dense v-model="col.value" type="number"
                                :min="0"></v-text-field>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
 
<script>
export default {
    name: "TabSetPreferences",
    props: {
        preferences: Array,
        labels: Array,
        noData: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        preferences: function (_new) {
            this.updateCells(_new)
        }
    },
    data() {
        return {
            cells: [],
            selectedCellIndexes: { rowIndex: null, colIndex: null },
        }
    },
    mounted() {
        this.updateCells(this.preferences)
    },
    methods: {
        handleChange(_e, _line) {
            let updatedPreferences = []
            _line.data.forEach((cell) => {
                cell.value = Number(cell.value) < 0 ? 0 : Number(cell.value) > 10 ? 10 : Number(cell.value)
                updatedPreferences.push({ name: cell.name, value: cell.value})
            });
            this.$emit('update', _line.idx, updatedPreferences);
        },
        updateCells(_preferences) {

                this.cells = JSON.parse(JSON.stringify(_preferences)).map((e) => {
                
                let order = []
                this.labels.forEach((_) => {
                    order.push(e.data.find((_e) => _e.name == _))
                })

                e.data = order;

                return {
                    ...e,
                    draggedOver: false,
                    editable: true,
                }
            })
        },
        startDrag(rowIndex, colIndex) {
            this.selectedCellIndexes = { rowIndex, colIndex };
        },
        handleDrag(event) {
            const { rowIndex, colIndex } = this.selectedCellIndexes;

            if (rowIndex !== null && colIndex !== null) {
                const selectedCell = this.cells[rowIndex].data[colIndex];
                //const rect = this.$refs.cellRefs[rowIndex][colIndex].getBoundingClientRect();


                const mouseX = event.clientX;
                const mouseY = event.clientY;

                this.cells.forEach((line, i) => {
                    line.data.forEach((cell, j) => {
                        const cellRect = this.$refs[`cellRefs_${i}_${j}`][0].getBoundingClientRect();
                        if (mouseX >= cellRect.left && mouseX <= cellRect.right && mouseY >= cellRect.top && mouseY <= cellRect.bottom) {
                            this.cells[i].data[j].value = selectedCell.value,
                                this.cells[i].data[j].draggedOver = true;
                        } else {
                            this.cells[i].data[j].draggedOver = false;
                        }
                    });
                });
            }
        },
        stopDrag() {
            this.selectedCellIndexes = { rowIndex: null, colIndex: null };

            // Clear the dragged-over property from all cells
            this.cells.forEach((line) => {
                let updatedPreferences = []
                line.data.forEach((cell) => {
                    cell.draggedOver = false;
                    updatedPreferences.push({ name: cell.name, value: cell.value })
                });
                this.$emit('update', line.idx, updatedPreferences)
            });
        },
    },
    computed: {
    }
};
</script>

<style scoped>
.no_data {
    width: 100%;
    min-height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.line:hover {
    background-color: #F5F5F5;
}

.vertical-text {
    text-align: right;
    writing-mode: vertical-lr;
    text-orientation: mixed;
}

.without-btn {
    margin-left: 20px;
}

.with-btn {
    margin-left: 70px;
}

.field {
    width: 70px;
    margin-right: 1px;
    height: 40px;
}

.field:hover {
    background-color: #E3F2FD;
}

.cell {
    border: 1px solid #ccc;
    width: 50px;
    height: 20px;
    text-align: center;
    cursor: pointer;
}

.editable {
    background-color: #e6f7ff;
    /* Light blue background for editable cells */
}

.dragged-over {
    z-index: 2;
    background-color: #64B5F6;
    /* Light red background for cells being dragged over */
}
</style>
