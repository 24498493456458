<template>
  <v-data-table
    :headers="parsedHeaders"
    :items="items"
    :search="search"
    class="table"
    :items-per-page="5"
    show-select
    v-model="selectedItems"
    @item-selected="$emit('item-selected', $event)"
    :custom-filter="filerIgnoreAccents"
  >
    <!-- Elements that go on top of the table -->
    <template v-slot:top>
      <!-- Container to keep elements in the same line -->
      <v-container fluid>
        <v-row align="baseline" justify="space-between">
          <v-col cols="auto">
            <!-- Filter bar -->
            <v-text-field
              v-model="search"
              append-icon="mdi-filter-outline"
              label="Pesquise..."
              single-line
              outlined
              class="filter rounded-lg"
            />
          </v-col>

          <!-- Elements for table CRUD when table isn't fixed -->
          <v-col cols="auto" v-if="!fixed">
            <!-- Add/Edit element dialog -->
            <v-dialog v-model="dialog" max-width="600px">
              <!-- Dialog-opening button -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  large
                  class="rounded-lg"
                  v-bind="attrs"
                  v-on="on"
                >
                  Adicionar
                </v-btn>
              </template>

              <!-- Actual dialog card -->
              <v-card>
                <v-card-title>
                  {{ dialogTitle }}
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- Generate an input field for each header -->
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-for="header in parsedHeaders"
                        :key="header.name"
                      >
                        <!-- Checkbox if header is bool -->
                        <v-checkbox
                          v-if="header.type == 'bool'"
                          class="my-3"
                          :label="header.text"
                          v-model="newItem[header.value]"
                        />
                        <!-- Else, text field -->
                        <v-text-field
                          v-else-if="!header.ignore"
                          outlined
                          :label="header.text"
                          v-model="newItem[header.value]"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    text
                    @click="cancelAddItem"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn color="primary" text @click="addItem">
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Add help icons next to headers -->
    <template
      v-for="header in headers"
      v-slot:[`header.${header.value}`]
    >
      {{ header.text }}
      <HelpIcon
        :key="header.value"
        v-if="header.helpText != undefined"
        small
        :text="header.helpText"
      />
    </template>

    <!-- Turn cells into input elements for header types other than display -->
    <template
      v-for="header in headers"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <div :key="header.value">
        <!-- Bool columns' items into checkboxes -->
        <v-simple-checkbox
          v-if="checkHeaderType(header.value, 'bool')"
          v-ripple
          v-model="item[header.value]"
        />

        <!-- Select columns' items into select boxes -->
        <v-select
          v-else-if="
            checkHeaderType(header.value, 'select') ||
            checkHeaderType(header.value, 'select-mult')
          "
          :items="item[header.value]"
          v-model="selectedSubitems[header.value][item.id]"
          @change="updateSelected"
          :multiple="checkHeaderType(header.value, 'select-mult')"
          outlined
          dense
          class="select"
        >
          <template
            v-if="checkHeaderType(header.value, 'select-mult')"
            v-slot:selection="{ item, index }"
          >
            <span v-if="index === 0">
              {{
                item.text.length > 10
                  ? item.text.substr(0, 9) + "&hellip;"
                  : item.text
              }}
            </span>
            <span v-if="index === 1" class="text-caption">
              (+ outros)
            </span>
          </template>
        </v-select>

        <!-- String columns' items into text fields -->
        <v-text-field
          v-else-if="checkHeaderType(header.value, 'string')"
          v-model="item[header.value]"
          outlined
          dense
          :rules="[
            validation.required,
            validation.specific[header.value] || undefined,
          ]"
        />

        <!-- Number columns' items into smaller text fields -->
        <v-text-field
          v-else-if="checkHeaderType(header.value, 'number')"
          v-model="item[header.value]"
          outlined
          dense
          :rules="[validation.number, validation.required]"
          class="narrow"
        />

        <!-- Defaults to simply displaying cell data -->
        <span v-else>
          {{ item[header.value] }}
        </span>
      </div>
    </template>

    <!-- Make action column's items menus -->
    <template v-slot:[`item.actions`]="{ item }" v-if="!fixed">
      <v-menu>
        <!-- Dots: Open menu -->
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- Edit -->
          <v-list-item @click="edit(item)">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>

          <!-- Remove -->
          <v-list-item @click="remove(item)">
            <v-list-item-title>Remover</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
/**
 * @typedef {object} Header
 * @property {string} text
 * @property {?string} helpText
 * @property {string} value
 * @property {?string} align
 * @property {?"bool"|"select"|"select-mult"|"display"|"number"|"string"} type
 * @property {?boolean} ignore
 * @property {?boolean} sortable
 * @property {?function} validation
 */

/**
 * VALUE TYPE WHEN USING SELECT HEADER: Array<SelectArrayElement>
 * @typedef {object} SelectArrayElement
 * @property {string} text
 * @property {any} value
 * @property {boolean} selected
 */

import HelpIcon from "./HelpIcon.vue";

export default {
  name: "InputTable",
  components: {
    HelpIcon,
  },
  props: {
    headers: {
      /** @type {{ new(): Header[] }} */
      type: Array,
    },
    initItems: {
      type: Array,
    },
    fixed: Boolean,
  },
  data() {
    return {
      dialog: false, // Opens or closes add/edit dialog
      editing: -1, // Index of edited item
      search: "", // Filtered string
      items: [],
      newItem: {},
      defaultItem: {},
      parsedHeaders: [],
      selectHeaders: [],
      selectedSubitems: {},
      validation: {
        number: (val) => !isNaN(val) || "Digite um número",
        required: (val) => !!val || "Campo vazio",
        specific: {},
      },
      selectedItems: [...this.initItems],
    };
  },
  computed: {
    dialogTitle() {
      return this.editing < 0
        ? "Adicionar entrada"
        : "Editar entrada";
    },
  },
  methods: {
    resetNewItem() {
      this.newItem = { ...this.defaultItem };
    },

    cancelAddItem() {
      this.dialog = false;
      this.resetNewItem();

      this.editing = -1;
    },

    addItem() {
      this.dialog = false;
      // Add new item with typed data to items array
      this.items.push(this.newItem);

      if (this.editing > 0) {
        // If editing, remove edited item
        this.items.splice(this.editing, 1);
        this.editing = -1;
      }

      this.$emit("update", this.items);
      this.resetNewItem();
    },

    edit(item) {
      // Set index of edited item
      this.editing = this.items.indexOf(item);
      // Set new item's data to start with edited item's current data
      this.newItem = { ...item };
      // Open add/edit dialog
      this.dialog = true;
    },

    remove(item) {
      this.editing = this.items.indexOf(item);
      this.items.splice(this.editing, 1);
      this.$emit("update", this.items);
      this.editing = -1;
    },

    // For "select" type headers: Updates selected property in
    // original items to reflect subitems in selectedSubitems array
    updateSelected() {
      this.selectHeaders.forEach((header) => {
        this.items.forEach((item) => {
          item[header].map((subitem) => {
            // for multi select
            if (
              Array.isArray(this.selectedSubitems[header][item.id])
            ) {
              // if subitem is in proper selectedSubitems array
              if (
                this.selectedSubitems[header][item.id].includes(
                  subitem.value
                )
              ) {
                subitem.selected = true;
              } else {
                subitem.selected = false;
              }
            }
            // for single select
            else {
              subitem.selected =
                this.selectedSubitems[header][item.id] ===
                subitem.value;
            }
            return subitem;
          });
        });
      });
    },

    checkHeaderType(headerValue, type) {
      const header = this.headers.find((h) => h.value == headerValue);
      return header.type == type;
    },

    // Custom filter to ignore accents
    filerIgnoreAccents(value, search) {
      // Convert string to upper case and remove accents
      const normalizeString = (str) => {
        return str
          .toLocaleUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      };

      return (
        value != null && // Value exists
        search != null && // Search is not empty
        typeof value == "string" && // Value is a string (can't compare against other types)
        normalizeString(value.toString()).indexOf(
          normalizeString(search) // Find search as substring of value
        ) !== -1 // If found
      );
    },
  },
  created() {
    // Copy initItems into items
    this.items = [...this.initItems];

    // Copy headers into parsed headers, and add actions header
    this.parsedHeaders = [
      ...this.headers,
      {
        text: "",
        value: "actions",
        ignore: true,
        sortable: false,
        align: "end",
      },
    ];

    // Init list of select headers
    this.headers.forEach((header) => {
      if (header.type === "select-mult" || header.type === "select") {
        this.selectHeaders.push(header.value);
      }
    });

    // Create variables to keep selected subitems of select-type headers
    this.selectHeaders.forEach((header) => {
      this.selectedSubitems[header] = {};

      this.items.forEach((item) => {
        // An array for multi selects
        if (this.checkHeaderType(header, "select-mult")) {
          this.selectedSubitems[header][item.id] = [];

          // Add pre-selected subitems to array
          item[header].forEach((subitem) => {
            if (subitem.selected == true) {
              this.selectedSubitems[header][item.id].push(
                subitem.value
              );
            }
          });
        }
        // Simple variable for single selects
        else {
          item[header].forEach((subitem) => {
            if (subitem.selected == true) {
              this.selectedSubitems[header][item.id] = subitem.value;
            }
          });
        }
      });
    });

    // Get validation rules for string headers
    this.headers.forEach((header) => {
      if (
        header.type === "string" &&
        header.validation !== undefined
      ) {
        this.validation.specific[header.value] = header.validation;
      }
    });

    // Make a default item
    this.parsedHeaders.forEach((header) => {
      if (header.ignore !== true) {
        // Set default value for each header type
        switch (header.type) {
          case "bool":
            this.defaultItem[header.value] = false;
            break;
          case "select":
          case "select-mult":
            this.defaultItem[header.value] = {};
            break;
          default:
            this.defaultItem[header.value] = "";
            break;
        }
      }
    });
    this.resetNewItem();
  },
};
</script>

<style scoped>
.select {
  max-width: 200px;
  max-height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.narrow {
  max-width: 80px;
}

.table {
  margin: 28px;
}

td {
  background-color: aqua;
}
</style>