<template>
  <v-card :class="`text-center ${isMobile ? 'test' : ''}`" elevation="0">
    <p name="title" class="subtitle-2 text-center"> Período </p>
    <p name="subtitle" class="subtitle-2 title-col">{{
      semester
    }}</p>
    <ClassCard
      v-for="(subject, index) in data"
      :key="index"
      :name="subject.name"
      :code="subject.code"
      :time="subject.timeslot"
      :color="done ? '' : 'blue'"
    />
  </v-card>
</template>

<script>
import ClassCard from "./ClassCard.vue";

export default {
  name: "Semester",
  components: {
    ClassCard,
  },
  props: {
    /** @type { {name: string, id: string, timeslot:string}[] } */
    data: Array,
    semester: String,
    done: Boolean,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
  }
};
</script>

<style scoped>
.test {
  width: 80%;
}
.title-col {
  margin: 0;
  font-weight: 900;
}
</style>