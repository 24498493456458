<template>
    <div>
        <div v-if="type == 'time'">
            <div  v-for="(tt, i) in time" :key="i">
                <div v-for="(t, idx) in tt" :key="idx">
                    <div class="card-time text-center pt-3">
                        <p>{{ t }}</p>
                    </div> 
                </div>
            </div>
        </div>
        <div v-if="type == 'day'">
            <div class="text-center">
                <p>{{ title }}</p>
                <div v-for="(classObj, idx) in data" :key="idx">
                    <div v-if="classObj.nome == ''" >
                        <div v-for="tt in Array.from(Array(classObj.repeat).keys())" class="card" :key="tt"></div>
                    </div>

                    <div class="card2" v-if="classObj.nome != ''" :style="`height: ${52*classObj.repeat}px \;`">
                        <div class="card-class pa-2"  @click="() => detail(classObj.idx)" :style="`height: ${52*classObj.repeat}px \;`">
                            <p class="nome">{{ classObj.nome.length > 23*classObj.repeat ? classObj.nome.substring(0,20*classObj.repeat) + '...' : classObj.nome}}</p>
                            <p class="codigo">{{ classObj.codigo }}</p>
                        </div>                                            
                    </div>    
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name: 'ColCalendar',
    props: {
        type: String,
        data: Array, //trype [{codigo: String, nome: String, id: Number}]
        title: String,
        detail: Function,
    },
    data() {
        return {
            time: [['07:00', '08:00', '09:00', '10:00', '11:00', '12:00'],
                    ['13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
                    ['19:00', '19:50', '20:40', '21:30']
                ]
        }
    },
}
</script>

<style scoped>

.card-class{
    min-width: 50px;
    margin: 2px 0;
    color: #FAFAFA;
    /* background-color: #90CAF9; */
    /* background-color: ; */
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    background: rgb(71,118,230);
    /* background: linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%); */
    background: linear-gradient(90deg, rgba(20,145,255,1) 0%, 50deg, rgba(71,118,230,1));


    /* background: rgb(85,129,241);
    background: linear-gradient(50deg, rgba(85,129,241,1) 0%, rgba(17,83,252,1) 100%); */
}

.card {
    padding: 0 6px;
    width: 100%;
    min-height: 51px;
    margin: 2px 2px 2px 0;
    background-color: rgba(245, 245, 245, 0.6);
    /* background-color: rgba(250, 250, 250, 1); */

}

.card2 {
    padding: 0 6px;
    width: 100%;
    margin: 2px 2px 2px 0;
    background-color: rgba(245, 245, 245, 0.6);
    /* background-color: rgba(250, 250, 250, 1); */


}

.card-time {
    border-radius: 4px;
    padding: 0 6px;
    min-width: 50px;
    min-height: 51px;
    margin: 2px 2px 2px 0;
    background-color: rgba(245, 245, 245, 0.6);
    /* background-color: rgba(250, 250, 250, 1); */


}

.card p {
    margin: 0;
}

.nome {
    margin: 0;
    font-size: 14px;
}
.codigo {
    margin: 0;
    font-size: 12px;
}
</style>