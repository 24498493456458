<template>
  <div>
    <Header title="FAQ" />
    <!-- Page select -->
    <v-card flat>
      <v-row align="center" justify="center">
        <v-btn-toggle
          v-model="chosen"
          mandatory
          title
          group
          color="primary"
        >
          <v-btn
            v-for="page in pages"
            :key="page.name"
            :value="page.name"
          >
            {{ page.name }}
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-card>
    <!-- Page contents -->
    <div v-for="page in pages" :key="page.name">
      <div v-if="page.name === chosen">
        <div v-for="qa in page.data" :key="qa.q" class="qa-container">
          <h3>{{ qa.q }}</h3>
          <p>{{ qa.a }}</p>
        </div>
      </div>
    </div>
    <!-- Empty space at bottom -->
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "FAQ",
  components: {
    Header,
  },
  data() {
    return {
      pages: [
        {
          name: "Geral",
          data: [
            {
              q: "O que é o SACI?",
              a: "O SACI (Sitema de Apoio a decisões do Centro de Informática) é um sistema que utiliza ferramentas de Pesquisa Operacional para auxiliar em decisões acadêmicas na UFPB.",
            },
            {
              q: "O que é um 'módulo'?",
              a: "São as partes que compõe SACI. Cada módulo se propõe a ajudar a resolver algum problema específico do âmbito acadêmico: seja planejar as disciplinas cursadas nos próximos períodos, ou definir em que salas as aulas de um centro devem ocorrer, entre muitas outras possibilidades.",
            },
            {
              q: "Encontrei um erro/tenho uma sugestão. Como posso enviá-lo(a)?",
              a: "No final da barra de navegação você pode encontrar um link para contato. Por favor, envie por lá.",
            },
            {
              q: "Sou servidor e o sistema me parece vazio. Alguma coisa deu errado?",
              a: "Infelizmente no momento só possuimos um módulo: Otimização de Horários. Esse módulo é feito apenas para discentes, e servidores não tem acesso a ele. No futuro adicionaremos também módulos para servidores.",
            },
          ],
        },
        {
          name: "Otimização de Horários",
          data: [
            {
              q: 'O SACI não realiza matrícula!'
            },
            {
              q: "O que é o módulo de Otimização de Horários (POH)?",
              a: "O POH tem por objetivo determinar quais disciplinas um discente deve cursar ao longo dos próximos períodos letivos de forma que a pessoa possa se graduar o mais rápido possível. O POH leva em consideração o histórico do discente, bem como pré-requisitos e choques de horário das disciplinas, entre muitas outras coisas. Além disso, é possível que alguns parâmetros como carga-horária e número de créditos (entre outros) sejam customizados a fim de se gerar planos (distribuições de disciplinas) que atendam as preferências do discente. O discente pode gerar vários planos diferentes (considerando diferentes configurações) e escolher aquele que mais o agrada.",
            },
            {
              q: "O meu plano sugeriu cursar TCC/Monografia/Estágio supervisionado no começo do curso. Posso mudar isso?",
              a: "Sim! O parâmetro 'Cursar no final?', que se encontra na lista de disciplinas, permite que você informe o sistema que só deseja cursar aquela disciplina em seu último ou penúltimo período na universidade.",
            },
            {
              q: "O horário da disciplina parece incorreto, o que fazer?",
              a: 'O sistema considera o horário mais recente da disciplina, independentemente da turma. Caso queira considerar o horário de uma turma específica basta alterar o horário no parâmetro "horário", que se encontra na lista de disciplinas.',
            },
            {
              q: "Por que aparecem disciplinas repetidas no meu plano?",
              a: "O processo de equivalência de disciplinas não é feito de forma automática, portanto pode acontecer de um plano ser gerado com disciplinas equivalentes mas de códigos diferentes. Para que isso não aconteça basta desmarcar a disciplina que queira remover da simulação, na lista de disciplinas."
            },
            {
              q: "Posso especificar alguma coisa no meu plano?",
              a: "SIM! Na verdade, você pode especificar quase tudo. O simulador tem diversos parâmetros que podem ser editados, como a CH máxima e mínima de um período, em que período uma disciplina deve ser cursada (na lista de disciplinas!), o horário de uma disciplina, entre muitas outras coisas. Experimente mudar esses parâmetros para ter um plano só seu!",
            },
            {
              q: "O que o parâmetro X do simulador faz?",
              a: "Cada parâmetro possui um ícone de '?' do seu lado. Clique esse ícone para entender melhor o que aquele parâmetro determina.",
            },
            {
              q: "Posso escolher não cursar uma disciplina específica?",
              a: "Sim! Basta entrar na lista de disciplinas, achar a disciplina que você não quer cursar e clicar na caixinha com um '✓' a esquerda ou acima do nome da disciplina.",
            },
            
          ],
        },
      ],
      chosen: "Geral",
    };
  },
};
</script>

<style scoped>
.qa-container {
  margin: 75px 15% 0 15%;
}
</style>