<template>
    <div>
        <template v-if="loading">
            <Loading></Loading>
            <NotFound type="loading" title="Carregando..." text="Isso pode levar alguns minutos. Não feche esta aba." />
        </template>

        <div v-else-if="exibResult">
            <v-btn :disabled="disabled" plain small class="mb-2" @click="close">
                <v-icon small color="blue">
                    mdi-arrow-left
                </v-icon>
                cancelar</v-btn>

            <div class="parent mt-4">
                <div class="child">
                    <p class="text-body-2 font-weight-bold">Selecione as turmas que serão atualizadas, e depois clique em
                        atualizar.</p>
                </div>

                <v-btn dark color="blue" class="float-right" small @click="handleSaveSelected">Atualizar</v-btn>
            </div>

            <table class="table mt-5">
                <tr class="line">
                    <th class="column header text-button">
                        <v-checkbox color="info" @change="handleAll"></v-checkbox>
                    </th>
                    <th class="text-body-2 font-weight-bold a-l pl-2">Código</th>
                    <th class="text-body-2 font-weight-bold a-l pl-2">Turma</th>
                    <th class="text-body-2 font-weight-bold a-l pl-2">Nome</th>
                    <th class="text-body-2 font-weight-bold a-l pl-2">Horário</th>
                    <th class="text-body-2 font-weight-bold a-l pl-2">Discentes</th>
                </tr>

                <template v-for="(turma, idx) in showChanges" class="test">

                    <tr :key="'divider-' + idx">
                        <td colspan="6">
                            <v-divider class="width-100"></v-divider>
                        </td>
                    </tr>

                    <tr :class="`line ${idx % 2 == 0 ? 'line-bg' : ''}`" :key="'main-' + idx">
                        <td><v-checkbox v-model="res[idx].select" class="ml-2" :value="res[idx].select"
                                color="info"></v-checkbox></td>
                        <td class="column text-body-2">{{ turma.codigo }}</td>
                        <td class="column text-body-2">{{ turma.turma }}</td>
                        <td class="column text-body-2">{{ turma.nome }}</td>

                        <!-- show horario -->
                        <td class="column text-body-2" v-if="turma.horario">
                            <p><span class="text-decoration-line-through"> {{ turma.horario.old }}</span> <span><v-icon
                                        class="mt-n1" size="small">mdi-arrow-right</v-icon></span> {{ turma.horario.new }}
                            </p>
                        </td>
                        <td class="column text-body-2" v-else>
                            <p><span><v-icon class="mt-n1" size="small">mdi-check</v-icon> Atualizado</span></p>
                        </td>

                        <!-- show discentes -->

                        <td class="column text-body-2" v-if="turma.alunos">
                            <p> <span class="text-decoration-line-through"> {{ turma.alunos.old }}</span> <span><v-icon
                                        class="mt-n1" size="small">mdi-arrow-right</v-icon></span> {{ turma.alunos.new }}
                            </p>
                        </td>
                        <td class="column text-body-2" v-else>
                            <p><span><v-icon class="mt-n1" size="small">mdi-check</v-icon> Atualizado</span></p>
                        </td>
                    </tr>

                    <tr v-if="turma.docente" :key="'secondary-' + idx">
                        <td colspan="6">
                            <div class="expand-card pa-2">
                                <div class="text-body-2 txt-color-grey">
                                    <p> <span class="txt-color-black">Docente: </span> <span
                                            class="text-decoration-line-through"> {{
                                                turma.docente.old }}</span> <span> <v-icon class="mt-n1"
                                                size="small">mdi-arrow-right</v-icon>
                                            {{ turma.docente.new }} </span></p>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <tr v-if="updatePreAloc && turma.local" :key="'secondary-2-' + idx">
                        <td colspan="6">
                            <div class="expand-card pa-2">
                                <div class="text-body-2 txt-color-grey">
                                    <p> <span class="txt-color-black">Local no SIGAA: </span> {{ turma.local.new }} </p>
                                    <p class="txt-color-black mt-n2">Local no SACI: <span>
                                            <v-select class="width-400" :items="rooms_names" v-model="res[idx].pre_alocacao"
                                                dense outlined></v-select>
                                        </span></p>
                                    <p class="text-caption">*O local deve ser alterado manualmente. </p>

                                </div>
                            </div>
                        </td>
                    </tr>


                </template>
            </table>

        </div>
        <div v-else-if="err">
            <p>Algo deu errado, tente novamente mais tarde.</p>
        </div>
        <div v-else>
            <v-btn :disabled="disabled" plain small class="mb-2" @click="close">
                <v-icon small color="blue">
                    mdi-arrow-left
                </v-icon>
                Voltar</v-btn>

            <div>

                <v-row>
                    <v-col>
                        <div class="about-card px-2 px-md-8">
                            <p class="text-h6 mt-4">Atualizar Dados das Turmas</p>
                            <p class="mt-8 text-body-2">{{ updatePreAloc ?
                                'Ao longo do período, '
                                : 'Se você estiver aproveitando os dados de simulações passadas, ' }}
                                as informações como {{ updatePreAloc ? 'local, ' : '' }} horário, número de alunos e
                                docentes
                                podem ter sofrido alterações.</p>
                            <p class="mt-8 text-body-2"> Ao clicar em atualizar, buscaremos as informações atualizadas do
                                período letivo mais recente.</p>
                            <p class="mt-8 text-body-2">Essa função pode ser útil para manter as turmas atualizadas,
                                possibilitando uma melhor alocação.</p>
                            <p v-if="classes.length == 0">Nenhuma turma adicionada.</p>
                            <v-btn class="mb-4 mt-3" :disabled="classes.length == 0" dark small color=blue
                                @click="handleUpdate">
                                Buscar por mudanças
                            </v-btn>
                        </div>

                    </v-col>
                    <v-col class="hidden-md-and-down">
                        <v-img class="mt-n4 ml-3 mb-4" max-width="350" src="../../../assets/Update-pana.svg"></v-img>
                    </v-col>
                </v-row>

            </div>

        </div>

    </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import NotFound from "@/components/NotFound.vue";
import { validateEditSolution } from '@/api/paas';
import axios from "axios";

export default {
    name: "UpdatePaas",
    data() {
        return {
            disabled: false,
            loading: false,
            exibResult: false,
            err: false,
            res: [],
        }
    },
    props: {
        deptos: Array,
        classes: Array,
        rooms: Array,
        updatePreAloc: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Loading,
        NotFound,
    },
    computed: {
        showChanges() {
            return this.res.map((e, idx) => {

                let alunos = e.changed.find(el => el.field == 'alunos');
                let horario = e.changed.find(el => el.field == 'horario');
                let docente = e.changed.find(el => el.field == 'docente');
                let local = e.changed.find(el => el.field == 'local');

                let i = this.classes.findIndex(el => el.codigo + el.turma == e.codigo + e.turma);

                return {
                    idx: idx,
                    classId: i,
                    codigo: e.codigo,
                    turma: e.turma,
                    nome: e.nome,
                    alunos,
                    horario,
                    docente,
                    local,
                    // alunos: alunos ? `${alunos.old} -> ${alunos.new}` : '-',
                    // horario: horario ? `${horario.old} -> ${horario.new}` : '-',
                    // docente: docente ? `${docente.old} -> ${docente.new}` : '-',
                }
            })
        },
        rooms_names() {
            return this.rooms.map(e => `${e.bloco} ${e.nome}`);
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        handleUpdate() {
            this.disabled = true;
            this.loading = true;

            const server_uri = process.env.VUE_APP_SERVER_URI;

            axios
                .post(`${server_uri}/paas/updatePaasClasses`, {
                    deptos: this.deptos,
                    classes: this.classes,
                })
                .then((res) => {
                    this.res = res.data;
                    this.res.forEach(e => {
                        e.select = false;
                    })
                    this.disabled = false;
                    this.loading = false;
                    this.exibResult = true;
                }).catch(err => {
                    console.log(err)
                    this.disabled = false;
                    this.loading = false;
                    this.err = true;
                })

            setTimeout(() => {

            }, 2000)
        },
        handleSaveSelected() {
            let classesCopy = JSON.parse(JSON.stringify(this.classes));

            this.res.forEach((e) => {
                if (e.select) {
                    let alunos = e.changed.find(el => el.field == 'alunos');
                    let horario = e.changed.find(el => el.field == 'horario');
                    let docente = e.changed.find(el => el.field == 'docente');

                    let i = classesCopy.findIndex(el => el.codigo + '-' + el.turma == e.codigo + '-' + e.turma);

                    if (i != -1) {
                        classesCopy[i].alunos = alunos ? alunos.new : classesCopy[i].alunos;
                        classesCopy[i].horario = horario ? horario.new : classesCopy[i].horario;
                        classesCopy[i].docente = docente ? docente.new : classesCopy[i].docente;
                        classesCopy[i].pre_alocacao = e.pre_alocacao;
                    } else {
                        console.log(e)
                        console.log(i)
                        let test = classesCopy.map(el => el.codigo + '-' + el.turma)
                        console.log(e.codigo + '-' + e.turma, test[0])
                        console.log(test[0] == e.codigo + '-' + e.turma)
                    }



                }
            })

            if (this.updatePreAloc) {
                let error = validateEditSolution({ classes: classesCopy, rooms: this.rooms });
                if (error != '') {
                    alert(error);
                } else {
                    this.$emit('update', classesCopy);
                }
            } else {
                this.$emit('update', classesCopy);
            }
        },
        handleAll(value) {
            this.res.forEach(e => {
                e.select = value;
            });

            //triggers changes
            this.res = JSON.parse(JSON.stringify(this.res));
        },
        testar() {
            console.log("showChanges", this.showChanges);
            console.log("res", this.res);
        }
    },
}
</script>

<style scoped>
.test {
    background-color: aqua;
}

.parent {
    position: relative;
    display: flex;
    width: 100%;
}

.child {
    flex-grow: 1;
}

.about-card {
    background-color: #f5f5f5;
    border-radius: 6px;
    border: solid 1px #00000013;
}


table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.column {
    padding: 8px;
    color: #757575;
}

.txt-color-grey {
    color: #757575;
}

.txt-color-black {
    color: black;
}

.tr-red,
.tr-grey {
    width: 70px;
    text-align: center;
    padding: 2px;
    border-radius: 12px;
}

.nfe {
    overflow-wrap: anywhere;
}

.tr-red {
    background-color: #ef5350;
    color: #e0e0e0;
}

.tr-grey {
    background-color: #e0e0e0;
}

.pointer {
    cursor: pointer;
}

.icon {
    width: 50px;
}

/* .line-bg {
    background-color: #f5f5f5;
} */


.header {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
}

.width-100 {
    width: 100%;
    display: flex;
}

.width-400 {
    max-width: 400px;
}

.a-l {
    text-align: left;
}
</style>