<template>
  <v-card class="card mx-2 pa-4">
    <h5 class="title text-subtitle-1 font-weight-bold">
      {{ title }}
      <HelpIcon v-if="helpText != undefined" :text="helpText" small />
    </h5>

    <!-- display default -->
    <div v-if="!edit">
      <h6
        v-if="value1 !== undefined"
        :class="doubleValues ? 'value2' : 'value1'"
      >
        {{ value1 }} <br />
        <span class="subtitle-1" v-if="label1 !== undefined">
          {{ label1 }}
        </span>
      </h6>
      <h6
        v-if="value1 !== undefined"
        :class="doubleValues ? 'value2' : 'value1'"
      >
        {{ value2 }} <br />
        <span class="subtitle-1" v-if="label2 !== undefined">
          {{ label2 }}
        </span>
      </h6>
      <!-- edit icon -->
      <v-icon v-if="!locked" large class="edit" @click="edit = !edit">
        mdi-square-edit-outline
      </v-icon>
    </div>

    <!-- display input box -->
    <div v-if="edit">
      <input
        v-if="value1 !== undefined"
        name="value1"
        :placeholder="value1"
        @change="handleValue1"
      />
      <label v-if="value1 !== undefined" for="value1"> {{ label1 }} </label>

      <input
        v-if="value2 !== undefined"
        name="value2"
        :placeholder="value2"
        @change="handleValue2"
      />
      <label v-if="value2 !== undefined" for="value2"> {{ label2 }} </label>

      <br />
      <!-- confirmation icon -->
      <v-icon class="save" large @click="save">
        mdi-check-circle
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import HelpIcon from "@/components/HelpIcon.vue";

export default {
  name: "LargeInputBox",
  components: {
    HelpIcon,
  },
  props: {
    title: String,
    value1: [String, Number],
    label1: String,
    value2: [String, Number],
    label2: String,
    type: String,
    helpText: String,
    locked: Boolean
  },
  setup(props) {
    return { type: props.type };
  },
  data() {
    return {
      edit: false,
      newValue1: undefined,
      newValue2: undefined,
    };
  },
  computed: {
    doubleValues() {
      return this.value1 !== undefined && this.value2 !== undefined;
    },
  },
  methods: {
    parseValue(val) {
      if (this.type === "Number" && isNaN(Number(val))) {
        return false;
      }
      return this.type === "Number" ? Number(val) : val;
    },
    handleValue1(event) {
      const parsed = this.parseValue(event.target.value);
      if (parsed === false) {
        return;
      }
      this.newValue1 = parsed;
    },
    handleValue2(event) {
      const parsed = this.parseValue(event.target.value);
      if (parsed === false) {
        return;
      }
      this.newValue2 = parsed;
    },
    save() {
      if (this.newValue1 !== undefined)
        this.$emit("update:value1", this.newValue1);
      if (this.newValue2 !== undefined)
        this.$emit("update:value2", this.newValue2);

      this.edit = !this.edit;
    },
  },
};
</script>

<style scoped>
.card {
  background-color: var(--v-accent-base);
  width: 100%;
  min-height: 202px;
  text-align: center;
}
.title {
  margin-bottom: 20px;
}

.value1 {
  font-size: 0.9rem;
  width: 100%;
}

.value2 {
  font-size: 0.9rem;
  width: 50%;
  float: left;
}

.edit {
  color: var(--v-primary-base);
  clear: both;
  margin-top: 10px;
}

input {
  border: solid 1.5px #b1b7b9;
  border-radius: 8px;
  margin: 5px;
}

.save {
  color: var(--v-primary-base);
  margin-top: 10px;
  clear: both;
}
</style>