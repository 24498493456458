import store from "@/store/vuex";
import axios from "axios";

const server_uri = process.env.VUE_APP_SERVER_URI;

// Redirects to login screen
export const loadLoginScreen = async () => {
  logout();
  const response = await axios.get(`${server_uri}/oidc/login-screen`);
  location.assign(response.data.redirect_uri);
};

export const login = async (/** @type {string} */ code) => {
  return new Promise((resolve, reject) => {
    // Get token from backend
    axios
      .get(`${server_uri}/oidc/user-login?code=${code}`)
      .then(response => {
        // Add tokens to store
        store.commit("setTokens", {
          access_token: response.data.access_token,
          id_token: response.data.id_token,
          refresh_token: response.data.refresh_token
        });

        // Add user data to store
        store.commit("setUserData", response.data.user_data);

        // Pass it along to next 'then' block
        return response;
      })
      .then(response => {
        // Start timer until sessions expires
        store.dispatch("startTimer", {
          expires_in: (response.data.expires_in - 10)
          // remove 10 seconds to take into account time it took to login
        });
      })
      .catch(e => {
        console.error(e);
        reject(false);
      })
      .finally(() => {
        resolve(true);
      })
  });
};

export const logout = () => {
  store.commit("clearTokens");
  store.commit("clearUserData");
};
