<template>
  <div>
    <Header title="Login" />
    <div class="text-center">
      <!-- Error handling -->
      <div v-if="!success">
        <NotFound type="sorry" title="Erro na autenticação" text="Desculpe, não foi possível fazer o login neste momento. Não obtemos resposta do SIGAA, nosso sistema de autenticação externa. Por favor, tente novamente mais tarde." btn="voltar" :btnFunction="goBack" />
        <!-- <h4>
          Ocorreu um erro em sua autenticação, por favor tente
          novamente.
        </h4>
        <PrimaryButton :onClick="goBack" class="mt-15"
          >Voltar</PrimaryButton
        > -->
      </div>

      <!-- Loading -->
      <div v-if="loading">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
//import PrimaryButton from "@/components/PrimaryButton.vue";
import NotFound from "@/components/NotFound.vue";
import { login, logout } from "@/api/oidc.js";
import { getCurriculoAndHistorico } from "@/api/poh.js";

export default {
  components: { 
    Header, 
    //PrimaryButton, 
    NotFound },
  data() {
    return {
      /** @type {boolean} */
      success: true,
      /** @type {boolean} */
      loading: true
    };
  },

  methods: {
    goBack() {
      logout();
      this.$router.replace({ name: "Home" });
    }
  },

  async created() {
    const code = this.$route.query.code;

    login(code)
      .then(() => {
        //if (process.env.VUE_APP_RESTRICT_CI_ONLY == "true") {
        //  if (
        //    this.$store.getters.getCourseName !=
        //    "Engenharia Da Computação" &&
        //    this.$store.getters.getCourseName !=
        //    "Ciência Da Computação"
        //  ) {
        //    alert(
        //      "Desculpe, mas no momento o sistema só está disponível para os cursos Engenharia da Computação e Ciência da Computação."
        //    );
        //    this.goBack();
        //  }
        //}

        //store curriculo and historico if student
        if (this.$store.getters.userIsStudent) {
          //verify if data is empty
          const isEmpty =
            this.$store.getters.getStudentData == "none";
          //verify if there is new a version
          const newVersion = this.$store.getters.getUpdatePohDisplay;
          //verify if not concluded
          const waiting =
            this.$store.getters.getStudentDataStatus == "waiting";
          //verify if got error
          const error =
            this.$store.getters.getStudentDataStatus == "error";
          console.log(isEmpty, newVersion, waiting, error);

          if (isEmpty || newVersion || waiting || error) {
            let matricula = this.$store.getters.getMatricula;
            let token = this.$store.getters.getAccessToken;

            getCurriculoAndHistorico(matricula, token).catch(() => {
              throw "error getCurriculoAndHistorico";
            });
          }
        }

        //verify if there is a redirect request
        let url = this.$store.getters.getRedirect;
        if (url.length != 0) {
          this.$store.commit("clearRedirect");
          this.$router.push(url);
        }
        // redirect to app
        else this.$router.replace({ name: "Home" });
      })
      .catch(() => {
        this.success = false;
        this.loading = false;
      });
  }
};
</script>
