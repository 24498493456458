<template>
  <div class="px-md-9 px-2">
    <p class="text-h6 mt-7">Ocupação</p>

    <div v-if="loading">
      <p>carregando</p>
    </div>

    <div v-else>
      <p class="text-body-2 font-weight-bold">
        Escala de intensidade:
      </p>

      <SquareGrid />

      <div class="mb-10" />

      <v-row>
        <v-col :cols="12" :md="4">
          <HandleMatrix
            :matrix="matrix_rate"
            :rowHeader="rows"
            :colHeader="cols"
            :item="item"
            @click-item="handleClick"
          />
        </v-col>
        <v-col :cols="12" :md="8">
          <HandleTimeSummary
            v-if="timeSummary[0]"
            :title="timeSummary[1]"
            :max="max_value"
            :rate="timeSummary[2]"
            :classes="timeSummary[3]"
            :rooms="timeSummary[4]"
            @close="handleClick"
            @click-plus="handleClickPlus"
          />
          <HandleGeneralSummary
            v-else
            :chart1="chart1"
            :chart2="chart2"
            :max="max_value"
            :times="times_with_max_value"
          />
        </v-col>
      </v-row>

      <div class="mb-10" />
    </div>
  </div>
</template>

<script>
import SquareGrid from "@/components/paas/SquareGrid.vue";
import HandleMatrix from "@/handles/paas/HandleMatrix.vue";
import HandleGeneralSummary from "@/handles/paas/HandleGeneralSummary.vue";
import HandleTimeSummary from "@/handles/paas/HandleTimeSummary.vue";

export default {
  name: "HandleOcupacao",
  components: {
    SquareGrid,
    HandleMatrix,
    HandleGeneralSummary,
    HandleTimeSummary
  },
  props: {
    loading: Boolean,
    matrix_rate: Array,
    rows: Array,
    cols: Array,
    chart1: Object,
    chart2: Object,
    max_value: Number,
    matrix_search: Array,
    times_with_max_value: Array,
    matrix_search_empty: Array,
  },
  data() {
    return {
      matrix_empty: [],
      item: [-1, -1],
    };
  },
  computed: {
    timeSummary() {
      let [i, j] = this.item;

      if (i == -1) return [false, null, null, null];

      let title = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo"
      ];

      return [
        true,
        `Resumo: ${title[j]}, ${this.rows[i]}`,
        this.matrix_rate[i][j],
        this.matrix_search[i][j],
        this.matrix_search_empty[i][j],
      ];
    }
  },
  methods: {
    handleClick(i, j) {
      if (this.item[0] == i && this.item[1] == j)
        this.item = [-1, -1];
      else this.item = [i, j];
    },
    handleClickPlus(idx) {
      this.$emit('click-plus', idx)
    },
  }
};
</script>

<style></style>
