<template>
  <v-card class="card pa-5 mr-4">
    <div>
      <h2 class="float-left mb-6">
        {{ title }}
        <HelpIcon
          v-if="helpText != undefined"
          :text="helpText"
        />
      </h2>

      <v-icon x-large class="icon mb-6" @click="handleClick">
        mdi-square-edit-outline
      </v-icon>
    </div>

    <p class="description mb-4">{{ description }}</p>
    <h4>{{ detail.slice(0, end) }}...</h4>
  </v-card>
</template>

<script>
import HelpIcon from "@/components/HelpIcon.vue";

export default {
  name: "HugeInputBox",
  components: {
    HelpIcon,
  },
  props: {
    title: String,
    description: String,
    detail: String,
    handleClick: Function,
    helpText: String,
  },
  computed: {
    end() {
      return this.$vuetify.breakpoint.width < 500 ? 180 : 300;
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 430px;
  background-color: var(--v-accent-base);
}
.description {
  margin-top: 20px;
  clear: both;
}

.icon {
  float: right;
  color: var(--v-primary-base);
}
</style>