<template>
  <div>
    <div v-if="loading">
      <p>carregando</p>
    </div>
    <div class="px-md-9 px-2" v-else>
      <p v-if="isMobile" class="text-button clear pl-2">
        Salas disponíveis
      </p>
      <p v-else class="text-h6 pl-2 mt-7">
        Buscar por salas disponíveis
      </p>
      <v-row>
        <v-col :cols="12" :md="4" v-if="!isMobile">
          <HandleMatrix
            :matrix="matrix_rate"
            :rowHeader="rows"
            :colHeader="cols"
            :item="itemDefault"
            @click-item="handleClick"
          />
        </v-col>
        <v-col :cols="12" :md="8">
          <div class="px-md-10 mt-md-8">
            <v-text-field
              rounded
              outlined
              dense
              v-model="search"
              label="Pesquisar"
              :rules="rules.horario"
              hint="Utilize o padrão de horário da UFPB. Ex: 24M23. Para uma combinação de horários utilize espaço: 2M23 3T34"
            >
              <template v-slot:append-outer>
                <v-btn
                  fab
                  small
                  depressed
                  color="primary"
                  class="mt-n2"
                  @click="handleSearch"
                  ><v-icon dark small>
                    mdi-magnify
                  </v-icon></v-btn
                >
              </template>
            </v-text-field>

            <AssistentCard
              class="my-2"
              text="Clique na tabela de horários para visualizar as salas disponíveis."
            />

            <template v-if="isMobile">
              <v-btn
                rounded
                outlined
                class="my-4"
                small
                @click.stop="
                  displayMatrixMobile = !displayMatrixMobile
                "
              >
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                horários
                <v-icon small class="ml-2">{{
                  displayMatrixMobile
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
              <v-expand-transition>
                <HandleMatrix
                  v-show="displayMatrixMobile"
                  class="mb-4"
                  :matrix="matrix_rate"
                  :rowHeader="rows"
                  :colHeader="cols"
                  :item="itemDefault"
                  @click-item="handleClick"
                />
              </v-expand-transition>
            </template>

            <!-- ========================== display search result ================================= -->

            <div v-if="search_result.display">
              <p>
                <span class="pointer" @click.stop="restartSerach"
                  ><v-icon color="blue">mdi-arrow-left</v-icon></span
                >
                <span class="text-button"> {{ search_result.title }}</span>
              </p>
              <HandleRoomTable
                :rooms="search_result.rooms"
                :displayNoneMsg="search_result.rooms.length == 0"
                @click-plus="handleClickPlus"
              />
            </div>

            <!-- ======================= display default and click table ========================== -->
            <template v-else>
              <div v-if="default_ && time.no_range">
                <p class="my-2 text-button">{{ time.time }}</p>
                <p class="text-body-2">
                  Horário fora do intervalo da alocação. Todas salas
                  disponíveis.
                </p>
                <p class="text-body-2">
                  Pesquise ou clique na tabela de horários para
                  consultar salas disponíveis.
                </p>
              </div>
              <div v-if="timeSummary[0]">
                <p>
                  <span
                    class="pointer"
                    v-if="!default_"
                    @click.stop="default_ = true"
                    ><v-icon color="blue"
                      >mdi-arrow-left</v-icon
                    ></span
                  >
                  <span class="text-button">
                    {{ timeSummary[2] }}</span
                  >
                  <span
                    class="text-caption ml-2"
                    v-if="!timeSummary[3]"
                    >({{ timeSummary[3] }})</span
                  >
                </p>
                <HandleRoomTable
                  :rooms="timeSummary[3]"
                  :displayMaxMsg="rooms_qtd == timeSummary[3].length"
                  @click-plus="handleClickPlus"
                />
              </div>
            </template>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import HandleMatrix from "@/handles/paas/HandleMatrix.vue";
import HandleRoomTable from "@/handles/paas/HandleRoomTable.vue";
import AssistentCard from "@/components/AssistentCard.vue";

import { convertUFPBTimeFormatToIdx } from "@/utils/PaasOccupancyRate";

export default {
  name: "handleSearchEmpty",
  components: {
    HandleMatrix,
    HandleRoomTable,
    AssistentCard
  },
  props: {
    loading: Boolean,
    matrix_rate: Array,
    matrix_search_empty: Array,
    rooms_qtd: Number,
    rows: Array,
    cols: Array
  },
  data() {
    return {
      item: [-1, -1],
      search: "",
      displayMatrixMobile: false,
      search_result: {
        title: '',
        loading: true,
        display: false,
        rooms: []
      },
      time: {},
      week_label: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado"
      ],
      default_: true,
      rules: {
        horario: [
          v =>
            !v
              .split(" ")
              .map(_ => /([2-7])+[MTN]([1-6])+/.test(_))
              .includes(false) ||
            v == "" ||
            "Utilize o formato de horário da UFPB (e.g. 23M12)"
        ]
      }
    };
  },
  computed: {
    default_rooms() {
      return this.time.no_range
        ? []
        : this.matrix_search_empty[this.time.idx[0]][
            this.time.idx[1]
          ];
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
    itemDefault() {
      if (this.search_result.display) return [-1, -1];

      return this.default_
        ? this.time.no_range
          ? [-1, -1]
          : this.time.idx
        : this.item;
    },
    timeSummary() {
      let [i, j] = this.itemDefault;

      if (i == -1) return [false, null, []];

      let title = this.default_
        ? `Horário atual: ${this.time.time}`
        : `${this.week_label[j + 1]}, ${this.rows[i]}`;
      let subtitle = this.default_
        ? this.time.no_range
          ? ""
          : this.time.value
        : ``;

      return [true, subtitle, title, this.matrix_search_empty[i][j]];
    }
  },
  mounted() {
    this.getTime();
    if (!this.time.no_range) this.item = this.time.idx;
    setInterval(() => {
      this.getTime();
    }, 60000);
  },
  methods: {
    handleClickPlus(idx) {
      this.$emit('click-plus', idx)
    },
    handleClick(i, j) {
      if (this.item[0] == i && this.item[1] == j) {
        this.item = [-1, -1];
        this.default = true;
        return;
      }

      this.item = [i, j];
      this.default_ = false;
    },
    restartSerach() {
      this.search = "";
      this.search_result = {
        title: '',
        loading: true,
        display: false,
        rooms: []
      };
    },
    handleSearch() {
      if (
        !(this.rules.horario[0](this.search) === true) ||
        this.search == ""
      )
        return;

      this.search_result.loading = true;
      this.search_result.display = true;
      this.search_result.title = this.search;

      let count = 0;
      let rooms = {};

      this.search.split(" ").map(_time => {
        let idx = convertUFPBTimeFormatToIdx(_time);

        idx[0].forEach(day => {
          idx[1].forEach(time => {
            count++;

            let found = this.matrix_search_empty[time][day];

            found.forEach(room => {
              if (rooms[room.id]) rooms[room.id].count++;
              else
                rooms[room.id] = {
                  count: 1,
                  ...room
                };
            });
          });
        });

        return idx;
      });

      let found = [];

      Object.keys(rooms).forEach(key => {
        if (rooms[key].count != count || (rooms[key].bloco == "Não" && rooms[key].sala == "Alocado")) return;

        found.push(rooms[key]);
      });

      this.search_result.rooms = found;
      this.search_result.loading = false;

      return;
    },
    getTime() {
      let _time = new Date();
      let hour = _time.getHours();
      let min = _time.getMinutes();
      let day = _time.getDay();
      this.time = this.timeToUFPBFormat(hour, min, day);
    },
    timeToUFPBFormat(hour, min, day) {
      let _time = `${
        this.week_label[day]
      }, ${hour
        .toString()
        .padStart(2, "0")}:${min.toString().padStart(2, "0")}.`;
      let format = "";

      if (
        day == 0 ||
        hour < 7 ||
        hour >= 23 ||
        (hour == 22 && min > min > 10)
      )
        return {
          no_range: true,
          value: format,
          time: _time
        };

      format += day + 1;

      switch (true) {
        case hour < 13:
          format += "M" + (hour - 6);
          break;
        case hour < 19:
          format += "T" + (hour - 12);
          break;
        default:
          format +=
            "N" + Math.ceil(((hour - 19) * 60 + min + 1) / 50);
      }

      let idx = convertUFPBTimeFormatToIdx(format);
      idx = [idx[1][0], idx[0][0]];

      return {
        no_range: false,
        value: format,
        time: _time,
        idx
      };
    }
  }
};
</script>

<style scoped>
.clear {
  clear: both;
}

.pointer {
  cursor: pointer;
}
</style>
