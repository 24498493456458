export function getOccupancyData(_plan) {

  let labels = getLabes();
  let matrix = getMatrixOccupancy(_plan, labels.cols, labels.rows);
  let charts = getCharts(matrix.matrix_ocuppancy_rate, labels.cols, labels.rows);

  return {
    ...matrix,
    ...labels,
    ...charts,
  }

}

export function getMatrixOccupancy(_plan, _cols, _rows) {
  let rooms = _plan.solution.map((e, i) => {
    return {
      id: e.id,
      bloco: e.bloco,
      sala: e.nome,
      tipo: e.tipo,
      capacidade: e.capacidade,
      idx: i,
    };
  });

  let time, _time;
  let matrix = [];
  let matrix_empty = [];

  for (let i = 0; i < 18; i++) {
    time = [];
    _time = [];
    for (let j = 0; j < 6; j++) {
      time.push([]);
      _time.push([...rooms]);
    }
    matrix.push(time);
    matrix_empty.push(_time);
  }

  let matrix_ocp = JSON.parse(JSON.stringify(matrix));

  _plan.solution.forEach((room, i) => {
    room.classes.forEach((discipline, j) => {
      discipline.horario.split(" ").forEach(horario => {
   
        let timeMatrix = convertUFPBTimeFormatToIdx(horario)
        let idx = rooms.findIndex((_) => _.id == room.id)

        //now add classes to main matrix
        timeMatrix[0].forEach(week_day => {
          timeMatrix[1].forEach(time => {

            let idx2 =  matrix_empty[time][week_day].findIndex(_ => _.id == room.id)
            if(idx >= 0) matrix_empty[time][week_day].splice(idx2, 1);

            // if(room.id == 27447) {
            //     console.log(idx2, idx, i, room.nome, time, week_day, matrix_empty[time][week_day][idx], matrix_empty[time][week_day][i], matrix_empty[time][week_day])
            // }
            //matrix_empty[time][week_day].splice(idx, 1);
            matrix[time][week_day].push({
              codigo: discipline.codigo,
              nome: discipline.nome,
              docente: discipline.docente,
              sala: room.bloco + " " + room.nome,
              id: discipline.codigo + "-" + discipline.turma,
              j_idx: j,
              i_idx: i
            });
          });
        });
      });
    });
  });

  let biggest = 0;

  matrix.forEach(day => {
    day.forEach(h => {
      biggest = Math.max(biggest, h.length);
    });
  });

  let times_max = [];

  matrix.forEach((time, i) => {
    time.forEach((day, j) => {
      let rate = day.length / biggest;
      if (rate == 1)
        times_max.push(
          `${_cols[j]}, ${_rows[i]}`
        );
      matrix_ocp[i][j] = rate;
    });
  });

  return {
    matrix_search_occupancy: matrix,
    matrix_ocuppancy_rate: matrix_ocp,
    max_value: biggest,
    times_with_max_value: times_max.sort(),
    matrix_search_empty: matrix_empty
  };
}

export function convertUFPBTimeFormatToIdx(_horario) {
    //converts UFPB time format to array
    //example '24T23' -> [[0, 2], [7,8]]

    let timeMatrix = [[], []];
    let changeCol = 0;
    let decrement = 2;
    let turno = 0;

    for (let i = 0; i < _horario.length; i++) {
        if (isNaN(Number(_horario.charAt(i)))) {
          changeCol = 1;
          decrement = 1;
          switch (_horario.charAt(i).toUpperCase()) {
            case "M":
              turno = 0;
              break;
            case "T":
              turno = 6;
              break;
            case "N":
              turno = 12;
              break;
          }
        } else
          timeMatrix[changeCol].push(
            turno + Number(_horario.charAt(i)) - decrement
          );
      }
    return timeMatrix
}

export function getLabes() {
  return {
    cols: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    rows: [
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "19:50",
      "20:40",
      "21:30"
    ]
  };
}

export function getCharts(_rate, _cols, _rows) {
  let values = [];
  let values2 = [];
  let value2_temp = [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0]
  ];

  _rate.forEach((line, i) => {
    if (i >= 16) return;

    let count = 0;
    let sum = 0;

    line.forEach((e, j) => {
      if (e != 0) {
        count++;
        sum += e;

        value2_temp[j][0] += e;
        value2_temp[j][1] += 1;
      }
    });

    if (sum == 0) values.push(0);
    else values.push(Math.ceil((sum / count) * 100));
  });

  value2_temp.forEach(e => {
    if (e[1] == 0) values2.push(0);
    else values2.push(Math.ceil((e[0] / e[1]) * 100));
  });

  return {
    chart1: {
      labels: _rows,
      datasets: values
    },
    chart2: {
      labels: _cols,
      datasets: values2
    }
  };
}
