<template>

  <v-app-bar height="120" v-bind:class="[hiddenDrawer ? 'with-btn' : 'without-btn']" flat color="rgba(255,255,255,0)">   
    <!-- Title -->
    <v-toolbar-title :style="titleColor ? `color: ${titleColor};` : ''" >{{ title }}</v-toolbar-title>
  </v-app-bar>
 
</template>
 
<script>
export default {
  name: "Header",
  props: {
    title: String,
    titleColor: String,
  },
  computed: {
    hiddenDrawer() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  }  
  
};
</script>

<style scoped>

.without-btn {
  margin-left: 20px;
}
.with-btn {
  margin-left: 70px;
}

</style>
