<template>
  <div>
    <Header title="Simulador" />
    <p class="ml-9 text-subtitle-1">
      {{ userName }} - {{ userCurso }}
    </p>

    <div v-if="getStudentDataStatus === 'waiting'">
      <NotFound type="loading" title="Aguarde" text="Estamos atualizando os seus dados! Isto pode levar alguns minutos." />
    </div>
    <v-container v-else-if="getStudentDataStatus === 'ready'">
      <v-row>
        <v-col cols="12" md="6" class="mg-large-input">
          <v-row>
            <v-col cols="12" md="6" class="mg-large-input-m">
              <LargeInputBox
                helpText="A soma da carga horária das disciplinas de um período no plano estará sempre entre esses dois valores."
                title="Carga horária por período" :value1="studentData.minHours" label1="min."
                @update:value1="studentData.minHours = $event" :value2="studentData.maxHours" label2="max."
                @update:value2="studentData.maxHours = $event" type="Number" class="float-left" />
            </v-col>
            <v-col cols="12" md="6" class="mg-large-input-m">
              <!-- need to correct value-->
              <LargeInputBox helpText="Quantidade de períodos que já foram cursados pelo discente."
                title="Número de períodos já cursados" :value1="studentData.numTakenSemesters" label1="Períodos"
                @update:value1="
                  studentData.numTakenSemesters = $event
                  " type="Number" class="float-left" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6" class="mg-large-input-m">
              <LargeInputBox
                helpText="Um limite mínimo e máximo para o número de períodos no plano. Note que o sistema já busca a quantidade mínima possível de períodos até a graduação, e um limite máximo baixo demais pode levar a um plano impossivel de ser encontrado."
                title="Número de períodos para se formar" :value1="studentData.minSemesters" label1="min."
                @update:value1="studentData.minSemesters = $event" :value2="studentData.maxSemesters" label2="max."
                @update:value2="studentData.maxSemesters = $event" type="Number" class="float-left" />
            </v-col>
            <v-col cols="12" md="6" class="mg-large-input-m">
              <LargeInputBox
                helpText="A soma da carga horária das disciplinas optativas no plano deve ser maior do que ese valor. Note que o sistema conta automaticamente a carga horária das disciplinas que já foram cursadas."
                title="Carga horária mínima de disciplinas optativas" :value1="studentData.minOptional" label1="Horas"
                @update:value1="studentData.minOptional = $event" type="Number" class="float-left" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="5" class="mg-huge-input">
          <HugeInputBox
            helpText="Escolha opções para cada uma das disciplinas no seu currículo. Você pode (des)marcá-las como obrigatórias, mudar seu horário, remover ou adicionar pré-requisitos, e muito mais!"
            title="Disciplinas" :description="description" :handleClick="openDialog" :detail="hugeCardDetail" />
        </v-col>
      </v-row>


      <p v-if="waitingValidation" class="ml-9 mt-6" style="color: red">
        {{
          Waiting > 0
          ? `* Há ${Waiting} processos sendo executados no momento. Tente novamente quando as optimizações concluirem.`
          : "* Não há processos sendo executados. Você pode tentar novamente."
        }}
      </p>


      <v-row justify="center">
        <PrimaryButton :onClick="otimizar" class="mt-8 mb-2">
          Otimizar!
        </PrimaryButton>
      </v-row>

      <v-row justify="start">
        <PrimaryButton class="ml-10 mt-2 mb-8" variant="outlined" :onClick="restaurar"
          helpText="Seus dados serão restaurados, todas as alterações feitas serão apagadas. Isso pode levar alguns minutos.">
          restaurar dados
        </PrimaryButton>
      </v-row>



      <!-- Dialog - edit classes -->

      <v-dialog v-model="dialog" persistent v-bind="flexProps" max-width="1750">
        <v-card class="bg-color-base pt-6">
          <v-icon class="close" @click="() => (dialog = false)">
            mdi-close
          </v-icon>
          <InputTable :headers="headers" :initItems="classes" class="bg-color-base mg-mobile" fixed
            @item-selected="classSelected($event)" />
          <v-card-actions>
            <v-btn class="save" color="primary" @click="saveEditedItems">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog alerting user of unsaved changes -->
      <!-- Currently never opens as users can't exit without saving  -->
      <v-dialog v-model="dAlert" persistent max-width="300px">
        <v-card class="bg-color-base pt-6 text-center">
          <p>Você realmente deseja sair sem salvar?</p>
          <v-card-actions>
            <v-btn class="save" color="#BDBDBD" @click="() => (dAlert = false)">cancelar</v-btn>
            <v-btn class="save" color="primary" @click="closeDialogUnsaved">sair</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div v-else>
      <NotFound type="sorry" title="Algo deu errado." btn="Tentar novamente" :btnFunction="restaurar"
        text="Infelizmente não conseguimos atualizar os seus dados. Tente novamente mais tarde. Se o erro persistir entre em contato conosco no menu ao lado." />
    </div>
    
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import LargeInputBox from "@/components/LargeInputBox.vue";
import HugeInputBox from "@/components/HugeInputBox.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import InputTable from "@/components/InputTable.vue";
import { solverPoh, resetData } from "@/api/poh.js";
import NotFound from "@/components/NotFound.vue";

export default {
  name: "PohSimulador",
  components: {
    Header,
    LargeInputBox,
    HugeInputBox,
    PrimaryButton,
    InputTable,
    NotFound,
  },
  created() { },

  data() {
    //HugeInputBox props
    const description =
      "Edite pré-requisitos, quais disciplinas foram cursadas, quais disciplinas são obrigatórias, em que período cursar a disciplina, etc.";

    return {
      description,
      hugeCardDetail: "",
      dialog: false,
      //studentData: JSON.parse(JSON.stringify(this.$store.getters.getStudentData)),
      studentData: this.$store.getters.getStudentData,
      waitingValidation: false,
      headers: [
        {
          text: "Disciplina",
          value: "name",
          align: "start",
          sortable: true,
        },
        { text: "Código", value: "code" },
        { text: "Carga Horária", value: "ch", sortable: true },
        {
          text: "Horário",
          helpText:
            "O horário desta disciplina no formato usado pela UFPB (e.g. 23M12). É usado para evitar choques de horário.",
          value: "timeslot",
          type: "string",
          sortable: false,
          validation: (val) =>
            /([2-7])+[MTN]([1-6])+/.test(val) ||
            "Utilize o formato de horário da UFPB (e.g. 23M12)",
        },
        {
          text: "Obrigatória",
          value: "obrigatoria",
          helpText: "Se esta disciplina é obrigatória ou opcional.",
          type: "bool",
          sortable: true,
        },
        {
          text: "Co-requisitos",
          value: "coreq",
          helpText:
            "Lista de outras disciplinas que devem ser cursadas no mesmo período que esta.",
          type: "select-mult",
          sortable: false,
        },
        {
          text: "Pré-requisitos",
          value: "prereq",
          helpText:
            "Lista de outras disciplinas que devem ser cursadas antes desta.",
          type: "select-mult",
          sortable: false,
        },
        {
          text: "Cursada",
          value: "cursada",
          helpText:
            "Se esta disciplina já foi cursada, e em que período.",
          type: "select",
          sortable: false,
        },
        {
          text: "Período Min",
          value: "minsemester",
          helpText:
            "O plano recomendará que esta disciplina seja cursada após esse período. (Dica: Se quiser que esta disciplina seja cursada em um período específico, coloque o mesmo valor aqui e em 'Período Max')",
          type: "number",
        },
        {
          text: "Período Max",
          value: "maxsemester",
          helpText:
            "O plano recomendará que esta disciplina seja cursada antes desse período. (Dica: Se quiser que esta disciplina seja cursada em um período específico, coloque o mesmo valor aqui e em 'Período Min')",
          type: "number",
        },
        {
          text: "Cursar no final?",
          value: "final",
          helpText:
            "Se desejar, o sistema recomendará que essa disciplina seja cursada no último ou penúltimo período na universidade. (Dica: Pode ser útil para a disciplina TCC ou Monografia)",
          type: "select",
          sortable: false,
        },
      ],
      /** @type {{
       * name: string,
       * id: string,
       * code: string,
       * ch: number,
       * timeslot: string,
       * obrigatoria: boolean,
       * coreq: {text: string, value: string, selected: boolean}[],
       * prereq: {text: string, value: string, selected: boolean}[],
       * cursada: {text: string, value: number, selected: boolean}[],
       * minsemester: number,
       * maxsemester: number,
       * final: {text: string, value: string, selected: boolean}[]
       * }[] }
       */
      classes: [],
      classesBackUp: [],
      numTaken: 0,
      dAlert: false,
    };
  },
  computed: {
    userName() {
      return this.$store.getters.getUserName;
    },
    userCurso() {
      return this.$store.getters.userIsAdmin ? 'Simulação Admin - Engenharia de Computação' : this.$store.getters.getCourseName
    },
    Waiting() {
      return this.$store.getters.getWaitingPOH;
    },
    flexProps() {
      return this.$vuetify.breakpoint.width < 500
        ? { fullscreen: true }
        : null;
    },
    getStudentDataStatus() {
      return this.$store.getters.getStudentDataStatus;
    }
  },
  watch: {
    getStudentDataStatus(newStatus) {
      if (newStatus == 'ready') {
        this.createDetail(); // Get detailed text for "disciplinas"
        this.studentDataToClasses(); // Convert studentData to more useful array
        this.saveEditedItems(); // Only to update numTaken
      }
    }
  },
  methods: {
    otimizar() {
      if (this.Waiting >= 3) {
        this.waitingValidation = true;
      } else {
        this.classesToStudentData();
        solverPoh(this.studentData, this.numTaken);
        this.$router.push({ path: "/poh-historico" });
        this.waitingValidation = false;
      }
    },

    restaurar() {
      resetData()
      .then(() => {
        this.$router.go()
      });     
    },

    createDetail() {
      this.studentData.classes.forEach((el) => {
        this.hugeCardDetail += `${el.nome}, `;
      });
    },

    openDialog() {
      this.dialog = true;
    },

    closeDialogUnsaved() {
      this.dialog = false;
      this.dAlert = false;
    },

    saveEditedItems() {
      // Close edit window
      this.dialog = false;

      // Update numTaken
      this.numTaken = 0;
      this.classes.forEach((el) => {
        if (el.cursada.find((x) => x.value == -1).selected == false) {
          this.numTaken += 1;
        }
      });
    },

    classSelected(el) {
      // Find index of selected class in this.classes
      const index_in_classes = this.classes.indexOf(el.item);
      // If it was selected and it is not in this.classes
      if (el.value === true && index_in_classes == -1) {
        // Add to this.classes
        this.classes.unshift(el.item);
        // If it was deselected and it is in this.classes
      } else if (index_in_classes >= 0) {
        // Remove from this.classes
        this.classes.splice(index_in_classes, 1);
      }
    },

    // Join classes array back into studentData
    classesToStudentData() {
      const newStudentDataClasses = [];
      this.classes.forEach((el) => {

        const newObj = {
          // Copy classes element data directly where possible
          ch: el.ch,
          codigo: el.code,
          corequisitos: [],
          cursada: -1,
          final: false,
          horario: /([2-7])+[MTN]([1-6])+/.test(el.timeslot)
            ? el.timeslot
            : "0",
          id: el.id,
          nome: el.name,
          obrigatoria: el.obrigatoria,
          periodoMax:
            isNaN(Number(el.maxsemester)) ? 99 : Number(el.maxsemester),
          periodoMin:
            isNaN(Number(el.minsemester)) ? 1 : Number(el.minsemester),
          prefinal: false,
          prerequisitos: [],
        };

        // Parse periodo min/max
        const parsePeriodoMinMax = (val, def) => {
          const num = Number(val);
          if (isNaN(num)) return def;

          return num - this.studentData.numTakenSemesters >= 0
            ? num - this.studentData.numTakenSemesters
            : 1;
        };
        newObj.periodoMax = parsePeriodoMinMax(el.maxsemester, 99);
        newObj.periodoMin = parsePeriodoMinMax(el.minsemester, 1);

        // Parse cursada
        newObj.cursada = el.cursada.find(
          (x) => x.selected == true
        ).value;

        // Parse (pre)final
        const finalValue = el.final.find(
          (x) => x.selected == true
        ).value;
        newObj.final = finalValue == "final" ? true : false;
        newObj.prefinal = finalValue == "prefinal" ? true : false;

        // Parse prerequisites and corequisites
        el.prereq.forEach((req) => {
          if (req.selected) {
            newObj.prerequisitos.push(req.value);
          }
        });
        el.coreq.forEach((req) => {
          if (req.selected) {
            newObj.corequisitos.push(req.value);
          }
        });

        newStudentDataClasses.push(newObj);
      });
      this.studentData.classes = newStudentDataClasses;
      this.studentData.numClasses = newStudentDataClasses.length;
    },

    // Extract class data from studentData into classes array
    studentDataToClasses() {
      this.studentData.classes.forEach((el) => {
        // Create select-header-objects for
        // prerequisites and corequisites
        const reqs = [[], []];
        for (let i = 0; i <= 1; i++) {
          this.studentData.classes.forEach((reqCandidate) => {
            reqs[i].push({
              text: reqCandidate.nome,
              value: reqCandidate.id,
              selected:
                i == 0
                  ? el.prerequisitos.includes(reqCandidate.id)
                  : el.corequisitos.includes(reqCandidate.id),
            });
          });
        }

        // Create select-header-objects for "cursada" property
        const cursadas = [
          {
            text: `Não foi cursada.`,
            value: -1,
            selected: el.cursada == -1,
          },
        ];
        for (let i = 0; i < this.studentData.numTakenSemesters; i++) {
          cursadas.push({
            text: `No ${i + 1}° período.`,
            value: i,
            selected: el.cursada == i,
          });
        }

        // Set appropriate string for final
        const final = [
          {
            text: "No último período.",
            value: "final",
            selected: el.final,
          },
          {
            text: "No penúltimo período.",
            value: "prefinal",
            selected: el.prefinal,
          },
          {
            text: "Em qualquer período.",
            value: "false",
            selected: !el.final && !el.prefinal,
          },
        ];

        // Reset min/max semester to a more intuitive format
        const minsemester =
          el.periodoMin == 1
            ? 1
            : el.periodoMin + this.studentData.numTakenSemesters;
        const maxsemester =
          el.periodoMax ==
            this.studentData.maxSemesters - this.studentData.numTakenSemesters
            || el.periodoMax == this.studentData.maxSemesters
            ? this.studentData.maxSemesters
            : el.periodoMax + this.studentData.numTakenSemesters;

        this.classes.push({
          name: el.nome,
          id: el.id,
          code: el.codigo,
          ch: el.ch,
          timeslot: el.horario,
          obrigatoria: el.obrigatoria,
          prereq: reqs[0],
          coreq: reqs[1],
          cursada: cursadas,
          minsemester,
          maxsemester,
          final: final,
        });
      });
    },
  },

  mounted() {
    this.createDetail(); // Get detailed text for "disciplinas"
    this.studentDataToClasses(); // Convert studentData to more useful array
    this.saveEditedItems(); // Only to update numTaken
  },
};
</script>

<style scoped>
.mg-large-input {
  margin-left: 20px;
}

.mg-huge-input {
  margin-left: 10px;
}

.bg-color-base {
  background-color: var(--v-accent-base);
}

/* var(--v-primary-base) */
.save {
  margin: 10px 0 10px auto;
  clear: both;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 60px 80px 0 0;
  font-size: 2rem;
  color: var(--v-primary-base);
}

.statusDataCard {
  background-color: #f5f5f5;
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid grey;
  margin-left: 36px;
}

@media only screen and (max-width: 500px) {
  .mg-large-input-m {
    margin-left: -7px;
    padding: 20px;
  }

  .close {
    margin: 45px 30px 0 0;
  }

  .mg-large-input {
    margin-left: 0px;
  }

  .mg-mobile {
    margin-top: -10px;
    padding-top: 30px;
  }

  .mg-huge-input {
    margin-left: 0px;
    padding: 20px;
  }
}
</style>