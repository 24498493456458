<template>
  <div class="container">
    <div v-if="!openSearch">
      <!-- SHOW SET ROOMS TAB -->
      <div>
        <v-dialog v-model="openAdd" persistent max-width="600">
          <PaasForms
            option="rooms"
            :add="(e) => addRoom([e])"
            :roomTypes="input.roomTypes"
            :addRoomType="addRoomType"
            :departaments="input.departaments"
            :addDep="addDep"
            :trigger="openAdd"
            :close="closeOpenAdd"
          />
        </v-dialog>

        <!-- rooms data table -->
        <div :class="`${isMobile ? 'mt-n6' : 'mt-n3'} table`">
          <div class="search mt-5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <v-data-table
            :height="heightTableRooms"
            :items-per-page="-1"
            hide-default-footer
            :headers="headers"
            :items="exibRooms"
            :search="search"
          >
            <!-- Add help icons next to headers -->
            <template
              v-for="header in headers"
              v-slot:[`header.${header.value}`]
            >
              {{ header.text }}
              <HelpIcon
                :key="header.value"
                v-if="header.helpText != undefined"
                small
                :text="header.helpText"
              />
            </template>
            <!-- allow search without special characters -->
            <template v-slot:item.all="{ item }">
              <p class="mb-0">{{ item.bloco }}</p>
            </template>
            <template v-slot:item.acessivel="{ item }">
              <p class="mb-0">
                <v-icon v-if="item.acessivel" color="blue"
                  >mdi-wheelchair</v-icon
                >
                {{ item.acessivel ? "" : "Não" }} Acessível
              </p>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="handleEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="handleDelete(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <p>Nenhuma sala adicionada</p>
            </template>
          </v-data-table>

          <v-btn
            small
            v-if="exibRooms.length > 0"
            class="mt-2 blue"
            dark
            @click="handleOpenSaveRoomGroup"
          >
            Salvar grupo de Salas</v-btn
          >

          <!-- Dialog card to save rooms into the database -->
          <v-dialog
            v-model="saveRoomGroup.dialog"
            persistent
            max-width="600"
          >
            <v-card class="pb-5">
              <v-card-title>Salvar Grupo de salas</v-card-title>
              <v-card-subtitle
                >{{ input.rooms.length }} salas serão
                salvas.</v-card-subtitle
              >
              <v-card-subtitle v-if="saveRoomGroup.verify"
                ><span class="color-red"
                  >Digite um título para este grupo de salas.</span
                ></v-card-subtitle
              >
              <v-text-field
                class="mx-6 my-4"
                v-model="saveRoomGroup.name"
                append-icon="mdi-pen"
                label="Título"
                single-line
                hide-details
              ></v-text-field>
              <div>
                <v-checkbox
                  clas
                  v-model="saveRoomGroup.public"
                  class="ml-6"
                  ><template v-slot:label>
                    <p class="mt-4 mr-1">Tornar público</p>
                    <HelpIcon
                      class="ml-2"
                      small
                      :text="'As salas estarão disponíveis para outros usuários do seu centro.'"
                    /> </template
                ></v-checkbox>
              </div>

              <v-card-actions>
                <v-btn class="primary" @click="handleSaveRoomGroup"
                  >Salvar</v-btn
                >
                <v-btn
                  color="grey"
                  plain
                  @click="() => (saveRoomGroup.dialog = false)"
                  >Cancelar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-dialog v-model="edit.dialog" persistent max-width="600">
          <PaasForms
            option="rooms"
            edit
            :editObject="edit.placeholder"
            :add="editItem"
            :roomTypes="input.roomTypes"
            :close="() => (edit.dialog = false)"
          />
        </v-dialog>
      </div>

      <!-- DELETE CONFIRMATION DIALOG - it's outside of the tab because both set-rooms and set-classes triggers this dialog -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-subtitle"
            >Você tem certeza que deseja excluir este item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="() => (dialogDelete = false)"
            >
              Cancelar</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItem"
              >Excluir</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- SHOW SEARCH ROOM TAB -->
    <PaasSearchOption
      v-else
      :add="(e) => addRoom(e)"
      :option="'search-rooms'"
      :addDep="addDep"
      :handleAction="closeSearch"
      :addRoomTypes="addRoomType"
    />
  </div>
</template>

<script>
import PaasForms from "@/components/paas/PaasForms.vue";
import PaasSearchOption from "@/components/paas/PaasSearchOption.vue";
import HelpIcon from "@/components/HelpIcon.vue";
import latinize from "latinize";
import { postNewRoomGroup } from "@/api/db";
import { validateRooms } from "@/api/paas";

export default {
  name: "HandleRooms",
  components: {
    PaasForms,
    HelpIcon,
    PaasSearchOption,
  },
  props: {
    input: Object,
    openSearch: Boolean,
    openAdd: Boolean,
  },
  data() {
    return {
      search: "",
      saveRoomGroup: {
        dialog: false,
        copy: [],
        name: "",
        public: true,
        verify: false,
      },
      edit: {
        dialog: false,
        placeholder: {},
        idx: undefined,
      },
      dialogDelete: false,
      deletePlaceholder: {},
      headers: [
        {
          text: "Bloco",
          align: "start",
          value: "all",
        },
        { text: "Nome", value: "nome" },
        { text: "Capacidade", value: "capacidade" },
        { text: "Tipo de Sala", value: "tipo" },
        {
          text: "Preferências",
          value: "preferencias",
          sortable: false,
        },
        {
          text: "Exceção",
          value: "excecao",
          helpText:
            "Em alguns casos a sala está indisponível em alguns horários. Se necessário, especifique os horários de indisponibilidade no formato de horário da UFPB (e.g. 23M12) ",
          sortable: false,
        },
        { text: "Acessibilidade", value: "acessivel" },
        { text: "Editar", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
    heightTableRooms() {
      return this.height;
    },
    exibRooms() {
      return this.input.rooms.map((e, idx) => {
        //allow search with special characters
        let roomDataLatinaze = latinize(
          `${e.tipo} ${e.bloco} ${e.nome}`
        );
        let all = `${e.tipo} ${e.nome} ${e.bloco} ${e.nome} ${roomDataLatinaze}`;

        return {
          idx: idx,
          bloco: e.bloco,
          nome: e.nome,
          capacidade: e.capacidade,
          tipo: e.tipo,
          acessivel: e.acessivel,
          preferencias: e.preferencias
            .map((e) => e.value)
            .every((e) => e == 1)
            ? "s/ preferências"
            : e.preferencias
                .sort((a, b) => b.value - a.value)
                .map((el) => el.name)
                .toString(),
          excecao:
            e.excecao == "" ||
            e.excecao == undefined ||
            e.excecao == "undefined"
              ? "s/ exceção"
              : e.excecao,
          all: all,
        };
      });
    },
  },
  methods: {
    closeSearch() {
      this.$emit("update:openSearch", false);
    },
    closeOpenAdd() {
      this.$emit("update:openAdd", false);
    },
    addDep() {},
    handleEdit(selected) {
      this.edit.placeholder = JSON.parse(
        JSON.stringify(this.input.rooms[selected.idx])
      );
      this.edit.idx = selected.idx;
      this.edit.dialog = true;
    },
    editItem(item) {
      if ("bloco" in item) {
        this.input.rooms[this.edit.idx] = JSON.parse(
          JSON.stringify(item)
        );
        this.input.rooms.push({});
        this.input.rooms.pop(); //it updates the data table
      } else {
        this.input.classes[this.edit.idx] = JSON.parse(
          JSON.stringify(item)
        );
        this.input.classes.push({});
        this.input.classes.pop(); //it updates the data table
      }
    },
    addRoom(item) {
      console.log(item);
      this.input.rooms = validateRooms(item, this.input.rooms);
    },
    handleOpenSaveRoomGroup() {
      //init with default values
      this.saveRoomGroup = {
        dialog: true,
        name: "",
        public: false,
        copy: this.input.rooms,
        verify: false,
      };
    },
    addRoomType(roomTypes) {
      //verify if there is a new roomType
      roomTypes.forEach((e) => {
        let idx = this.input.roomTypes.indexOf(e);
        if (idx < 0) {
          //add if it is new
          this.input.roomTypes.push(e);

          //update capInfo
          if (
            isNaN(this.input.capInfo) &&
            this.input.capInfo != undefined
          ) {
            this.input.capInfo.push({ name: e, value: 10 });
          }
        }
      });

      //update all classes to include all roomTypes preferences
      this.input.classes.forEach((e) => {
        let prefNames = e.preferencias.map((el) => el.name);

        this.input.roomTypes.forEach((roomType) => {
          if (prefNames.indexOf(roomType) == -1) {
            e.preferencias.push({
              name: roomType,
              value: 1,
            });
          }
        });
      });
    },
    handleSaveRoomGroup() {
      //name can't be empyt
      if (this.saveRoomGroup.name.length == 0)
        //show warning
        this.saveRoomGroup.verify = true;
      else {
        //send post request
        postNewRoomGroup(
          this.saveRoomGroup.copy,
          this.saveRoomGroup.name,
          this.saveRoomGroup.public
        );
        //close dialog
        this.saveRoomGroup.dialog = false;
      }
    },
    handleDelete(selected) {
      this.deletePlaceholder = selected;
      this.dialogDelete = true;
    },
    //if user confirm delete
    deleteItem() {
      this.input.rooms.splice(this.deletePlaceholder.idx, 1);
      this.dialogDelete = false;
    },
  },
};
</script>

<style scoped>
.btn-search {
  position: absolute;
  top: 76px;
  right: 110px;
}

.btn-add {
  position: absolute;
  top: 76px;
  right: 272px;
}

.container {
  position: absolute;
  max-width: 1600px;
  left: 0;
}

.search {
  width: 30%;
  border: solid 1px grey;
  border-radius: 5px;
}

.color-red {
  color: red;
}

.v-text-field {
  padding-top: 0px;
  padding-left: 5px;
  margin-top: 0px;
}

.main {
  background-image: linear-gradient(90deg, #1491ff, #0346b6);
}

.table {
  scroll-behavior: auto;
}

@media only screen and (max-width: 570px) {
  .search {
    margin-top: 70px;
    width: 100%;
  }
}
</style>
