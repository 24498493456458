<template>
  <div>
    <Header title="Histórico" />

    <!-- Solution cards -->

    <div v-if="!detail && !unsolved" class="ml-5">
      <PlanCard v-if="numInQueue > 0" Loading class="float-left" :waiting="numInQueue" />

      <div v-if="test">
        <PlanCard v-for="(plan, index) in PlanList" :key="index" :title="plan.name" :date="plan.date"
          :description="`Plano gerado com ${plan.taken_classes} disciplinas cursadas.`"
          :clickMain="() => handleClickPlan(index)" :saveRename="(rename) => renamePlan(rename, index)"
          :delFunction="() => deletePlan(index)" @update:title1="PlanList[index].name = $event" class="float-left" />
      </div>

      <PlanCard AddNew title="Novo Plano" class="float-left pb-10" @click.native="openSimulador" />
    </div>

    <!-- Detailed solution -->
    <div v-if="detail">
      <v-icon class="back-icon ml-8" x-large @click="detail = !detail">
        mdi-arrow-left
      </v-icon>

      <Plan :plan="solution" :semestersTaken="numTaken" />
    </div>

    <!-- Unsolved plans explainer -->
    <div v-if="unsolved">
      <v-icon class="back-icon ml-8" x-large @click="unsolved = !unsolved">
        mdi-arrow-left
      </v-icon>

      <PohUnsolved />
    </div>
  </div>
</template>


<script>
import Header from "@/components/Header.vue";
import PlanCard from "@/components/PlanCard.vue";
import Plan from "@/components/Plan.vue";
import PohUnsolved from "@/components/Poh-Unsolved.vue";
import { formatSolutionToDisplay } from "@/api/poh"
import axios from "axios";
import {
  createNewPlanDB,
  deletePlanDB,
  renamePlanDB,
  getPlansDB,
  getSolutionPOH
} from "@/api/db";

export default {
  name: "PohHistorico",
  components: {
    Header,
    PlanCard,
    Plan,
    PohUnsolved,
  },
  data() {
    return {
      detail: false,
      unsolved: false,
      numInQueue: 0,
      /** @type { {semester: string, data: {name: string, id: string, time:string}[]}[] } */
      solution: [],
      numTaken: 0,
      test: true,
      PlanList: [],
    };
  },
  watch: {
    "$store.getters.getWaitingPOH": function (newNum) {
      // Update numInQueue
      this.numInQueue = newNum;
      this.checkQueue();
    },
    "PlanList": function () {
      this.test = false;
      this.test = true;
    }
  },
  methods: {
    async handleClickPlan(idx) {
      try {
        // Hash of clicked plan
        const hash = this.PlanList[idx].poh_solution_hash;

        // Update number of semesters taken
        this.numTaken = this.PlanList[idx].taken_semesters;

        // Get solution from database
        const res = await getSolutionPOH(hash);

        //If solution isn't in database, show error message
        if (res === "Solution not found") {
          alert(
            "Desculpe, mas algo deu errado e parece que esse plano ainda não esta pronto. Tente novamente mais tarde.\n"
          );
          return;
        }

        // Set this.solution to what we got
        this.solution = formatSolutionToDisplay(res);

        // Handle unsolved instance
        if (this.solution.length == this.numTaken) {
          // Open page explaining unfeasibility
          this.unsolved = true;
        } else if (!this.solution || this.solution.length == 0) {
          // Error getting solution, alert user and delete
          alert(
            "Desculpe, mas algo deu errado com a seu plano. Experimente reiniciar o navegador e tentar novamente mais tarde.\n"
          );
          this.deletePlan(idx);
        } else {
          // Show details page
          this.detail = !this.detail;
        }

      } catch (e) {
        console.log("error: ", e);
      }
    },
    openSimulador() {
      this.$router.push({ path: "/poh-simulador" });
    },

    // Check if instances in queue are ready
    checkQueue() {
      // If there is any plan in store
      if (this.$store.getters.getHistoricoPOH.length > 0) {
        // Find the ones in queue
        this.$store.getters.getHistoricoPOH.forEach((element) => {
          if (element.status === "queue") {
            // Check if solution is ready
            axios
              .get(
                `${process.env.VUE_APP_SERVER_URI}/db/solutions/poh?hash=${element.hash}`
              )
              .then((res) => {
                // If it isn't, do nothing
                if (res.data === "Solution not found") {
                  return;
                }
                // if is ready puts it in database

                this.postNewPlan(JSON.parse(JSON.stringify(element)));
              })
              .catch((error) => {
                throw error;
              });
          }
          // Find the ones in ready
          if (element.status === "ready") {
            // If finds, put it in database

            this.postNewPlan(JSON.parse(JSON.stringify(element)));

          }
        });
      }
    },

    verifyIfAlreadyIn(hash) {
      const found = this.PlanList.filter((e) => e.poh_solution_hash = hash);
      return found.length === 0;

    },

    /* CRUD OPERATIONS DB */

    // CREATE
    async postNewPlan(plan) {
      //Create and save new plan in database
      // Verify if plan already is in database
      const found = this.PlanList.filter((el) => el.poh_solution_hash == plan.hash);

      //If isn't in database
      if (found.length === 0) {
        // Creates plan object
        const body = {
          idVinculo: plan.user,
          hash: plan.hash,
          name: `Plano ${this.PlanList.length + 1}`,
          date: plan.date,
          taken_classes: plan.numClasses,
          taken_semesters: plan.numTakenSemesters,
        };

        //save object in database trough db api
        const updatedPlanList = await createNewPlanDB(body);
        //Update PlanList
        this.PlanList = Object.assign(updatedPlanList);

      }
      // Remove temporary plan from store
      this.$store.dispatch("removeFromHistoricoPOH", plan);
    },

    // READ
    async updatePlanList() {
      // get array of plans in database through db api
      const updatedPlanList = await getPlansDB();
      //Update PlanList variable
      this.PlanList = Object.assign(updatedPlanList);
    },

    // UPDATE
    async renamePlan(rename, idx) {


      //send rename request through db api
      const updatedPlanList = await renamePlanDB(
        this.PlanList[idx].poh_solution_hash,
        rename
      );
      // Update PlanList
      this.PlanList = Object.assign(updatedPlanList);

    },

    // DELETE
    async deletePlan(idx) {
      // send a delete request through db api
      const updatedPlanList = await deletePlanDB(
        this.PlanList[idx].poh_solution_hash
      );
      // Update PlanList
      this.PlanList = Object.assign(updatedPlanList);
    },


  },
  mounted() {
    // Init numInQueue value
    this.numInQueue = this.$store.getters.getWaitingPOH;

    //Update PlanList
    this.updatePlanList();

    // Seconds between every queue check
    const checkWaitForSec = 2;
    // Check queue in fixed time intervals
    window.setInterval(() => {
      this.checkQueue();
      if (this.PlanList.length == 0) this.updatePlanList();
    }, checkWaitForSec * 1000);
  },
};
</script>

<style scoped>
.back-icon {
  color: var(--v-primary-base);
}
</style>