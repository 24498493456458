<template>
  <div>
    <!-- Show add new card -->
    <v-card v-if="AddNew" dark class="addNew pa-4">
      <h3>{{ title }}</h3>
      <h1 class="plus">+</h1>
    </v-card>

    <!-- Show loading card -->
    <v-card v-else-if="Loading" class="loading pa-4">
      <h3 class="t-a">Aguarde</h3>
      <p class="text-caption t-a">
        {{ waiting }} novo{{ waiting > 1 ? "s" : "" }} plano{{
          waiting > 1 ? "s" : ""
        }}
        est{{ waiting > 1 ? "ão" : "á" }} sendo gerado{{
          waiting > 1 ? "s" : ""
        }}
      </p>
      <v-progress-circular indeterminate color="#ffffff"></v-progress-circular>
    </v-card>

    <div v-else-if="saved">
      <!-- main display -->
      <v-card dark class="card pa-4" v-if="!clickIcon">
        <v-icon @click="openEdit" class="edit-icon ma-2">
          mdi-square-edit-outline
        </v-icon>
        <div @click="clickMain" class="clickable-area pa-4">
          <h3 class="pt-3">{{ title }}</h3>
          <h4>{{ date }}</h4>
          <p>{{ description }}</p>
        </div>
      </v-card>
      <!-- Edit display -->
      <v-card v-if="clickIcon" dark class="card-renomear pa-4">
        <div class="mt-8">
          <v-icon  @click="clickIcon = false" class="ma-2">
            mdi-arrow-left
          </v-icon>
          <v-btn small outlined @click="duplicarPlan">
            duplicar
            <!-- <HelpIcon
              small
              text="Esta simulação salva será duplicada, para renomear, clique no card e conclua as alterações no simulador."
            /> -->
          </v-btn>
          <v-icon @click="deletePlan" class="ma-2">
            mdi-delete
          </v-icon>

        </div>
      </v-card>
    </div>

    <v-card v-else-if="noRename" dark class="card pa-4">
      <v-icon @click="deletePlan" class="edit-icon ma-2">
        mdi-delete
      </v-icon>
      <div @click="clickMain" class="clickable-area pa-4 ">
        <h3 class="pt-3">{{ title }}</h3>
        <h4>{{ date }}</h4>
        <p>{{ description }}</p>
      </div>
    </v-card>

    <!-- default card -->
    <div v-else>
      <!-- main display -->
      <v-card dark class="card pa-4" v-if="!clickIcon">
        <v-icon @click="openEdit" class="edit-icon ma-2">
          mdi-square-edit-outline
        </v-icon>
        <div @click="clickMain" class="clickable-area pa-4">
          <h3 class="pt-3">{{ title }}</h3>
          <h4>{{ date }}</h4>
          <p>{{ description }}</p>
        </div>
      </v-card>
      <!-- Edit display -->
      <v-card v-if="clickIcon" dark class="card-renomear pa-4">
        <v-text-field v-model="titleCopy" label="Renomear" maxlength="26" :rules="[rules.required, rules.counter]" counter
          outlined></v-text-field>
        <div>
          <v-icon v-if="clickIcon" @click="clickIcon = false" class="ma-2">
            mdi-arrow-left
          </v-icon>
          <v-icon v-if="clickIcon" @click="save" class="ma-2">
            mdi-checkbox-marked-circle
          </v-icon>
          <v-icon v-if="clickIcon" @click="deletePlan" class="ma-2">
            mdi-delete
          </v-icon>

        </div>
      </v-card>
    </div>

  </div>
</template>

<script>
// import HelpIcon from "./HelpIcon.vue";

export default {
  name: "PlanCard",
  // comments: {
  //   HelpIcon
  // },
  props: {
    id: String,
    title: String,
    date: String,
    description: String,
    AddNew: Boolean,
    Loading: Boolean,
    waiting: Number,
    delFunction: Function,
    clickMain: Function,
    saveRename: Function,
    noRename: Boolean,
    saved: Boolean
  },
  data() {
    return {
      clickIcon: false,
      titleCopy: '',
      rules: {
        required: value => !!value || 'Digite algo.',
        counter: value => value.length <= 26 || 'Max 26 caracteres',
      },
    };
  },
  methods: {
    openEdit() {
      this.clickIcon = true;
      this.titleCopy = this.$props.title;
    },
    save() {
      if (this.titleCopy.length > 0) {
        this.$emit("update:title1", this.titleCopy);
        this.clickIcon = false;
        this.$props.saveRename(this.titleCopy);
      }
    },
    deletePlan() {
      this.$props.delFunction();
      this.clickIcon = false;
    },
    duplicarPlan() {
      console.log("gg")
      this.$emit("duplicar")
    }
  },
};
</script>

<style scoped>
.clickable-area {
  margin: -8% 0 0 -8%;
  height: 126%;
  width: 116%;
  clip-path: polygon(0 0, 72% 0, 100% 33%, 100% 100%, 0 100%);
}

.card {
  width: 240px;
  height: 160px;
  margin: 10px;
  background-color: var(--v-accent-darken3);
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.card:hover {
  background-color: var(--v-primary-base);
}

.card-renomear {
  width: 240px;
  height: 160px;
  margin: 10px;
  background-color: var(--v-primary-base);
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.edit-icon:hover {
  color: black;
}

p {
  font-size: 1rem;
}

.loading {
  width: 240px;
  height: 160px;
  margin: 10px;
  border-radius: 10px;
  text-align: center;
  background: linear-gradient(45deg, #0088ff, #2f7dc2, #3b3dc9, #0088ff);
  background-size: 600% 100%;
  animation: gradient 4s linear infinite;
  animation-direction: alternate;
}

.wrapper {
  width: 100%;
  height: 100px;

}

@keyframes gradient {
  0% {
    background-position: 0%
  }

  100% {
    background-position: 100%
  }
}

.addNew {
  width: 240px;
  height: 160px;
  margin: 10px;
  background-color: var(--v-primary-base);
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.saci {
  width: 40px;
  margin-top: -10px;
}

.t-a {
  color: white;
}

.plus {
  font-size: 5rem;
  line-height: 1.2;
  margin: 0;
}


@media only screen and (min-width: 400px) and (max-width: 500px) {

  .card,
  .addNew,
  .card-renomear,
  .loading {
    width: 170px;
    height: 200px;
    margin: 5px;
  }
}

@media only screen and (max-width: 399px) {

  .card,
  .addNew,
  .card-renomear,
  .loading {
    width: 130px;
    height: 200px;
    margin: 5px;
  }
}
</style>