<template>
  <div>
    <Header title="Alocação de Aulas a Salas" />
    <v-container class="all">
      <div class="main">
        <v-row>
          <v-col>
            <div class="card-m">
              <v-container
                class="d-flex flex-no-wrap justify-space-between"
              >
                <v-row>
                  <v-col :class="isMobile ? 'pa-5' : 'pa-12'">
                    <v-card-title class="text-h4 wb"
                      >Olá, {{ userName }}.</v-card-title
                    >

                    <v-card-subtitle class="subtitle-1 mt-2 wb"
                      >Aqui você pode iniciar uma nova simulação{{
                        isPaasEditor ? "," : " e "
                      }}
                      visualizar o resultados das simulações{{
                        !isPaasEditor
                          ? "."
                          : " e configurar a alocação pública."
                      }}</v-card-subtitle
                    >
                  </v-col>
                  <v-col v-if="!isMobile" class="img">
                    <v-avatar class="ma-3" :size="250" tile>
                      <v-img
                        :src="require('../assets/paas-dash.svg')"
                      ></v-img>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="isPaasEditor">
          <v-col cols="12" sm="6" md="4">
            <LargeButton
              type="primary"
              title="Simulador"
              subtitle="Criar e editar nova entrada com informações fornecidas pelo SIGAA"
              @click.native="openSimulador"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <LargeButton
              type="secondary-small"
              title="Histórico"
              subtitle="Ver resultados das simulações passadas"
              @click.native="openHistorico"
            />
            <LargeButton
              class="mt-5"
              type="secondary-small"
              title="Infraestrutura"
              subtitle="Configurar salas que estarão disponíveis para a alocação"
              @click.native="openInfra"
            />
          </v-col>
          <v-col v-if="isPaasEditor" cols="12" sm="6" md="4">
            <LargeButton
              type="basic"
              title="Alocação Pública"
              subtitle="Aqui você pode editar a alocação de salas publicada para seu centro"
              @click.native="openPublicar"
            />
          </v-col>
        </v-row>

        <v-row v-if="!isPaasEditor">
          <v-col cols="12" sm="6">
            <LargeButton
              type="primary"
              title="Simulador"
              subtitle="Criar e editar nova entrada com informações fornecidas pelo SIGAA"
              @click.native="openSimulador"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <LargeButton
              type="secondary"
              title="Histórico"
              subtitle="Ver resultados das simulações passadas"
              @click.native="openHistorico"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import LargeButton from "@/components/LargeButton.vue";

export default {
  name: "PaasDashboard",
  components: {
    Header,
    LargeButton,
  },
  methods: {
    openSimulador() {
      this.$router.push({ path: "/paas-simulador" });
    },
    openHistorico() {
      this.$router.push({ path: "/paas-historico" });
    },
    openInfra() {
      this.$router.push({ path: "/paas-infra" });
    },
    openPublicar() {
      this.$router.push({ path: "/paas-publicar" });
    },
  },
  computed: {
    isPaasEditor() {
      return this.$store.getters.userIsPaasEditor;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    },
    height() {
      return this.$vuetify.breakpoint.width < 800 ? 260 : 200;
    },
    userName() {
      return this.$store.getters.getUserFirstName;
    },
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Paas",
    });
  },
};
</script>

<style scoped>
.all {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.img {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  max-width: 1000px;
  /* background-color: aqua; */
  padding: 0 10px;
}

.card-m {
  width: 100%;
  border-radius: 10px;
  height: 250px;
  background-color: var(--v-accent-base);
}

.wb {
  word-break: keep-all;
}

.ma-mo {
  margin: -60px 0px 0px 5px;
}

@media only screen and (min-width: 1600px) {
}
</style>
