<template>
    <section class="main-p" :style="height <= 720 ? 'height: 560px' : `height: ${height - 160}px;`">

        <v-btn v-if="save" small class="save-btn" color="blue" outlined id="save" @click="action">
            <v-icon small class="mr-2">
                mdi-content-save-outline
            </v-icon>
            salvar alterações
        </v-btn>


        <v-icon v-if="close" :class="`${isMobile ? 'close-icon-m' : 'close-icon'}`" large id="close" @click="action">
            mdi-close
        </v-icon>

        <h2 :class="`${isMobile ? 'pl-4 pt-4' : 'pl-12 pt-6'}`" v-if="title">{{ title }} <span v-if="subtitle && !isMobile"
                class="subtitle">{{ subtitle }}</span></h2>

        <div class="parent px-1 px-md-12">
            <AssistentCard v-if="assistent && assistent != ''" :text="assistent" />

            <div v-if="addSearch && !isMobile" class="btns">
                <v-btn small class="float-right mr-4" color="primary"
                    @click="() => handleAction(`${name == 'set-rooms' ? 'search-rooms' : 'search-classes'}`)">
                    {{ name == 'set-rooms' ? 'Buscar Salas' : 'Buscar Turmas' }}
                </v-btn>
                <v-btn small class="float-right mr-4" color="primary"
                    @click="() => handleAction(`${name == 'set-rooms' ? 'open-add-room' : 'open-add-class'}`)">
                    {{ name == 'set-rooms' ? 'Adicionar Sala' : 'Adicionar Turma' }}
                </v-btn>

            </div>
            <div v-if="addSearch && isMobile" class="btns">
                <v-btn small class="float-right mr-4" color="primary"
                    @click="() => handleAction(`${name == 'set-rooms' ? 'search-rooms' : 'search-classes'}`)">
                    <v-icon small> mdi-magnify</v-icon>
                </v-btn>
                <v-btn small class="float-right mr-2" color="primary"
                    @click="() => handleAction(`${name == 'set-rooms' ? 'open-add-room' : 'open-add-class'}`)">
                    <v-icon small> mdi-plus</v-icon>
                </v-btn>

            </div>

        </div>




        <div :class="isMobile ? 'px-5 test' : 'px-12 test'" :style="maxHeightSlot">
            <slot class="test"></slot>
        </div>

        <div class="nav-arrow noselect">
            <div v-if="back" class="float-left cursor-pointer" id="back" @click="action">
                <v-icon class="icon float-left pl-12">
                    mdi-arrow-left
                </v-icon>
                <h4 v-if="!isMobile" class="float-left pl-4">Anterior</h4>
            </div>
            <div v-if="next" class="next-arrow float-right cursor-pointer" id="next" @click="action">
                <v-icon class="icon float-right pr-12">
                    mdi-arrow-right
                </v-icon>
                <h4 v-if="!isMobile" class="float-right pr-4">Próximo</h4>
            </div>
        </div>

        <div v-if="dotted && !isMobile" class="dotted-menu">
            <div :class="`dot-t ${idx == 0 ? 'active' : ''}`" id="one-dot" @click="action" @mouseover="dot1 = true"
                @mouseleave="dot1 = false">
                <div v-show="dot1" class="dot-detail">Início</div>
            </div>
            <div :class="`dot-t ${idx == 1 ? 'active' : ''}`" id="two-dot" @click="action" @mouseover="dot2 = true"
                @mouseleave="dot2 = false">
                <div v-show="dot2" class="dot-detail">Infraestrutura</div>
            </div>
            <div :class="`dot-t ${idx == 2 ? 'active' : ''}`" id="three-dot" @click="action" @mouseover="dot3 = true"
                @mouseleave="dot3 = false">
                <div v-show="dot3" class="dot-detail">Turmas</div>
            </div>
            <div :class="`dot-t ${idx == 3 ? 'active' : ''}`" id="four-dot" @click="action" @mouseover="dot4 = true"
                @mouseleave="dot4 = false">
                <div v-show="dot4" class="dot-detail">Preferências salas</div>
            </div>
            <div :class="`dot-t ${idx == 4 ? 'active' : ''}`" id="five-dot" @click="action" @mouseover="dot5 = true"
                @mouseleave="dot5 = false">
                <div v-show="dot5" class="dot-detail">Preferências turmas</div>
            </div>
            <div :class="`dot-t ${idx == 5 ? 'active' : ''}`" id="six-dot" @click="action" @mouseover="dot6 = true"
                @mouseleave="dot6 = false">
                <div v-show="dot6" class="dot-detail">Opções avançadas</div>
            </div>
            <div :class="`dot-t ${idx == 6 ? 'active' : ''}`" id="seven-dot" @click="action" @mouseover="dot7 = true"
                @mouseleave="dot7 = false">
                <div v-show="dot7" class="dot-detail">Simular</div>
            </div>
        </div>


    </section>
</template>

<script>
import AssistentCard from './AssistentCard.vue';

export default {
    name: "PaasMainCard",
    components: {
        AssistentCard,
    },
    props: {
        title: String,
        name: String,
        subtitle: String,
        handleAction: Function,
        close: Boolean,
        dotted: Boolean,
        back: Boolean,
        next: Boolean,
        assistent: String,
        idx: Number,
        addSearch: Boolean,
        save: Boolean
    },
    data() {
        return {
            dot1: false,
            dot2: false,
            dot3: false,
            dot4: false,
            dot5: false,
            dot6: false,
            dot7: false,
        }
    },
    computed: {
        height() {
            return this.$vuetify.breakpoint.height;
        },
        isMobile() {
            return this.$vuetify.breakpoint.width < 570;
        },
        maxHeightSlot() {
            return `height: ${this.height - 350}px;`
        },
    },
    methods: {
        action(e) {
            this.handleAction(e.currentTarget.id);
        }
    }
}

</script>

<style scoped>
.test {
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    height: max-content;
    /* -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;  Firefox */
}

/* .test::-webkit-scrollbar {
  display: none;
} */
.parent {
    position: relative;
    display: flex;
    width: 100%;
}

.main-p {
    margin: -20px 35px 36px 35px;
    border-radius: 10px;
    background-color: white;
    z-index: -3;
}

.title {
    margin: 0;
}

.subtitle {
    color: var(--v-accent-darken2);
    font-size: 16px;
}

.nav-arrow {
    width: 95%;
    position: absolute;
    bottom: 80px;
}

.icon {
    color: var(--v-primary-base);
}

.cursor-pointer {
    cursor: pointer;
}

.close-icon {
    padding: 25px 50px 0 0;
    float: right;
    color: var(--v-primary-base)
}

.save-btn {
    margin: 15px 15px 0 0;
    float: right;
}

.close-icon-m {
    padding: 10px 10px 0 0;
    float: right;
    color: var(--v-primary-base)
}

.dotted-menu {
    left: 45%;
    position: absolute;
    bottom: 70px;
    margin: 0 auto;
}

.dot-t {
    margin: 0 5px;
    float: left;
    height: 16px;
    min-width: 16px;
    border-radius: 10px;
    background-color: var(--v-accent-darken2);
}

.active {
    background-color: var(--v-primary-base)
}

.dot-t:hover {
    cursor: pointer;
    background-color: var(--v-primary-base)
}

.dot-detail {
    font-size: 12px;
    padding: 0 7px 3px 7px;
    color: #e5e5e5;
}

.next-arrow {
    margin-right: 25px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.btns {
    flex-grow: 1;
}

@media only screen and (max-width: 570px) {
    .main-p {
        margin: 0;
        border-radius: 10px 10px 0 0;
        background-color: white;
        z-index: -3;
    }


    .nav-arrow {
        width: 110%;
        position: absolute;
        bottom: 30px;
    }
}

@media only screen and (max-width: 1200px) {

    .next-arrow {
        margin-right: 80px;
    }

    .btns {
        margin-top: -10px;
        margin-right: 14px;
        float: right;
    }


}
</style>

<!-- background-color: var(--v-primary-base) -->