<template>
  <div>
    <div v-if="variant == 'outlined'">
        <v-btn  color="primary" large outlined class="rounded-lg" @click="onClick">
        <slot />
        <p v-if="helpText" class="mt-5 ml-2"><HelpIcon :text="helpText"  small /></p>  
      </v-btn>
 
    </div>
    
    <v-btn v-else color="primary" large class="rounded-lg" @click="onClick">
      <slot />
    </v-btn>
  </div>
</template>

<script>
import HelpIcon from '@/components/HelpIcon.vue';

export default {
  name: "PrimaryButton",
  components: {
    HelpIcon,
  },
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    variant: String,
    helpText: String,
  },
};
</script>