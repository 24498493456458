<template>
  <div style="text-align: center">
    <h1>Opa! Esses parâmetros são inviáveis.</h1>
    <h3 class="my-7">
      Não existe nenhuma maneira de se formar com os parâmetros
      definidos. Tente mudar os parâmetros e tentar novamente.
    </h3>

    <h3>Como assim? Veja alguns exemplos:</h3>

    <v-container style="width: 80%">
      <v-row class="my-9">
        <v-col cols="5">
          <LargeInputBox
            title="Carga horária por período"
            value1="280"
            label1="min."
            value2="240"
            label2="max."
            type="Number"
            class="float-left"
            locked
          />
        </v-col>
        <v-col cols="7">
          <p class="example-text">
            Aqui, a carga horária mínima é maior do que a carga
            horária máxima, o que é impossível de acontecer. Além
            disso, algumas disciplinas (como Estágio Supervisionado)
            podem possuir CH maior que 240, o que impede o sistema de
            colocá-las em qualquer período. Isso também torna o plano
            inviável.
          </p>
        </v-col>
      </v-row>
      <v-row class="my-9">
        <v-col cols="5">
          <LargeInputBox
            title="Carga horária mínima de disciplinas optativas"
            value1="100000000"
            label1="Horas"
            type="Number"
            class="float-left"
            locked
          />
        </v-col>
        <v-col cols="7">
          <p class="example-text">
            Mesmo cursando todas as disciplinas optativas disponiveis,
            não é possível atingir essa carga horária. Isso torna o
            plano inviável.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <h3 class="mb-12">
      Há muitas outras maneiras de obter planos inviáveis, portanto
      tome cuidado com as opções que definir!
    </h3>
  </div>
</template>

<script>
import LargeInputBox from "./LargeInputBox.vue";

export default {
  name: "PohUnsolved",
  components: {
    LargeInputBox,
  },
};
</script>

<style scoped>
.example-text {
  text-align: left;
  margin-left: 10%;
}
</style>