<template>
  <div class="main" @click="btnMobile">
    <v-card :color="bgColor" dark>
      <v-container class="d-flex flex-no-wrap justify-space-between">
        <v-row>
          <v-col :class="isMobile ? 'pa-5' : 'pa-12'">
            <v-card-title
              class="text-h4 wb"
              v-text="title"
            ></v-card-title>

            <v-card-subtitle
              class="text-h6 mt-2 wb"
              v-text="subTitle"
            ></v-card-subtitle>

            <v-card-actions v-if="!isMobile">
              <v-btn
                class="ml-2 mt-8 px-14"
                large
                :style="`background-color: ${btnBgColor}`"
                @click="btn"
              >
                <span :style="`color: ${btnTxtColor}`">{{btnText ? btnText : 'Iniciar' }}</span>
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col>
            <v-avatar :class="isMobile ? 'ma-mo' : 'ma-3'" :size="height" tile>
              <v-img :src="img"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ModuleCard",
  props: {
    img: String,
    bgColor: String,
    txtColor: String,
    title: String,
    subTitle: String,
    btnBgColor: String,
    btnTxtColor: String,
    btn: Function,
    btnText: String,
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.width < 800 ? 260 : 350;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 800;
    }
  },
  methods: {
    btnMobile() {
      if(this.isMobile) 
        this.btn();
    }
  }
};
</script>

<style scoped>
.main {
  width: 100%;
}

.wb {
  word-break: keep-all;
}

.ma-mo {

  margin: -60px 0px 0px 5px;
}
</style>