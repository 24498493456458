<template>
    <div>
        <Header title="Solicitação de Turmas" />
        <section class="px-11">
            <div v-if="notFound">
                <NotFound :title="'Não encontramos esta solicitação!'" btn="Voltar para home" :btnFunction="handleGoHome"
                    :text="'A solicitação não existe. Verifique o link ou entre em contato com a pessoa responsável por ele.'" />
            </div>
            <div v-else-if="statusReady">
                <NotFound type="ok" :title="'Esta solicitação já foi preenchida!'" btn="Voltar para home"
                    :btnFunction="handleGoHome"
                    :text="'Tudo certo! Mas caso não esteja esperando por isso, entre em contado com a pessoa responsável por este link.'" />
            </div>
            <div v-else-if="notLogged">
                <NotFound type="login" :title="'Você não está logado!'" btn="Fazer Login" :btnFunction="handleLogin"
                    :text="'Você precisa estar logado para acessar a solicitação de turmas.'" />
            </div>
            <div v-else-if="notAllowed">
                <NotFound type="401" :title="'Você não tem permissão!'" btn="Voltar para home" :btnFunction="handleLogin"
                    :text="'Você não possui permissão para acessar esta página.'" />
            </div>
            <div v-else-if="loading">
                <p>Carregando...</p>
            </div>
            <div v-else>

                <div v-if="nav == 'main'">

                    <div v-if="classes.length > 0">

                        <v-btn class="blue btn" dark @click="openSearch">Buscar Turmas</v-btn>
                        <v-btn class="blue btn mr-4" dark @click="addDialog = true">Nova Turma</v-btn>

                        <div class="clear-all">
                            <AssistentCard class="ml-0" text="Adicione turmas para alocação!" />

                            <v-btn v-if="addedClasses.length > 0" dark class="blue my-10"
                                @click="() => sendDialog = true">Enviar</v-btn>

                            <v-dialog v-model="sendDialog" max-width="500">
                                <v-card class="pa-4">
                                    <v-card-title class="pl-0 pb-6 text-h6">
                                        Enviar solicitação?
                                    </v-card-title>
                                    <v-card-text class="ml-n4">
                                        As turmas adicionadas e alterações feitas serão enviadas. Você não terá mais acesso
                                        a esta solicitação.
                                    </v-card-text>
                                    <v-card-actions class="pa-0 pt-7">
                                        <v-btn small class="blue" dark @click="send">Enviar</v-btn>
                                        <v-btn small plain @click="() => sendDialog = false">Cancelar</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>


                            <!-- <v-btn v-if="addedClasses.length > 0 && rooms.length > 0" outlined color="blue"
                                class="my-10 float-right ml-3" @click="openSelectRooms">Escolher salas</v-btn>
                            <v-btn v-if="addedClasses.length > 0 && rooms.length > 0" outlined color="blue"
                                class="my-10 float-right" @click="openAdvancedOption">Opções avançadas de edição</v-btn> -->

                            <div v-if="selectRooms.open" class="clear-all">
                                <v-btn plain small class="mb-2" @click="cancelOptions">
                                    <v-icon small color="blue">
                                        mdi-arrow-left
                                    </v-icon>
                                    Voltar
                                </v-btn>
                                <p class="mt-5 text-subtitle">Escolher Salas</p>

                                <v-btn color="blue" outlined class="mb-4" @click="saveRoomsSelected">salvar salas
                                    selecionadas</v-btn>

                                <v-data-table show-select v-model="selectRooms.list" :headers="selectRooms.headers"
                                    :items-per-page="-1" :items="rooms" item-key="all" hide-default-footer
                                    class="elevation-1 clear-both">
                                    <template v-slot:item.all="{ item }">
                                        <p class="mb-0">{{ item.bloco }}
                                        </p>
                                    </template>
                                </v-data-table>
                            </div>

                            <div v-else-if="advancedOptions.open">
                                <v-btn plain small class="mb-2" @click="cancelOptions">
                                    Cancelar</v-btn>
                                <AdvancedOptions preferencesOptions :paasInput="advancedOptions.input" noRooms
                                    @updateClassesPreferences="updateClassesPreferences" />
                            </div>

                            <v-data-table v-else :items-per-page="-1" hide-default-footer :headers="headers.main"
                                :items="addedClasses">
                                <!-- Add help icons next to headers -->
                                <template v-for="header in headers.requests" v-slot:[`header.${header.value}`]>
                                    {{ header.text == 'delete' ? '' : header.text }}
                                </template>
                                <template v-slot:item.pcd="{ item }">
                                    <p class="mb-0">
                                        <v-icon v-if="item.pcd" color="blue">mdi-wheelchair</v-icon> {{
                                            item.pcd ?
                                            '' : '-'
                                        }}
                                    </p>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small class="mr-2" @click="() => handleEdit(item)">
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon small @click="handleDelete(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                            </v-data-table>


                        </div>
                    </div>

                    <NotFound v-if="classes.length == 0" type="team" :title="'Adicione turmas!'" btn="Adicionar Turmas"
                        :btnFunction="openSearch"
                        :text="'Aqui você pode buscar, adicionar e editar novas turmas. Comece clicando em adicionar turmas. Ao finalizar clique em enviar. '" />
                    <!-- Add new class dialog -->
                    <v-dialog v-model="addDialog" persistent max-width="700">
                        <PaasForms option="classes" :add="addNewClass" :noPreferences="!rooms.length > 0" noPreAloc
                            :addDep="() => { }" :roomTypes="roomTypes" :trigger="addDialog"
                            :close="() => addDialog = false" />
                    </v-dialog>

                    <v-dialog v-model="edit.dialog" persistent max-width="700">
                        <PaasForms option="classes" edit :noPreferences="!rooms.length > 0" noPreAloc
                            :editObject="edit.placeholder" :add="editItem" :roomTypes="roomTypes" :rooms="[]"
                            :close="() => edit.dialog = false" />
                    </v-dialog>

                </div>

                <div v-if="nav == 'search'">

                    <v-btn class="btn" plain @click="() => nav = 'main'">voltar</v-btn>

                    <!-- <div class="clear-all">
                        <v-row class="mt-1">
                            <v-col cols="12" md="2">
                                <v-select dense v-model="searchClasses.input.nivelDeEnsino"
                                    :items="searchClasses.select.nivelDeEnsino" label="Nível de Ensino" item-text="n"
                                    item-value="v" outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select dense v-model="searchClasses.input.unidade" :items="searchClasses.departaments"
                                    item-text="name" item-value="id" label="Unidade" outlined></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field dense outlined type="number" label="Ano" v-model="searchClasses.input.ano">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select dense v-model="searchClasses.input.periodo" :items="[1, 2, 3, 4]" label="Período"
                                    outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-btn @click="getClassList" color="blue" dark>buscar</v-btn>
                            </v-col>
                        </v-row>
                    </div> -->

                    <v-row class="mt-1">
                        <v-col cols="12" md="1">
                            <v-btn @click="() => change = !change" color="blue"
                                dark><v-icon>mdi-swap-horizontal</v-icon></v-btn>
                        </v-col>

                        <v-col v-if="!change" cols="12" md="2">
                            <v-select dense v-model="searchClasses.input.nivelDeEnsino"
                                :items="searchClasses.select.nivelDeEnsino" label="Nível de Ensino" item-text="n"
                                item-value="v" outlined></v-select>
                        </v-col>

                        <v-col v-if="change" cols="12" md="2">
                            <v-text-field dense outlined label="Código da Disciplina" v-model="searchClasses.input.codigo">
                            </v-text-field>
                        </v-col>

                        <v-col v-if="change" cols="12" md="2">
                            <v-text-field dense outlined label="Nome da Disciplina" v-model="searchClasses.input.nome">
                            </v-text-field>
                        </v-col>

                        <v-col v-if="!change" cols="12" :md="change ? '3' : '4'">
                            <v-select dense v-model="searchClasses.input.unidade" :items="searchClasses.departaments"
                                item-text="name" item-value="id" label="Unidade" outlined></v-select>
                        </v-col>

                        <v-col v-if="!change" cols="12" :md="change ? '1' : '2'">
                            <v-text-field dense outlined type="number" label="Ano" v-model="searchClasses.input.ano">
                            </v-text-field>
                        </v-col>
                        <v-col v-if="!change" cols="12" :md="change ? '2' : '1'">
                            <v-select dense v-model="searchClasses.input.periodo" :items="[1, 2, 3, 4]" label="Período"
                                outlined>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-btn @click="getClassList" color="blue" dark>buscar</v-btn>
                        </v-col>
                    </v-row>

                    <Loading v-if="searchClasses.loading" text="Buscando por classes..." />

                    <p v-if="searchClasses.notFound" class="color-red clear-all">NÃO ENCONTRADO, VERIFIQUE OS DADOS E TENTE
                        NOVAMENTE.</p>

                    <div v-if="searchClasses.classList.length > 0" class="clear-all">

                        <v-btn v-if="searchClasses.classList.length > 0" dark class="blue" @click="sendClasses">Adicionar
                            Selecionadas</v-btn>

                        <!-- Search box -->
                        <div class="search pt-1 mb-2">
                            <v-text-field v-model="search" dense append-icon="mdi-magnify" label="Pesquisar" single-line
                                hide-details></v-text-field>
                        </div>
                        <p v-if="searchClasses.validateSelect" class="color-red">Selecione ao menos uma turma!</p>
                        <!-- List of classes -->
                        <v-data-table v-model="searchClasses.selected" show-select :search="search"
                            :headers="headers.search" :items-per-page="-1" :items="exibClass" item-key="idx"
                            hide-default-footer class="elevation-1">

                            <!-- allow search without special characters -->
                            <template v-slot:item.all="{ item }">
                                <p class="mb-0">{{ item.codigo }}
                                </p>
                            </template>

                            <template v-for="header in searchClasses.headers" v-slot:[`header.${header.value}`]>
                                {{ header.text == 'PCD' ? '' : header.text }}
                                <div :key="header.value" v-if="header.text == 'PCD'" class="mb-n4">
                                    <v-icon>mdi-wheelchair</v-icon>
                                    <HelpIcon v-if="header.helpText != undefined" small :text="header.helpText" />
                                </div>
                            </template>

                            <template v-slot:item.pcd="{ item }">
                                <p class="mb-0">
                                    <v-icon v-if="item.pcd" color="blue">mdi-wheelchair</v-icon> {{
                                        item.pcd ? '' : '-'
                                    }}
                                </p>
                            </template>

                        </v-data-table>
                    </div>

                </div>

            </div>
        </section>

    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import NotFound from "@/components/NotFound.vue";
import Loading from '@/components/Loading.vue';
import PaasForms from "@/components/paas/PaasForms.vue";
import AdvancedOptions from '@/components/paas/AdvancedOptions.vue';
import AssistentCard from '@/components/AssistentCard.vue';
import latinize from 'latinize';

import { getRequestInfo, sendPaasClassesRequest } from "@/api/db";
import { loadLoginScreen } from "@/api/oidc.js";
import { departaments } from "@/api/util";
import { findClasses, validateClasses } from "@/api/paas";


export default {
    name: "PaasRequestHandle",
    components: {
        Header,
        NotFound,
        AssistentCard,
        Loading,
        PaasForms,
        AdvancedOptions
    },
    data() {
        return {
            change: false,
            default_preference_value: 0,
            notFound: false,
            notLogged: false,
            statusReady: false,
            notAllowed: false,
            loading: true,
            sendDialog: false,
            nav: 'main',
            info: {
                hash: '',
                status: '',
            },
            search: '',
            classes: [],
            addDialog: false,
            advancedOptions: {
                open: false,
                input: {}
            },
            selectRooms: {
                open: false,
                list: [],
                search: '',
                headers: [
                    { text: 'Bloco', value: 'all' },
                    { text: 'Sala', value: 'nome' },
                    { text: 'Tipo', value: 'tipo' },
                    { text: 'Capacidade', value: 'capacidade' },
                    { text: 'Acessível', value: 'pcd' },
                ]
            },
            searchClasses: {
                loading: false,
                selected: [],
                classList: [],
                notFound: false,
                validateSelect: false,
                departaments: [],
                input: {
                    nivelDeEnsino: 'G',
                    unidade: null,
                    ano: 2023,
                    periodo: 1,
                    codigo: '',
                    nome: '',
                },
                select: {
                    nivelDeEnsino: [
                        { n: 'Graduação', v: 'G' },
                        { n: 'Mestrado', v: 'M' },
                        { n: 'Doutorado', v: 'D' }
                    ],
                },
            },
            dialog: {
                open: false,
            },
            roomTypes: [],
            rooms: [],
            edit: {
                dialog: false,
                placeholder: {},
                idx: undefined
            },
            headers: {
                main: [
                    { text: 'Código', align: 'start', value: 'codigo' },
                    { text: 'Turma', value: 'turma' },
                    { text: 'Departamento', value: 'departamento' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Horário', value: 'horario' },
                    { text: 'Alunos', value: 'alunos' },
                    { text: 'Docente', value: 'docente' },
                    { text: 'PCD', value: 'pcd', helpText: 'Há discente com mobilidade redusida ou usuário de cadeira de rodas.' },
                    { text: '', value: 'actions', align: 'end', sortable: false, }
                ],
                search: [
                    { text: 'Código', align: 'start', value: 'all' },
                    { text: 'Turma', value: 'turma' },
                    { text: 'Nome', value: 'nome' },
                    { text: 'Horário', value: 'horario' },
                    { text: 'Alunos', value: 'alunos' },
                    { text: 'Docente', value: 'docente' },
                    { text: 'PCD', value: 'pcd', helpText: 'Há discente com mobilidade redusida ou usuário de cadeira de rodas.' },
                ]
            }
        }
    },
    computed: {
        exibClass() {
            return this.searchClasses.classList.map((e, idx) => {

                //allow search without special characters
                let dataLatinaze = latinize(`${e.nome} ${e.docente}`);
                let all = `${e.codigo} ${e.nome} ${e.docente} ${dataLatinaze}`

                return {
                    ...e,
                    id: e.codigo + '-' + e.turma,
                    idx: idx,
                    all: all,
                }
            })
        },
        addedClasses() {
            return this.classes.map((e, idx) => {
                return {
                    ...e,
                    id: e.codigo + '-' + e.turma,
                    idx: idx,
                    preferencias: this.rooms.length > 0 ? e.preferencias.map(e => e.value).every(e => e == this.default_preference_value) ? 's/ preferências' : (e.preferencias.sort((a, b) => b.value - a.value))[0].name : "",
                }
            })
        }
    },
    methods: {
        openSearch() {
            this.searchClasses.selected = [];
            this.searchClasses.classList = [];
            this.nav = 'search'
        },
        handleLogin() {
            this.$store.commit("setRedirect", `/paas/solicitacao/${this.info.hash}`);
            loadLoginScreen();
        },
        handleGoHome() {
            this.$router.replace({ name: "Home" })
        },
        handleEdit(selected) {
            this.edit.placeholder = JSON.parse(JSON.stringify(this.classes[selected.idx]));
            this.edit.idx = selected.idx
            this.edit.dialog = true;
        },
        editItem(item) {
            this.classes[this.edit.idx] = JSON.parse(JSON.stringify(item));
            this.classes.push({}); this.classes.pop(); //it updates the data table
            //save classes to store
            this.$store.dispatch("setRequestData", {
                hash: this.info.hash,
                classes: this.classes,
                rooms: this.selectRooms.list
            });

        },
        addNewClass(item) {
            this.classes = validateClasses([item], this.classes);
            //save classes to store
            this.$store.dispatch("setRequestData", {
                hash: this.info.hash,
                classes: this.classes,
                rooms: this.selectRooms.list
            });
        },
        handleDelete(item) {
            this.classes.splice(item.idx, 1)
            //save classes to store
            this.$store.dispatch("setRequestData", {
                hash: this.info.hash,
                classes: this.classes,
                rooms: this.selectRooms.list
            });
        },
        cancelOptions() {
            this.advancedOptions.open = false;
            this.selectRooms.open = false;
        },
        openSelectRooms() {
            this.advancedOptions.open = false;
            this.selectRooms.open = true;
        },
        openAdvancedOption() {
            this.advancedOptions.open = true;
            this.advancedOptions.input = {
                classes: this.classes,
                rooms: this.rooms,
                departaments: [],
                roomTypes: this.roomTypes,
            },

                this.selectRooms.open = false
        },
        saveRoomsSelected() {
            this.$store.dispatch("setRequestData", {
                hash: this.info.hash,
                classes: this.classes,
                rooms: this.selectRooms.list
            });
            this.cancelOptions();
        },
        updateClassesPreferences(option, roomType, value, exclusive) {

            switch (option) {
                case 'update':
                    this.classes.forEach(e => {

                        let change = true;

                        e.preferencias.every((el) => {
                            if (el.name != roomType && el.value > 1) {
                                change = false;
                                return false;
                            }
                        })

                        if (change) {
                            e.preferencias.forEach(el => {
                                if (el.name == roomType)
                                    el.value = value;
                                else
                                    el.value = exclusive ? 0 : 1;
                            })
                        }
                    })
                    break
                case 'updateSelected': {
                    roomType.forEach(e => {
                        let idx = this.classes.indexOf(e);
                        this.classes[idx].preferencias = JSON.parse(JSON.stringify(value))
                    });
                    break
                }
                case 'reset':
                    //update each class preferences, set default value = 1
                    this.classes.forEach(el => {
                        el.preferencias.forEach(e => {
                            e.value = 1;
                        });
                    });
                    break
            }
            this.classes = JSON.parse(JSON.stringify(this.classes));
        },
        // async getClassList() {
        //     this.searchClasses.loading = true;
        //     this.searchClasses.selected = [];
        //     this.searchClasses.classList = [];

        //     const res = await findClasses(this.searchClasses.input.nivelDeEnsino, this.searchClasses.input.unidade,
        //         this.searchClasses.input.ano, this.searchClasses.input.periodo, '', '');
        //     if (res == 'not found') {
        //         this.searchClasses.notFound = true;
        //         this.searchClasses.loading = false;
        //     } else {
        //         this.searchClasses.notFound = false;
        //         this.searchClasses.classList = res;
        //         this.searchClasses.loading = false;
        //     }
        // },
        async getClassList() {
            this.loading = true;
            this.searchClasses.selected = [];
            this.searchClasses.classList = [];

            if ((!this.change && this.searchClasses.input.nivelDeEnsino == undefined)
                ||
                (!this.change && (
                    this.searchClasses.input.periodo == undefined
                    || this.searchClasses.input.ano == undefined
                    || this.searchClasses.input.ano > (new Date().getFullYear()) + 1
                    || this.searchClasses.input.ano < 2010
                ))
                || (!this.change && this.searchClasses.input.unidade == undefined)
                || (this.change && !(this.searchClasses.input.codigo != '' || this.searchClasses.input.nome != ''))
            ) {
                this.searchClasses.validate = true;
                this.searchClasses.classList = [];
            } else {
                this.searchClasses.validate = false;

                if (!this.change) {
                    //deafut search
                    this.searchClasses.input.codigo = '';
                    this.searchClasses.input.nome = '';
                } else {
                    //search by name or code
                    this.searchClasses.input.unidade = '';
                    this.searchClasses.input.nivelDeEnsino = '';
                }

                const res = await findClasses(this.searchClasses.input.nivelDeEnsino, this.searchClasses.input.unidade,
                    this.change ? undefined : this.searchClasses.input.ano, this.change ? undefined : this.searchClasses.input.periodo, this.searchClasses.input.codigo, this.searchClasses.input.nome);
                if (res == 'not found') {
                    this.searchClasses.notFound = true;
                } else {
                    this.searchClasses.notFound = false;
                    this.searchClasses.classList = res;

                }
                this.loading = false;

            }
        },
        //push selected classes from search 
        sendClasses() {
            if (this.searchClasses.selected.length > 0) {

                // creates a array of preferences with value = 0
                let preferences = this.roomTypes.map(e => { return { name: e, value: this.default_preference_value } });

                //adds the new preference array to the select list
                let select = this.searchClasses.selected.map(e => { return { ...e, preferencias: preferences } });

                //adds it to the instance input
                let classes = validateClasses(select, this.classes);
                this.classes = classes;
                console.log(classes)
                //save classes to store
                this.$store.dispatch("setRequestData", {
                    hash: this.info.hash,
                    classes: classes,
                    rooms: this.selectRooms.list
                });
                //close the search classes tab
                this.nav = 'main'
                this.searchClasses.validateSelect = false;
            } else {
                //if none selected, display a warning
                this.searchClasses.validateSelect = true;
            }
        },
        send() {
            sendPaasClassesRequest(this.classes, this.info.hash, this.selectRooms.list);
            this.$store.dispatch("removeRequestData", {
                hash: this.info.hash,
            });
            this.info.status = "Pronto";
            this.statusReady = true;
        }
    },
    created() {
        this.$store.commit("setCurrentComponent", {
            component: "Paas",
        });
    },
    async mounted() {

        const hash = this.$route.params.hash;
        this.info.hash = hash;

        const data = await getRequestInfo(hash);

        if (data == 'Not Found')
            this.notFound = true;
        else if (data.request.status == 'Pronto')
            this.statusReady = true;
        else if (!this.$store.getters.isLoggedIn)
            this.notLogged = true;
        else if (!(this.$store.getters.userIsStaff || this.$store.getters.userIsAdmin)) {
            this.notAllowed = true;
        } else {

            let requests = JSON.parse(JSON.stringify(this.$store.getters.getRequestData));
            let found = requests.findIndex((e) => e.hash == hash);

            if (found != -1) {
                this.classes = JSON.parse(JSON.stringify(requests[found].classes));
                this.selectRooms.list = JSON.parse(JSON.stringify(requests[found].rooms));
            } else {
                this.classes = JSON.parse(JSON.stringify(data.classes));
            }

            this.rooms = data.rooms;

            this.searchClasses.departaments = departaments();

            this.info.status = data.request.status;

            this.loading = false;

            if (this.rooms.length > 0) {

                console.log(data);

                //verify if there is a new roomType
                this.rooms.forEach(e => {
                    let idx = this.roomTypes.indexOf(e.tipo);
                    if (idx < 0) {
                        //add if it is new
                        this.roomTypes.push(e.tipo);
                    }
                    e.all = `${e.bloco} ${e.nome} ${e.tipo}`
                    e.pcd = e.acessivel == 0 ? '-' : 'sim'
                });

                if (data.roomsList) {
                    data.roomsList.forEach(e => {
                        let idx = this.rooms.findIndex(el => `${el.bloco} ${el.nome}` == `${e.bloco} ${e.nome}`)
                        if (idx > -1)
                            this.selectRooms.list.push(this.rooms[idx])
                                ;
                    })
                }


                // creates a array of preferences with value = 0
                let preferences = this.roomTypes.map(e => { return { name: e, value: this.default_preference_value } });

                this.classes.forEach(e => {
                    e.preferencias = e.preferencias.length == 0 ? preferences : e.preferencias
                })

                this.headers.main.splice(7, 0, {
                    text: 'Preferências', value: 'preferencias', sortable: false,
                })
            }



        }

    }

}
</script>

<style scoped>
.btn {
    float: right;
}

.clear-all {
    width: 100%;
    float: left;
    clear: both;
}
</style>