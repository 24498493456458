<template>
    <div>
        <div>
            <div  v-for="idx in steps" :key="idx">
                <div :class="`step ${idx <= where ? 'blue' : 'grey lighten-2'}`">
                    <p v-if="discription[idx -1]" class="discription">{{ discription[idx -1] }}</p>
                </div>
                <div v-if="idx < steps" :class="`line ${idx < where ? 'blue' : 'grey lighten-2'} ${idx == where ? 'blue-half' : ''}`" :style="`width: ${80/steps}%;`"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProgressDisplay',
        props: {
            steps: Number,
            current: Number,
            where: Number,
            discription: Array,

        }
    }
</script>

<style scoped>
    .step {
        float: left;
        width: 30px;
        height: 30px;
        word-break: keep-all;
        border-radius: 15px;
        text-align: center;
    }

    .line {
        float: left;
        height: 6px;
        margin-top: 12px;
        background-color: grey;
    }

    .discription {
        float: left;
        margin-top: 30px;
        color: grey;
    }


    .blue-half {
        background: linear-gradient(
      90deg,
      #2196F3 50%,
      #E0E0E0 50%
    );
    }

</style>