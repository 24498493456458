<template>
  <div>
    <!-- Logged out -->
    <LoginScreen v-if="!loggedin" />

    <!-- Logged in -->
    <Header v-if="loggedin" title="Home" />
    <HomeScreen v-if="loggedin" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import LoginScreen from "@/components/LoginScreen";
import HomeScreen from "@/components/HomeScreen";

export default {
  name: "Home",
  metaInfo: {
    title: 'SACI',
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `Sistema de Apoio à Decisão do Centro de Informática. Planeje futuros períodos | Consulte a alocação de turmas a salas` },
    ]
  },
  components: {
    Header,
    LoginScreen,
    HomeScreen,
  },

  computed: {
    /** @returns {boolean} */
    loggedin() {
      return this.$store.getters.isLoggedIn;
    },
  },

  created() {
    this.$store.commit("setCurrentComponent", {
      component: "None",
    });
  },
};
</script>

<style scoped>

</style>