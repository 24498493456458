<template>
  <div class="container">
    <Header title="Infraestrutura" />

    <div v-if="openSelect" class="setRooms ml-10">
      <div class="pointer" @click="() => (openSelect = false)">
        <v-icon color="blue" class="pt-n2 float-left"
          >mdi-arrow-left</v-icon
        >
        <p class="float-left ml-2">voltar</p>
      </div>

      <div class="clear" />

      <p class="text-subtitle-2 font-weight-bold mt-5">
        Escolher grupo de salas
      </p>

      <v-btn
        v-if="roomGroup != null"
        class="btn2 mb-4 float-left mt-3"
        color="blue"
        outlined
        @click="() => handleEditRoomGroup(null)"
      >
        Remover Grupo de salas
        <v-icon rigth small> mdi-delete </v-icon>
      </v-btn>
      <PaasSearchOption
        :add="handleEditRoomGroup"
        option="search-rooms"
        :handleAction="(e) => (openSelect = false)"
        :setHeight="height"
        selectAll
      />
    </div>

    <div v-else>
      <div class="ml-10 room-group-tab pa-4 mb-10">
        <p v-if="roomGroup" class="font-weight-bold">
          Infraestrutura padrão: {{ roomGroup }}
        </p>
        <p v-else class="font-weight-bold">
          Nenhuma infraestrutura padrão definida.
        </p>

        <p class="text-caption mt-n2 mb-n2">
          Ao associar uma infraestrutura (grupo de salas), a pessoa
          responsável por preencher a solicitação de turmas pode
          especificar preferências por tipo de sala, além de
          configurar as preferências de salas por departamento.
        </p>

        <v-btn
          small
          class="mr-4 mt-6"
          outlined
          color="primary"
          @click="() => (openSelect = true)"
        >
          configurar
          <v-icon right> mdi-pencil </v-icon>
        </v-btn>
      </div>

      <p class="ml-10 font-weight-bold">
        Criar ou Editar Infraestrutura
      </p>

      <div class="mb-8" v-if="openSearch">
        <v-btn
          small
          class="float-right mr-4"
          outlined
          color="primary"
          @click="() => (openSearch = false)"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          voltar
        </v-btn>
      </div>

      <div class="mb-8" v-else>
        <v-btn
          small
          class="float-right mr-4"
          color="primary"
          @click="() => (openSearch = true)"
        >
          Buscar Salas
        </v-btn>
        <v-btn
          small
          class="float-right mr-4"
          color="primary"
          @click="() => (openAdd = true)"
        >
          Adicionar Sala
        </v-btn>
      </div>

      <div v-if="input.rooms.length > 0 || openAdd || openSearch">
        <AssistentCard
          v-if="!openSearch"
          class="ml-10 mb-5"
          text="Clique em salvar grupo de salas, no final da página, para finalizar"
        />
        <HandleRooms
          class="px-13"
          :input.sync="input"
          :openSearch.sync="openSearch"
          :openAdd.sync="openAdd"
        />
      </div>

      <div v-else>
        <NotFound
          type="infra"
          title="Adicione salas"
          text="A infraestrutura consiste em um conjunto de salas. Adicione salas uma a uma clicando em adicionar sala, ou busque por salas previamente cadastradas."
        />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import HandleRooms from "@/components/paas/handles/HandleRooms.vue";
import NotFound from "@/components/NotFound.vue";
import AssistentCard from "@/components/AssistentCard.vue";
import PaasSearchOption from "@/components/paas/PaasSearchOption.vue";

import { getRequestsRoomGroup, editRoomGroupRequest } from "@/api/db";

export default {
  name: "PaasInfra",
  components: {
    Header,
    HandleRooms,
    NotFound,
    AssistentCard,
    PaasSearchOption,
  },
  data() {
    return {
      input: {
        rooms: [],
        roomTypes: [],
        classes: [],
        departaments: [],
        addRoomDialog: false,
        capInfo: undefined,
      },
      openSearch: false,
      openAdd: false,
      openSelect: false,
      roomGroup: null,
      roomGroupId: null,
    };
  },
  computed: {
    heightTableRooms() {
      return this.isMobile ? this.height - 450 : this.height - 450;
    },
    height() {
      return this.$vuetify.breakpoint.height - 400;
    },
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Paas",
    });
  },
  methods: {
    async handleEditRoomGroup(id) {
      let res = await editRoomGroupRequest(id);
      if (id == null) {
        this.roomGroup = null;
        this.roomGroupId = null;
      } else {
        this.roomGroup = res;
        this.roomGroupId = id;
      }
      this.openSelect = false;
    },
  },
  async mounted() {
    let res = await getRequestsRoomGroup();

    this.roomGroup = res.roomGroup;
    this.roomGroupId = res.roomGroupId;
  },
};
</script>

<style scoped>
.btn-search {
  position: absolute;
  top: 76px;
  right: 110px;
}

.btn-add {
  position: absolute;
  top: 76px;
  right: 272px;
}

.container {
  position: absolute;
  max-width: 1600px;
  left: 0;
}

.search {
  width: 30%;
  border: solid 1px grey;
  border-radius: 5px;
}

.color-red {
  color: red;
}

.v-text-field {
  padding-top: 0px;
  padding-left: 5px;
  margin-top: 0px;
}

.main {
  background-image: linear-gradient(90deg, #1491ff, #0346b6);
}

.table {
  scroll-behavior: auto;
}

.room-group-tab {
  border: 2px solid #42424222;
  border-radius: 6px;
}

.pointer {
  cursor: pointer;
}

.setRooms {
  width: 100%;
  clear: both;
  height: 85vh;
}
.clear {
  clear: both;
}

@media only screen and (max-width: 570px) {
  .search {
    margin-top: 70px;
    width: 100%;
  }
}
</style>
