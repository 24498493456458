<template>
  <div>
    <table>
      <thead>
        <tr>
          <th></th>
          <th v-for="(header, i) in colHeader" :key="i">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in matrix" :key="i">
          <template v-if="i < 16">
            <td class="line">{{ rowHeader[i] }}</td>
            <td
              v-for="(_item, j) in row"
              @click.stop="clickItem(i, j)"
              :key="j"
              :class="`item ${
                item[0] == i && item[1] == j ? 'clicked' : ''
              }`"
              :style="{
                backgroundColor: calculateColor(_item),
              }"
            ></td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "HandleMatrix",
  props: {
    matrix: Array,
    rowHeader: Array,
    colHeader: Array,
    item: Array,
  },
  methods: {
    clickItem(i, j) {
      this.$emit("click-item", i, j);
    },
    calculateColor(index) {
      const startColor = [245, 245, 245]; // rgba(245,245,245,1)
      const endColor = [7, 87, 199]; // rgba(7,87,199,1)

      // Adjust index to create an exponential gradient
      const adjustedIndex = Math.pow(index, 2); // Quadratic adjustment for higher variability

      const r =
        startColor[0] + (endColor[0] - startColor[0]) * adjustedIndex;
      const g =
        startColor[1] + (endColor[1] - startColor[1]) * adjustedIndex;
      const b =
        startColor[2] + (endColor[2] - startColor[2]) * adjustedIndex;

      return `rgb(${Math.round(r)},${Math.round(g)},${Math.round(
        b
      )})`;
    },
  },
};
</script>

<style scoped>
.item,
.line {
  border: solid 4px white;
  margin: 0 2px 2px 0;
}

.item {
  cursor: pointer;
}

.clicked {
  -webkit-box-shadow: inset 0px 0px 0px 10px white;
  -moz-box-shadow: inset 0px 0px 0px 10px white;
  box-shadow: inset 0px 0px 0px 10px white;
}

.line {
  background-color: #f5f5f5;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 8px;
}
</style>
