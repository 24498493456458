import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { moduleOidc } from "./oidc_module";
import { moduleComponents } from "./components_module";
import { moduleUser } from "./user_module";
import { modulePoh } from "./poh_module";
import { modulePaas } from "./paas_module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    // Save vuex data to local/session storage
    createPersistedState({
      paths: ['oidc', 'user', 'components'],
      storage: window.sessionStorage // Session storage is reset when browser is closed
    }),
    createPersistedState({
      paths: ['poh', 'paas'],
      storage: window.localStorage // Local storage persists after browser is closed
    }),
  ],
  modules: {
    oidc: moduleOidc,
    components: moduleComponents,
    user: moduleUser,
    poh: modulePoh,
    paas: modulePaas
  }
});
