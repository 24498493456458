<template>
  <div>
    <div class="text-center mx-6 padding-res">
      <p class="main-text mb-12">
        Bem-vindo,
        <span class="font-weight-black"> {{ userName }}</span>!
      </p>
      <p class="main-text pa-4">
        Com o que podemos ajudar? Escolha um módulo:
      </p>
    </div>

    <!-- Module cards -->
    <div class="md-cards">
      <!-- Student-only modules -->
      <div v-if="userIsStudent">
        <!-- <p>Estamos em manutenção, voltamos em breve!</p> -->
        <ModuleCard
          bgColor="#1491FF"
          title="Otimização de Horário"
          subTitle="Planeje as disciplinas de seus próximos períodos."
          :btn="openPoh"
          :img="require('../assets/modulo-poh-ilu.png')"
          btnBgColor="white"
          btnTxtColor="black"
          :btnText="btnText"
        />
      </div>

      <!-- Staff-only modules -->
      <div v-if="userIsStaff">
        <ModuleCard
          class="mt-10"
          bgColor="#1491FF"
          title="Alocação de Aulas a Salas"
          subTitle="Decida que aulas ocorrerão em que salas."
          :btn="openPaas"
          :img="require('../assets/modulo-paas-ilu.png')"
          btnBgColor="white"
          btnTxtColor="black"
          :btnText="allowPaas ? 'iniciar': 'Sem acesso'"
          
        />
      </div>
    </div>

    <div class="text-center mx-6 padding-res">
      <p class="main-text ma-8">... mais módulos em breve!</p>
    </div>
  </div>
</template>

<script>
import ModuleCard from "./ModuleCard.vue";
//0088FF

export default {
  name: "HomeScreen",
  components: {
    ModuleCard,
  },
  computed: {
    userName() {
      return this.$store.getters.getUserFirstName;
    },
    userIsStudent() {
      return this.$store.getters.userIsStudent;
    },
    userIsStaff() {
      return this.$store.getters.userIsStaff;
    },
    allowPaas() {
      return this.$store.getters.getAllowPaas;
    },
    courseIsAllowed() {
      return this.$store.getters.courseIsAllowed;
    },
    userIsTester() {
      return this.$store.getters.userIsTester;
    },
    btnText() {
      if(this.userIsTester)
        return 'Testar';
      else if (this.courseIsAllowed)
        return 'Iniciar';
      else
        return 'Em breve';
    },
  },
  methods: {
    openPoh() {
      this.$router.push({ path: "/poh" });
    },
    openPaas() {
      if(this.userIsAdmin || this.allowPaas) 
        this.$router.push({ path: "/paas" });
    },
  },
};
</script>

<style scoped>
.main-text {
  font-size: 1.7vw;
}

.padding-res {
  padding: 30px;
}

.md-cards {
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .main-text {
    font-size: 5vw;
  }
  .padding-res {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .main-text {
    font-size: 1.6rem;
  }
}
</style>