<template>
  <div>

    <div v-if="displayMaxMsg"><p>Todas as salas estão disponíveis.</p></div>

    <div v-if="displayNoneMsg"><p>Nenhuma sala disponível.</p></div>

    <div>
      <div v-if="isMobile">
        <v-data-table
          :headers="headersMobile"
          :items="rooms"
          :items-per-page="-1"
          item-key="id"
          hide-default-footer
          hide-default-header
          class="elevation-1"
        >
          <template v-slot:item.all="{ item }">
            <div class="tt">
              <div class="div-icon pt-2 float-left"  @click.stop="handleClick(item.idx)">
                <v-icon class="m-div-icon">mdi-plus</v-icon>
              </div>
              <div class="float-left class-title">
                <p class="mt-2">
                  {{
                    `${item.bloco} ${item.sala} , ${item.tipo}. Capacidade de ${item.capacidade} pessoas.`
                  }}
                </p>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
      <v-data-table
        v-else
        :headers="headers"
        :items="rooms"
        :items-per-page="-1"
        item-key="id"
        hide-default-footer
        class="elevation-1"
        ><template v-slot:item.visualizar="{ item }">
          <v-btn
            elevation="2"
            color="blue"
            outlined
            rounded
            small
            depressed
            @click.stop="handleClick(item.idx)"
          >
            visualizar {{ item.a }}
            <v-icon small class="ml-2">mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "HandleRoomTable",
  props: {
    rooms: Array,
    displayMaxMsg: {
      type: Boolean,
      default: false
    },
    displayNoneMsg: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        { text: "Bloco", value: "bloco", align: "start" },
        { text: "Sala", value: "sala" },
        { text: "Tipo", value: "tipo" },
        { text: "Capacidade", value: "capacidade" },
        { text: "", value: "visualizar", align: "end" }
      ],
      headersMobile: [
        { text: "BlocoSala", value: "all", align: "start" }
      ]
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    }
  },
  methods: {
    handleClick(idx) {
      this.$emit('click-plus', idx)
    }
  }
};
</script>

<style scoped>
.tt {
  display: flex;
  width: 100%;
}

.div-icon {
  width: 40px;
}

.m-div-icon {
  margin-right: 100px;
  float: left;
  color: #eaeaea;
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 15px;
  background: rgb(71, 118, 230);
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  /* background: linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%); */
}

.class-title {
  width: 80%;
  text-align: left;
  font-size: 100%;
}
</style>
