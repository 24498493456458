const capitalize = str => {
  const strlist = str.toLowerCase().split(" ");
  const capitalized = strlist
    .map(val => {
      return val[0].toUpperCase() + val.substring(1);
    })
    .join(" ");
  return capitalized;
};

export const moduleUser = {
  state: {
    name: "Anônimo",
    type: [],
    staff_data: {},
    student_data: {},
    idVinculo: undefined,
    isPaasEditor: false,
    isAdmin: false,
    allowPaas: false,
    centro: "",
    redirect: ""
  },

  mutations: {
    setUserData(state, payload) {
      state.type = payload.type;
      state.student_data = payload.student_data;
      state.staff_data = payload.staff_data;
      state.name = capitalize(payload.user_fullname);
      state.idVinculo = payload.idVinculo;
      state.isPaasEditor = payload.isPaasEditor;
      state.isAdmin = payload.isAdmin;
      state.allowPaas = payload.allowPaas;
      state.centro = payload.centro;
    },

    clearUserData(state) {
      state.name = "Anônimo";
      state.type = [];
      state.staff_data = {};
      state.student_data = {};
      state.idVinculo = undefined;
      state.isPaasEditor = false;
      state.isAdmin = false;
    },

    setRedirect(state, payload) {
      state.redirect = payload;
    },

    clearRedirect(state) {
      state.redirect = "";
    }
  },

  getters: {
    userIsStudent: state => {
      return state.type.includes("Student");
    },

    userIsStaff: state => {
      return state.type.includes("Staff");
    },

    userIsPaasEditor: state => {
      return state.isPaasEditor;
    },

    userIsAdmin: state => {
      return state.isAdmin;
    },

    getMatricula: state => {
      return state.student_data.matricula || undefined;
    },

    getIdVinculo: state => {
      return state.idVinculo || undefined;
    },

    getUserName: state => {
      return state.name || "Anônimo";
    },

    getUserFirstName: state => {
      if (state.name === "Anônimo" || state.name === undefined)
        return "Anônimo";

      return state.name.split(" ")[0];
    },

    getCourseName: state => {
      return capitalize(state.student_data.course_name) || undefined;
    },

    getAllowPaas: state => {
      return state.allowPaas;
    },

    getCentro: state => {
      let centro = "";
      if (state.centro != "") centro = state.centro;
      else if (state.staff_data.centro != undefined)
        centro = state.staff_data.centro;

      return centro;
    },

    getRedirect: state => {
      return state.redirect;
    },

    courseIsAllowed: state => {
      const allowedCourses = [
        //CI
        'ENGENHARIA DA COMPUTAÇÃO',
        'CIÊNCIA DA COMPUTAÇÃO',
        'CIÊNCIA DE DADOS E INTELIGÊNCIA ARTIFICIAL',
        'COMPUTAÇÃO',
        'MATEMÁTICA COMPUTACIONAL',
        // //CT
        'ARQUITETURA E URBANISMO',
        'ENGENHARIA AMBIENTAL',
        'ENGENHARIA CIVIL',
        'ENGENHARIA DE ALIMENTOS',
        'ENGENHARIA DE MATERIAIS',
        'ENGENHARIA DE PRODUÇÃO',
        'ENGENHARIA DE PRODUÇÃO MECÂNICA',
        'ENGENHARIA MECÂNICA',
        'ENGENHARIA QUÍMICA',
        'QUÍMICA INDUSTRIAL',
        // //CEAR
        'ENGENHARIA DE ENERGIAS RENOVÁVEIS',
        'ENGENHARIA ELÉTRICA',
      ];

      let allow = false;

      if (
        state.isAdmin ||
        allowedCourses.indexOf(state.student_data.course_name) >= 0
      )
        allow = true;

      return allow;
    },

    userIsTester: state => {
      let allow = false;

      switch (state.student_data.matricula) {
        //Farmácia
        // case  2018017523:
        //    allow = true;
        //    break
        // case  20180175234:
        //    allow = true;
        //    break
        // case 20200019304:
        //   allow = true;
        //   break;
        // case 20230145258:
        //   allow = true;
        //   break;
        // case 20190025590:
        //   allow = true;
        //   break;
        // case 20180081254: // catarina
        //   allow = true;
        //   break;
        // case 20200001121: // elielson
        //   allow = true;
        //   break;
        // case 20190112819: // gil
        //   allow = true;
        //   break;
        // case 20180020858: // matheus
        //   allow = true;
        //   break;
        default:
          allow = false;
          break;
      }

      return allow;
    }
  }
};
