<template>
  <v-list-item router :to="route" :class="spacing">
    <v-list-item-icon>
      <v-icon>{{icon}}</v-icon>
    </v-list-item-icon>

    <v-list-item-title><slot/></v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    route: String,
    icon: String,
    isGrouped: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /** @returns {string} */
    spacing(){
      return this.isGrouped ? "pl-8" : "mx-2";
    }
  }
};
</script>