<template>
  <div>
    <Header title="Histórico" />
    <section v-if="!detail">
      <!-- Solution cards -->

      <div v-if="!detail && !unsolved" class="ml-5">
        <p class="ml-3 text-h6">Soluções</p>
        <PlanCard
          v-if="numInQueue > 0"
          Loading
          class="float-left"
          :waiting="numInQueue"
        />

        <div v-for="(plan, index) in PlanList" :key="index">
          <PlanCard
            :title="plan.name"
            :date="plan.date"
            :description="`Plano gerado com ${plan.number_rooms} salas e ${plan.number_classes} disciplinas.`"
            :clickMain="() => handleClickPlan(index)"
            :saveRename="(rename) => renamePlan(rename, index)"
            :delFunction="() => deletePlan(index)"
            @update:title1="PlanList[index].name = $event"
            class="float-left"
          />
        </div>

        <PlanCard
          AddNew
          title="Novo Plano"
          class="float-left pb-10"
          @click.native="openSimulador"
        />
      </div>
      <div class="saved ml-5" v-if="this.EditList.length > 0">
        <p class="ml-3 text-h6">Configurações Salvas</p>
        <div v-for="(plan, index) in EditList" :key="index">
          <PlanCard
            :title="plan.input.name"
            :date="plan.date"
            saved
            @duplicar="() => handleDuplicar(index)"
            :description="`Continue de onde parou. ${plan.input.rooms.length} salas e ${plan.input.classes.length} turmas.`"
            :clickMain="() => handleClickSaved(index)"
            :delFunction="() => deleteSaved(index)"
            class="float-left"
          />
        </div>
      </div>
    </section>
    <section v-if="detail">
      <div class="pointer" @click="close" v-if="editar || publicar">
        <v-icon color="blue" class="pt-n2 ml-8 float-left">
          mdi-arrow-left
        </v-icon>
        <p class="float-left ml-2">Voltar</p>
      </div>

      <div v-if="publicar">
        <NotFound
          type="ok"
          btn="publicar"
          :btnFunction="handlePublicar"
          title="Publicar"
          text="Esta alocação estará pública. Atenção: Caso já exista uma alocação pública, será removida e substituída por esta."
        />
      </div>

      <div v-else-if="editar">
        <NotFound
          type="setup"
          btn="Ir para simulador"
          :btnFunction="handleEditar"
          title="Editar"
          text="Você será redirecionado para a página de configuração, lá você poderá editar todas as informações e realizar uma nova simulação. Selecione as tumas abaixo para fixar a alocação encontrada."
        />

        <div class="pa-12">
          <!-- Search box -->
          <div class="search pt-1 mb-2">
            <v-text-field
              v-model="editInfo.search"
              dense
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            ></v-text-field>
          </div>
          <!-- List of classes -->
          <v-data-table
            :single-select="false"
            v-model="editInfo.selected"
            show-select
            :search="editInfo.search"
            :headers="editInfo.headers"
            :items-per-page="-1"
            :items="editInfo.data"
            item-key="idx"
            hide-default-footer
            class="elevation-1"
          >
            <!-- allow search without special characters -->
            <template v-slot:item.all="{ item }">
              <p class="mb-0">{{ item.codigo }}</p>
            </template>
          </v-data-table>
        </div>
      </div>

      <div v-else>
        <!-- DISPLAY SOLUTION -->

        <div class="btns">
          <v-btn
            dark
            class="blue mr-4"
            v-if="isPaasEditor && status != 'Unfeasible'"
            @click="openPublicar"
            >Publicar</v-btn
          >
          <v-btn
            dark
            class="blue mr-8"
            v-if="status != 'Unfeasible'"
            @click="openEditar"
            >Editar</v-btn
          >
        </div>

        <div class="pa-10"></div>

        <PaasSolution
          :status="status"
          :plan="plan"
          :back="() => (detail = !detail)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import PlanCard from "@/components/PlanCard.vue";
import PaasSolution from "@/components/paas/PaasSolution.vue";
import NotFound from "@/components/NotFound.vue";

import { handlePublishPaasSolution } from "@/api/db";
import { handleEditSolution } from "@/api/paas";
import axios from "axios";
import latinize from "latinize";

import {
  getPaasPlansDB,
  getSolutionPaas,
  createNewPaasPlanDB,
  deletePaasPlanDB,
  renamePaasPlanDB,
} from "@/api/db";

export default {
  name: "PaasHistorico",
  components: {
    Header,
    PlanCard,
    PaasSolution,
    NotFound,
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Paas",
    });
  },
  data: () => ({
    plan: [],
    status: "",
    planName: "",
    detail: false,
    unsolved: false,
    isPaasEditor: false,
    publicar: false,
    editar: false,
    editInfo: {
      search: "",
      selected: [],
      data: [],
      headers: [
        { align: "start", text: "Código", value: "all" },
        { text: "Turma", value: "turma" },
        { text: "Nome", value: "nome" },
        { text: "Sala alocada", value: "sala_alocada" },
      ],
    },
    numInQueue: 0,
    /** @type { {semester: string, data: {name: string, id: string, time:string}[]}[] } */
    solution: [],
    numTaken: 0,
    PlanList: [],
    EditList: [],
  }),
  watch: {
    "$store.getters.getWaitingPAAS": function (newNum) {
      // Update numInQueue
      this.numInQueue = newNum;
      this.checkQueue();
    },
  },
  methods: {
    close() {
      this.editar = false;
      this.publicar = false;
    },
    openEditar() {
      let classes = [];

      this.plan.solution.forEach((room) => {
        room.classes.forEach((e) => {
          let sala = `${room.bloco} ${room.nome}`;
          //allow search without special characters
          let dataLatinaze = latinize(
            `${sala} ${e.nome} ${e.docente}`
          );
          let all = `${sala} ${e.nome} ${e.docente} ${dataLatinaze}`;

          classes.push({
            ...e,
            sala_alocada: sala,
            idx: `${e.codigo}-${e.turma}`,
            all: all,
          });
        });
      });

      this.editInfo.data = JSON.parse(JSON.stringify(classes));

      this.editar = true;
      this.publicar = false;
    },
    openPublicar() {
      this.editar = false;
      this.publicar = true;
    },
    handlePublicar() {
      handlePublishPaasSolution(this.plan);
      setTimeout(() => {
        this.$router.push({ path: "/paas-publicar" });
      }, 1000);
    },
    handleEditar() {
      handleEditSolution(
        this.plan,
        this.planName,
        this.editInfo.selected
      );
      this.$router.push({ path: "/paas-simulador" });
    },
    openSimulador() {
      this.$router.push({ path: "/paas-simulador" });
    },
    async handleClickPlan(idx) {
      try {
        let hash = this.PlanList[idx].paas_solution_hash;
        this.planName = this.PlanList[idx].name;

        // Get solution from database
        const res = await getSolutionPaas(hash);

        //If solution isn't in database, show error message
        if (res === "Solution not found") {
          alert(
            "Desculpe, mas algo deu errado e parece que esse plano ainda não esta pronto. Tente novamente mais tarde."
          );
          return;
        }

        this.plan = res;
        this.status = this.plan.status;
        console.log("plan", this.status);

        this.detail = !this.detail;
      } catch (e) {
        console.log("error: ", e);
      }
    },

    // Check if instances in queue are ready
    checkQueue() {
      // If there is any plan in store
      if (this.$store.getters.getHistoricoPAAS.length > 0) {
        // Find the ones in queue
        this.$store.getters.getHistoricoPAAS.forEach((element) => {
          if (element.status == "queue") {
            // Check if solution is ready
            axios
              .get(
                `${process.env.VUE_APP_SERVER_URI}/db/solutions/paasReady?hash=${element.hash}`
              )
              .then((res) => {
                // If it isn't, do nothing
                if (res.data == "Solution not Ready") {
                  return;
                }

                // if is ready puts it in database
                this.postNewPlan(JSON.parse(JSON.stringify(element)));
              })
              .catch((error) => {
                throw error;
              });
          } else {
            this.postNewPlan(JSON.parse(JSON.stringify(element)));
          }
          // Find the ones in ready
          //if (element.status == "Ready") {
          // If finds, put it in database

          //}
        });
      }
    },

    /* CRUD OPERATIONS DB */

    // CREATE
    async postNewPlan(plan) {
      //Create and save new plan in database
      // Verify if plan already is in database
      const found = this.PlanList.findIndex((el) => {
        return el.paas_solution_hash == plan.hash;
      });

      //If isn't in database
      if (this.PlanList.length == 0 || found < 0) {
        // Creates plan object
        const body = {
          idVinculo: plan.user,
          hash: plan.hash,
          name: plan.nome,
          date: plan.date,
          numClasses: plan.numClasses,
          numRooms: plan.numRooms,
        };

        //save object in database trough db api
        const updatedPlanList = await createNewPaasPlanDB(body);
        //Update PlanList
        this.PlanList = updatedPlanList;
      }
      // Remove temporary plan from store
      this.$store.dispatch("removeFromHistoricoPAAS", plan);
    },

    // READ
    async updatePlanList() {
      // get array of plans in database through db api
      const updatedPlanList = await getPaasPlansDB();
      //Update PlanList variable
      this.PlanList = updatedPlanList;
    },

    // UPDATE
    async renamePlan(rename, idx) {
      //send rename request through db api
      const updatedPlanList = await renamePaasPlanDB(
        this.PlanList[idx].paas_solution_hash,
        rename
      );
      // Update PlanList
      this.PlanList = updatedPlanList;
    },

    // DELETE
    async deletePlan(idx) {
      // send a delete request through db api
      const updatedPlanList = await deletePaasPlanDB(
        this.PlanList[idx].paas_solution_hash
      );
      // Update PlanList
      this.PlanList = updatedPlanList;
    },

    openDetail() {
      this.detail = !this.detail;
    },

    //saved list
    handleClickSaved(item) {
      let id = this.EditList[item].id;
      let copyInput = JSON.parse(
        JSON.stringify(this.EditList[item].input)
      );
      console.log({ id, ...copyInput });
      this.$store.dispatch("setPaasInputPlaceholder", {
        id,
        ...copyInput,
      });
      this.$router.push({ path: "/paas-simulador" });
    },
    handleDuplicar(item) {
      console.log(this.EditList[item]);
      let copyInput = JSON.parse(
        JSON.stringify(this.EditList[item].input)
      );
      this.$store.dispatch("updateEditSave", copyInput);
      alert(
        "Duplicado!\nPara renomear, basta clicar no novo card e fazer as alterações na página da simulação que irá abrir. Não esqueça de salvar depois!"
      );
    },
    deleteSaved(item) {
      this.$store.dispatch("removeEditSaveByIdx", { idx: item });
      this.EditList = this.$store.getters.getEditSaveList;
    },
  },
  mounted() {
    // Init numInQueue value
    this.numInQueue = this.$store.getters.getWaitingPAAS;
    this.isPaasEditor = this.$store.getters.userIsPaasEditor;

    this.EditList = this.$store.getters.getEditSaveList;

    //Update PlanList
    this.updatePlanList();

    // Seconds between every queue check
    const checkWaitForSec = 2;
    // Check queue in fixed time intervals
    window.setInterval(() => {
      this.checkQueue();
      if (this.PlanList.length == 0) this.updatePlanList();
    }, checkWaitForSec * 1000);
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.btns {
  float: right;
}

.saved {
  clear: both;
}
</style>
