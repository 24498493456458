<template>
    <div class="main-ao">

        <UpdatePaas v-if="edit.show && edit.name == 'update-paas'" @update="handleUpdateClasses"
            @close="handleCloseUpdatePaas" :deptos="paasInput.departaments" :classes="paasInput.classes" :rooms="paasInput.rooms" :updatePreAloc="updatePreAloc" />

        <div v-else>
            <div v-if="preferences.setPreference.exib">
                <v-row>
                    <v-col cols="4" class="px-4">

                        <v-btn plain small class="mb-2" @click="() => preferences.setPreference.exib = false">
                            <v-icon small color="blue">
                                mdi-arrow-left
                            </v-icon>
                            Voltar</v-btn>

                        <div class="option-card px-4 text-caption">
                            <p v-if="preferences.setPreference.option == 'classes'">Defina a preferência por tipo de sala.
                            </p>
                            <p v-else> Defina a preferência por departamentos.</p>
                        </div>

                        <div v-for="tipo in preferences.setPreference.preference" :key="tipo.name">
                            <v-slider v-model="tipo.value" max="10" min="0" :label="tipo.name">
                                <template v-slot:append>
                                    <p>{{ tipo.value }}</p>
                                </template>
                            </v-slider>
                        </div>

                        <v-btn color="blue" dark class="mb-2" @click="handleSetPreference">Confirmar</v-btn>
                        <v-btn plain class="mb-2" @click="() => preferences.setPreference.exib = false">Cancelar</v-btn>


                    </v-col>
                    <v-col v-if="preferences.setPreference.option == 'classes'" cols="7">
                        <p v-if="preferences.setPreference.warning">Selecione ao menos uma turma!</p>
                        <p>Selecione as turmas que serão modificadas</p>
                        <v-data-table :height="400" show-select v-model="preferences.setPreference.selected"
                            :search="preferences.setPreference.search" :headers="preferences.setPreference.headers"
                            :items-per-page="-1" :items="paasInput.classes" item-key="idx" hide-default-footer
                            class="elevation-1 clear-both">
                            <template v-slot:item.all="{ item }">
                                <p class="mb-0">[{{ item.codigo }}-{{ item.turma }}] - {{ item.nome }}, <span
                                        class="text-caption"> {{ item.docente }}</span>.
                                </p>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col v-else cols="7">
                        <p v-if="preferences.setPreference.warning">Selecione ao menos uma sala!</p>
                        <p>Selecione as salas que serão modificadas</p>
                        <v-data-table :height="400" show-select v-model="preferences.setPreference.selected"
                            :search="preferences.setPreference.search" :headers="preferences.setPreference.headers"
                            :items-per-page="-1" :items="paasInput.rooms" item-key="id" hide-default-footer
                            class="elevation-1 clear-both">
                            <template v-slot:item.all="{ item }">
                                <p class="mb-0">{{ item.bloco }} {{ item.nome }} - {{ item.tipo }}, <span
                                        class="text-caption">
                                        {{ item.capacidade }} pessoas</span>.
                                </p>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

            </div>

            <v-row v-else>
                <!-- DISPLAY OPTIONS CARDS -->
                <v-col cols="12" md="4" v-if="!(isMobile && edit.show)">

                    <!-- taxa de ocupacao -->
                    <div v-if="capInfo" @click="handleClick" id="edit-taxa-lotacao"
                        :class="`option-card px-4 mt-4 pointer ${edit.show && edit.name == 'edit-taxa-lotacao' ? 'click-border' : ''}`">
                        <v-icon class="icon-position">mdi-arrow-right-thin</v-icon>
                        <p class="text-subtitle-2">Taxa de superlotação máxima</p>
                        <p class="text-caption">Defina o valor da superlotação máxima</p>
                    </div>

                    <!-- default preference -->
                    <div v-if="preferencesOptions" @click="handleClick" id="edit-default-preference"
                        :class="`option-card px-4 pointer ${edit.show && edit.name == 'edit-default-preference' ? 'click-border' : ''}`">
                        <v-icon class="icon-position">mdi-arrow-right-thin</v-icon>
                        <p class="text-subtitle-2">Definir preferências</p>
                        <p class="text-caption">Escolha uma preferência padrão, ou selecione quais turmas ou salas receberão
                            uma
                            preferência personalizada.</p>
                    </div>
                    <!-- split class -->
                    <div v-if="splitClasses" @click="handleClick" id="edit-split-class"
                        :class="`option-card px-4 pointer ${edit.show && edit.name == 'edit-split-class' ? 'click-border' : ''}`">
                        <v-icon class="icon-position">mdi-arrow-right-thin</v-icon>
                        <p class="text-subtitle-2">Dividir turma para diferentes salas</p>
                        <p class="text-caption">Divida uma turma e defina as preferências para
                            cada uma delas.</p>
                    </div>

                    <!-- split class -->
                    <div v-if="updatePaas" @click="handleClick" id="update-paas"
                        :class="`option-card px-4 pointer ${edit.show && edit.name == 'update-paas' ? 'click-border' : ''}`">
                        <v-icon class="icon-position">mdi-arrow-right-thin</v-icon>
                        <p class="text-subtitle-2">Atualizar dados das turmas</p>
                        <p class="text-caption">As informações das turmas serão atualizadas</p>
                    </div>
                </v-col>

                <!-- display img -->
                <v-col v-if="!isMobile && !edit.show" class="center" cols="12" md="8">
                    <v-img class="mt-n4 ml-3 mb-4" :aspect-ratio="1" :width="350" contain
                        src="../../assets/advanced.svg"></v-img>
                </v-col>

                <v-col v-if="edit.show" cols="12" md="8">

                    <!-- taxa de ocupacao -->
                    <div v-if="edit.name == 'edit-taxa-lotacao'" class="mt-3 capInfo" :style="heightCapInfo">

                        <p class="text-subtitle-2">Definir taxa de de superlotação máxima.</p>

                        <div class="option-card px-4 text-caption mt-n2">
                            <p>A taxa de superlotação define o valor máximo de pessoas em uma sala.</p>
                            <p class="mt-n3">Por exemplo: caso a capacidade de uma sala seja de 100 pessoas e a taxa de
                                superlotação esteja definida em 10%, então a sala pode comportar turmas de até 110 pessoas.
                            </p>
                            <p class="mt-n3">Essa taxa pode ser diferente para cada tipo de sala.</p>
                        </div>

                        <div class="px-16 pt-8">


                            <v-slider @end="setAllLotacao" :readonly="!lotacao.all" v-model="lotacao.default" inverse-label
                                max="30" min="0" :label="`${lotacao.default}%`" :color="`${lotacao.all ? 'blue' : 'grey'}`"
                                :track-color="`${lotacao.all ? 'blue' : 'grey'}`">
                                <template v-if="paasInput.roomTypes.length != 0" v-slot:prepend>
                                    <v-checkbox v-model="lotacao.all" class="mt-n1" label="Todos os tipos"></v-checkbox>
                                </template>
                            </v-slider>

                            <v-divider class="mb-10"></v-divider>

                            <p v-if="paasInput.roomTypes.length == 0" class="text-caption">Adicione salas para definir taxas
                                de
                                superlotação para cada tipo de sala.</p>

                            <div v-for="tipo in lotacao.data" :key="tipo.name">
                                <v-slider :disabled="lotacao.all" v-model="tipo.value" max="30" min="0" :label="tipo.name">
                                    <template v-slot:append>
                                        <p>{{ tipo.value }}%</p>
                                    </template>
                                </v-slider>
                            </div>
                        </div>

                        <div>
                            <v-btn @click="saveLotacao" small dark class="float-right blue mr-16">Salvar</v-btn>
                            <v-btn @click="cancelLotacao" small text class="float-right mr-4">Cancelar</v-btn>
                        </div>


                    </div>

                    <!-- default preference -->
                    <div v-else-if="edit.name == 'edit-default-preference'" class="mt-3">

                        <p class="text-subtitle-2">Definir Preferências</p>

                        <div v-if="!preferences.defaultPreference.exib">
                            <!-- Display main card  -->
                            <div class="option-card px-4 text-caption mt-n2">
                                <p class="text-subtitle-2">Atenção!</p>
                                <p class="mt-n3">Por padrão cada turma recebe o valor 1 para cada tipo de sala em
                                    “preferências”,
                                    significando que a turma pode ser alocada em qualquer tipo de sala. </p>
                                <p v-if="!noRooms" class="mt-n3">O mesmo se aplica as salas.</p>
                                <p class="mt-n3">Escolha que tipo de alteração deseja fazer clicando em turmas<span
                                        v-if="!noRooms"> ou salas</span>.</p>
                            </div>

                            <p v-if="paasInput.roomTypes.length < 1" class="text-caption ml-1">Adicione tipos de salas
                                diferentes.
                            </p>
                            <p v-if="paasInput.classes.length == 0" class="text-caption ml-1">Adicione turmas.</p>

                            <v-row v-if="paasInput.roomTypes.length > 1 && paasInput.classes.length != 0" class="mt-4">
                                <v-col class="pl-3 pr-2">
                                    <div class="option-card-2 px-4 text-caption mt-n2">
                                        <p class="text-subtitle-2">Definir preferências padrão ou redefinir preferências</p>
                                        <p class="mt-n3">Ao definir o tipo de sala padrão e seu valor, todas as tumas que
                                            não
                                            tiveram suas
                                            preferências modificadas, serão atualizadas.</p>
                                        <v-btn outlined color="blue"
                                            @click="() => { preferences.defaultPreference.exib = true; preferences.defaultPreference.option = 'classes' }">Turmas</v-btn>
                                        <v-btn v-if="!noRooms" outlined color="blue" class="ml-2"
                                            @click="() => { preferences.defaultPreference.exib = true; preferences.defaultPreference.option = 'rooms' }">Salas</v-btn>
                                    </div>
                                </v-col>
                                <v-col class="pr-3 pl-2">
                                    <div class="option-card-2 px-4 text-caption mt-n2">
                                        <p class="text-subtitle-2">Definir preferências personalizadas</p>
                                        <p class="mt-n3">Defina uma preferência por tipo de salas ou departamento e
                                            selecione
                                            quais turmas ou salas serão modificadas</p>
                                        <v-btn outlined color="blue"
                                            @click="() => openSetPreference('classes')">Turmas</v-btn>
                                        <v-btn v-if="!noRooms" outlined color="blue" class="ml-2"
                                            @click="() => openSetPreference('rooms')">Salas</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>



                        <div v-if="preferences.defaultPreference.exib">

                            <div v-if="paasInput.roomTypes.length > 1 && paasInput.classes.length != 0" class="px-16">

                                <div class="option-card px-4 text-caption mt-n2">
                                    <!-- display message if classes -->
                                    <div v-if="preferences.defaultPreference.option == 'classes'">
                                        <p>Selecione “Tipo de sala exclusivo” se deseja que as tumas modificadas
                                            apenas
                                            sejam alocadas no tipo de sala escolhido.</p>
                                        <p class="mt-n3">Todas as tumas que <strong>não</strong>
                                            tiveram suas
                                            preferências modificadas, serão atualizadas.</p>
                                    </div>
                                    <div v-else>
                                        <p>Selecione “Departamento exclusivo” se deseja que as salas modificadas
                                            apenas aloquem turmas do departamento escolhido.</p>
                                        <p class="mt-n3">Todas as salas que <strong>não</strong> tiveram suas
                                            preferências modificadas, serão atualizadas.</p>
                                    </div>

                                </div>

                                <!-- Reset preferences -->
                                <v-btn v-if="preferences.defaultPreference.option == 'classes'"
                                    @click="() => preferences.defaultPreference.resetClasses = true" small outlined
                                    color="blue" class="mt-4">Restaurar
                                    preferências</v-btn>

                                <v-btn v-else @click="() => preferences.defaultPreference.resetRooms = true" small outlined
                                    color="blue" class="mt-4">Restaurar
                                    preferências</v-btn>


                                <v-select v-if="preferences.defaultPreference.option == 'classes'" dense
                                    v-model="preferences.defaultPreference.tipo" class="mt-8" :items="paasInput.roomTypes"
                                    label="Selecione o tipo de sala" outlined>
                                </v-select>
                                <v-select v-else dense v-model="preferences.defaultPreference.tipo" class="mt-8"
                                    :items="paasInput.departaments" label="Selecione um departamento" outlined>
                                </v-select>

                                <div v-if="preferences.defaultPreference.warning">
                                    <p v-if="preferences.defaultPreference.option == 'classes'"
                                        class="text-caption text-color-red">Selecione um tipo de sala </p>
                                    <p v-else class="text-caption text-color-red"> Selecione um departamento</p>
                                </div>


                                <v-slider v-model="preferences.defaultPreference.value" max="10" min="2" label="Valor">
                                    <template v-slot:append>
                                        <p>{{ preferences.defaultPreference.value }}</p>
                                    </template>
                                </v-slider>

                                <v-checkbox v-if="preferences.defaultPreference.option == 'classes'"
                                    v-model="preferences.defaultPreference.exclusive" class="mt-n1"
                                    label="Tipo de sala exclusivo"></v-checkbox>
                                <v-checkbox v-else v-model="preferences.defaultPreference.exclusive" class="mt-n1"
                                    label="Departamento exclusivo"></v-checkbox>

                                <v-divider class="mb-1"></v-divider>

                                <div v-if="preferences.defaultPreference.tipo != ''">
                                    <p v-if="preferences.defaultPreference.option == 'classes'" class="text-caption">As
                                        turmas
                                        terão preferência para salas
                                        de tipo: {{ preferences.defaultPreference.tipo }}, com valor {{
                                            preferences.defaultPreference.value }}. Os demais tipos terão valor {{
        preferences.defaultPreference.exclusive ? '0' : '1' }}.
                                    </p>
                                    <p v-else>
                                        As salas
                                        terão preferência para o departamnto: {{ preferences.defaultPreference.tipo }}, com
                                        valor {{
                                            preferences.defaultPreference.value }}. Os demais departamentos terão valor {{
        preferences.defaultPreference.exclusive ? '0' : '1' }}.
                                    </p>
                                </div>

                                <div>
                                    <v-btn @click="saveDefaultPreference" small dark
                                        class="float-right blue ">Salvar</v-btn>
                                    <v-btn @click="cancelDefaultPreference" small text
                                        class="float-right mr-4">Cancelar</v-btn>
                                </div>

                            </div>

                            <v-dialog v-model="preferences.defaultPreference.resetClasses" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-subtitle-2 pa-2 pl-5">Você tem certeza que deseja restaurar as
                                        preferências das turmas?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text
                                            @click="() => preferences.defaultPreference.resetClasses = false">
                                            Cancelar</v-btn>
                                        <v-btn color="blue darken-1" text @click="resetPreferenceClasses">Restaurar</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="preferences.defaultPreference.resetRooms" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-subtitle-2 pa-2 pl-5">Você tem certeza que deseja restaurar as
                                        preferências das salas?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text
                                            @click="() => preferences.defaultPreference.resetRooms = false">
                                            Cancelar</v-btn>
                                        <v-btn color="blue darken-1" text @click="resetPreferenceRooms">Restaurar</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>



                        </div>





                    </div>

                    <!-- split class -->
                    <div v-else-if="edit.name == 'edit-split-class'" class="mt-3">
                        <p class="text-subtitle-2">Dividir Turma</p>

                        <div class="option-card px-4 text-caption mt-n2">
                            <p>Algumas turmas necessitam ser divididas em mais de uma sala, como por exemplo para aulas
                                teóricas
                                e práticas. Seja por opção ou necessidade por conta de disponibilidade de salas específicas,
                                aqui
                                você pode definir o horário e as preferências para cada divisão. </p>
                            <p class="mt-n3">
                                Ao confirmar, serão salvas turmas distintas, significando que você poderá editar todas
                                informações das novas turmas.</p>
                            <p class="mt-n3">Será usado o horário para diferenciar a turma.</p>
                        </div>

                        <v-select dense v-model="splitClass.class" class="mt-2" @change="handleSelectSplitClass"
                            :items="classList" item-text="name" item-value="value" label="Selecione a turma" outlined>
                        </v-select>

                        <div v-if="splitClass.class != undefined">
                            <p class="text-caption mt-n4"><strong>Discentes:</strong> {{ splitClass.class.alunos }},
                                <strong>Horário:</strong> {{ splitClass.class.horario }}
                            </p>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field dense outlined label="Horário" :rules="splitClass.rules.horario"
                                        v-model="splitClass.class1.horario">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div @click="() => splitClass.class1.dialog = true" class="slider pt-2 pl-3">
                                        <p class="pb-0 mb-0">Preferências</p>
                                        <v-icon class="float-right mt-n6 mr-2">mdi-tune-variant</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select dense persistent-hint hint="Opcional: escolher sala para esta turma."
                                        v-model="splitClass.class1.pre_alocacao" :items="roomList" label="Pré-alocação"
                                        outlined :menu-props="{
                                            closeOnClick: true,
                                            closeOnContentClick: true,
                                        }">
                                        <template v-slot:prepend-item>
                                            <v-list-item class="text-overline"
                                                @click="() => splitClass.class1.pre_alocacao = ''">Nenhuma</v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-3"></v-divider>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field dense outlined label="Horário" :rules="splitClass.rules.horario"
                                        v-model="splitClass.class2.horario">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div @click="() => splitClass.class2.dialog = true" class="slider pt-2 pl-3">
                                        <p class="pb-0 mb-0">Preferências</p>
                                        <v-icon class="float-right mt-n6 mr-2">mdi-tune-variant</v-icon>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select dense persistent-hint hint="Opcional: escolher sala para esta turma."
                                        v-model="splitClass.class2.pre_alocacao" :items="roomList" label="Pré-alocação"
                                        outlined :menu-props="{
                                            closeOnClick: true,
                                            closeOnContentClick: true,
                                        }">
                                        <template v-slot:prepend-item>
                                            <v-list-item class="text-overline"
                                                @click="() => splitClass.class2.pre_alocacao = ''">Nenhuma</v-list-item>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>

                            <p v-if="splitClass.warning" class="text-color-red">Verifique os dados e tente novamente!</p>

                            <div class="mt-4">
                                <v-btn @click="handleSaveSplitClass" small dark class="float-right blue ">Salvar</v-btn>
                                <v-btn @click="handleCancelSplitClass" small text class="float-right mr-4">Cancelar</v-btn>
                            </div>

                            <v-dialog v-model="splitClass.class1.dialog" persistent max-width="500">
                                <SetPreferences :preferences="splitClass.class1.preferencias"
                                    :close="() => splitClass.class1.dialog = false" />
                            </v-dialog>

                            <v-dialog v-model="splitClass.class2.dialog" persistent max-width="500">
                                <SetPreferences :preferences="splitClass.class2.preferencias"
                                    :close="() => splitClass.class2.dialog = false" />
                            </v-dialog>

                        </div>




                    </div>

                    <div v-else class="center">
                        <p>still working</p>
                        <p>{{ edit.name }}</p>
                    </div>

                </v-col>

            </v-row>
        </div>


    </div>
</template>

<script>
import SetPreferences from '../SetPreferences.vue';
import UpdatePaas from '@/components/paas/advancedOption/UpdatePaas.vue';

export default {
    name: 'AdvancedOptions',
    props: {
        paasInput: Object,
        capInfo: Boolean,
        splitClasses: Boolean,
        preferencesOptions: Boolean,
        noRooms: Boolean,
        updatePaas: Boolean,
        updatePreAloc: Boolean,
    },
    components: {
        SetPreferences,
        UpdatePaas,
    },

    data() {
        return {
            edit: {
                show: false,
                name: ''
            },
            lotacao: {
                all: true,
                default: 15,
                data: [],
            },
            preferences: {
                defaultPreference: {
                    exib: false,
                    option: 'classes',
                    tipo: '',
                    value: 10,
                    exclusive: false,
                    warning: false,
                    resetClasses: false,
                    resetRooms: false,
                },
                setPreference: {
                    exib: false,
                    option: 'classes',
                    preference: [],
                    warning: false,
                    search: '',
                    headers: [
                        { text: 'nome', value: 'all' },
                    ],
                    selected: [],
                }
            },
            splitClass: {
                class: undefined,
                class1: {
                    horario: '',
                    pre_alocacao: '',
                    preferencias: [],
                    dialog: false,
                },
                class2: {
                    horario: '',
                    pre_alocacao: '',
                    preferencias: [],
                    dialog: false,
                },
                warning: false,
                rules: {
                    horario: [
                        v => /([2-7])+[MTN]([1-6])+/.test(v) || "Utilize o formato de horário da UFPB (e.g. 23M12)"
                    ]
                }
            }
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.width < 700;
        },
        classList() {
            return this.paasInput.classes.map((e) => { return { name: `${e.codigo} ${e.turma} - ${e.nome} - ${e.docente}`, value: e } });
        },
        roomList() {
            return this.paasInput.rooms.map((e) => { return `${e.bloco} ${e.nome}` });
        },
        height() {
            return this.$vuetify.breakpoint.height <= 680 ? 680 : this.$vuetify.breakpoint.height;
        },
        heightCapInfo() {
            return `height: ${this.height - 410}px;`;
        },
    },
    methods: {
        handleClick(e) {
            this.edit.name = e.currentTarget.id;

            switch (e.currentTarget.id) {
                case 'edit-taxa-lotacao': {
                    //verify if capInfo is a number or array;
                    if (isNaN(Number(this.paasInput.capInfo))) {
                        this.lotacao.default = 10;
                        this.lotacao.all = false;
                        this.lotacao.data = this.paasInput.capInfo;
                    } else {
                        this.lotacao.default = Number(this.paasInput.capInfo);
                        this.lotacao.all = true;
                        this.updateLotacao(this.paasInput.capInfo, this.paasInput.roomTypes);
                    }
                    break
                }
                case 'edit-default-preference':
                    this.preferences.defaultPreference.tipo = '';
                    this.preferences.defaultPreference.value = 10;
                    this.preferences.defaultPreference.exclusive = false;
                    break
            }
            this.edit.show = true;
        },
        // Taxa de super lotacao
        setAllLotacao(value) {
            this.lotacao.data.forEach(e => {
                e.value = value;
            });
        },
        cancelLotacao() {
            this.edit.show = false;
            this.updateLotacao(this.paasInput.capInfo, this.paasInput.roomTypes);
        },
        updateLotacao(capInfo, roomTypes) {
            this.lotacao.data = [];
            roomTypes.forEach(e => {
                this.lotacao.data.push({
                    name: e,
                    value: capInfo,
                })
            })
        },
        handleCloseUpdatePaas() {
            this.edit.show = false;
            this.edit.name = '';
        },
        handleUpdateClasses(newClasses) {
            this.$emit('updateClasses', newClasses);
        },
        saveLotacao() {
            if (this.lotacao.all) {
                this.$emit('updateCapInfo', this.lotacao.default);
            } else {
                //verify if all values are the same
                if (this.lotacao.data.map(e => e.value).every((val, i, arr) => val === arr[0])) {
                    this.lotacao.default = this.lotacao.data[0].value;
                    this.lotacao.all = true;
                    this.$emit('updateCapInfo', this.lotacao.data[0].value);
                } else {
                    this.$emit('updateCapInfo', this.lotacao.data)
                }
            }
            this.edit.show = false;
        },
        saveDefaultPreference() {
            if (this.preferences.defaultPreference.tipo != '') {
                if (this.preferences.defaultPreference.option == 'classes')
                    this.$emit('updateClassesPreferences', 'update', this.preferences.defaultPreference.tipo, this.preferences.defaultPreference.value, this.preferences.defaultPreference.exclusive);
                else
                    this.$emit('updateRoomsPreferences', 'update', this.preferences.defaultPreference.tipo, this.preferences.defaultPreference.value, this.preferences.defaultPreference.exclusive);
                this.cancelDefaultPreference();
            } else
                this.preferences.defaultPreference.warning = true;

        },
        resetPreferenceClasses() {
            this.$emit('updateClassesPreferences', 'reset', '', 0, false);
            this.cancelDefaultPreference();
        },
        resetPreferenceRooms() {
            this.$emit('updateRoomsPreferences', 'reset', '', 0, false);
            this.cancelDefaultPreference();
        },
        handleSetPreference() {
            if (this.preferences.setPreference.selected.length == 0) {
                this.preferences.setPreference.warning = true;
            }
            else {
                this.preferences.setPreference.warning = false;
                if (this.preferences.setPreference.option == 'classes')
                    this.$emit('updateClassesPreferences', 'updateSelected', this.preferences.setPreference.selected, this.preferences.setPreference.preference, false);
                else
                    this.$emit('updateRoomsPreferences', 'updateSelected', this.preferences.setPreference.selected, this.preferences.setPreference.preference, false);
                this.cancelSetPreference();
            }
        },
        cancelSetPreference() {
            this.edit.show = false;
            this.preferences.setPreference = {
                exib: false,
                option: 'classes',
                preference: [],
                warning: false,
                search: '',
                headers: [
                    { text: 'nome', value: 'all' },
                ],
                selected: [],
            }
        },
        cancelDefaultPreference() {
            this.edit.show = false;
            this.preferences.defaultPreference = {
                exib: false,
                option: 'classes',
                tipo: '',
                value: 10,
                exclusive: false,
                warning: false,
                resetClasses: false,
                resetRooms: false,
            }
        },
        openSetPreference(option) {
            this.paasInput.rooms.forEach(e => {
                e.id = e.bloco + ' ' + e.nome;
            })
            if (option == 'classes') {
                this.preferences.setPreference.option = 'classes';

                let preferences = [];
                this.paasInput.roomTypes.forEach((e) => {
                    preferences.push({
                        name: e,
                        value: 1
                    });
                });

                this.preferences.setPreference.preference = preferences;
            } else {
                this.preferences.setPreference.option = 'rooms';


                let preferences = [];
                this.paasInput.departaments.forEach((e) => {
                    preferences.push({
                        name: e,
                        value: 1
                    });
                });

                this.preferences.setPreference.preference = preferences;
            }
            this.preferences.setPreference.exib = true;
        },
        handleSelectSplitClass(e) {
            this.splitClass.class1.preferencias = JSON.parse(JSON.stringify(e.preferencias));
            this.splitClass.class2.preferencias = JSON.parse(JSON.stringify(e.preferencias));
            this.splitClass.class1.pre_alocacao = e.pre_alocacao;
            this.splitClass.class2.pre_alocacao = e.pre_alocacao;
        },
        //reset split class values
        handleCancelSplitClass() {

            this.edit.show = false;

            let defaultClass = {
                horario: '',
                pre_alocacao: '',
                preferencias: [],
                dialog: false,
            };

            this.splitClass.class = undefined;
            this.splitClass.warning = false;
            this.splitClass.class1 = JSON.parse(JSON.stringify(defaultClass));
            this.splitClass.class2 = JSON.parse(JSON.stringify(defaultClass));
        },
        handleSaveSplitClass() {
            if (this.splitClass.class1.horario != '' && this.splitClass.class2.horario) {

                let newClasses = [JSON.parse(JSON.stringify(this.splitClass.class)), JSON.parse(JSON.stringify(this.splitClass.class))];

                //update class 1
                newClasses[0].horario = this.splitClass.class1.horario;
                newClasses[0].turma += `-[${this.splitClass.class1.horario}]`;
                newClasses[0].pre_alocacao = this.splitClass.class1.pre_alocacao;
                newClasses[0].preferencias = JSON.parse(JSON.stringify(this.splitClass.class1.preferencias));
                newClasses[0].idx = `${newClasses[0].codigo}-${newClasses[0].turma}`;


                //update class 2
                newClasses[1].horario = this.splitClass.class2.horario;
                newClasses[1].turma += `-[${this.splitClass.class2.horario}]`;
                newClasses[1].pre_alocacao = this.splitClass.class2.pre_alocacao;
                newClasses[1].preferencias = JSON.parse(JSON.stringify(this.splitClass.class2.preferencias));
                newClasses[1].idx = `${newClasses[0].codigo}-${newClasses[1].turma}`;

                this.$emit('updateSplitClass', { old: this.splitClass.class, new: newClasses });

                this.splitClass.warning = false;

                this.handleCancelSplitClass();
            } else {
                this.splitClass.warning = true;
            }

        }
    },
    mounted() {



    }
}
</script>

<style scoped>
.div-clear {
    clear: both;
    width: 100%;
}

.test {
    background-color: aqua;
    color: blue;
    overflow-y: scroll;
}

.main-ao {
    padding: 0px 0px 0px 0px;
}

.capInfo {
    overflow: hidden;
    overflow-y: auto;
}

.slider {
    height: 40px;
    border: solid 1px grey;
    border-radius: 4px;
    color: grey;
    cursor: pointer;
}

.img-ao {
    width: 50%;
}

.pointer {
    cursor: pointer;
}

.icon-position {
    color: #2196ff;
    position: absolute;
    right: 10px;
    top: 10px;
}

.click-border {
    border: #2196ff 2px solid;
}

.text-color-red {
    color: red;
}

.option-card {
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 8PX;
    margin-bottom: 10px;
    position: relative;
}

.option-card-2 {
    border: #2196ff 2px solid;
    min-height: 150px;
    padding: 10px;
    border-radius: 8PX;
    margin-bottom: 10px;
    position: relative;
}



.center {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>