<template>
  <Routes>
    <NavItem icon="mdi-television" route="/poh"> Dashboard </NavItem>
    <NavItem icon="mdi-play-circle" route="/poh-simulador"> Simulador </NavItem>
    <NavItem icon="mdi-history" route="/poh-historico"> Histórico </NavItem>

    <NavItem icon="mdi-arrow-left" route="/">
      Voltar para home
    </NavItem>
  </Routes>
</template>

<script>
import Routes from "./Routes";
import NavItem from "./NavItem";

export default {
  name: "PohRoutes",
  components: {
    NavItem,
    Routes,
  },
};
</script>