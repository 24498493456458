<template>

  <div>

    <Header title="Admin"></Header>
    <div class="ctn">
      <div>
        <!-- Open Poh card -->
        <ModuleCard class="modules" bgColor="#1491FF" title="POH - Otimização de Horário"
          subTitle="Planeje as disciplinas de seus próximos períodos. (Admin)" :btn="openPoh"
          :img="require('../assets/modulo-poh-ilu.png')" btnBgColor="white" btnTxtColor="black" />

        <!-- Open Pass card -->
        <ModuleCard class="modules" bgColor="#1491FF" title="PAAS - Alocação de Aulas a Salas"
          subTitle="Decida que aulas ocorrerão em que salas. (Admin)" :btn="openPaas"
          :img="require('../assets/modulo-paas-ilu.png')" btnBgColor="white" btnTxtColor="black" />
      </div>

    </div>
  </div>
</template>

<script>
import { getAdminPohData } from "../api/admin";
import Header from "../components/Header.vue";
import ModuleCard from "../components/ModuleCard.vue";

export default {
  name: 'Admin',
  components: {
    Header,
    ModuleCard,
  },
  data() {
    return {
      analytics: {},
      displayAnalytics: [{}],
      hashCopy: '',
      solution: [],
      displayPlan: false,
      dialog: false,
      hash: '',
      rules: {
        required: value => !!value || 'Digite algo.',
        counter: value => value.length <= 256 || 'Max 256 caracteres',
      },
    }
  },
  computed: {
    windowSize() {
      return this.$vuetify.breakpoint.width - 50;
    },
  },
  methods: {
    openPoh() {
      getAdminPohData();
      this.$router.push({ path: "/poh" });
    },
    openPaas() {
      this.$router.push({ path: "/paas" });
    }
  },
}
</script>

<style scoped>
.ctn {
  padding: 0 10%;
}

.solutions {
  max-height: 260px;
  overflow: scroll;
  overflow-x: hidden;
}

.ta {
  text-align: center;
}

.modules {
  margin: 20px 0;
}

.bg-color-blue {
  background-color: var(--v-primary-base);
}

.pointer {
  cursor: pointer;
}

.close {
  float: right;
  font-size: 30px;
  color: var(--v-primary-base);
  margin-top: -25px;

}

.show-plan {
  border: solid 4px var(--v-primary-base);
  border-radius: 10px;
}
</style>