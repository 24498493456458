<template>
  <div>
    <div v-if="status == 'Unfeasible'">
      <div class="menu">
        <v-icon
          v-if="back"
          class="back-icon ml-8 float-left"
          x-large
          @click="back"
        >
          mdi-arrow-left
        </v-icon>
      </div>

      <NotFound
        type="sorry"
        title="Ops, algo deu errado."
        btn="Nova solução com dados deste plano"
        :btnFunction="openReutilizar"
        :text="
          `Não há uma solução viável para esta alocação. ${
            this.error != '' ? this.error + ' Também pode' : 'Pode '
          } haver um número insuficiente de salas para o número de turmas definidas, ou os parâmetros definidos impedem que haja uma alocação possível. Há muitas outras maneiras de obter planos inviáveis, portanto tome cuidado com as opções que definir.`
        "
      />

      <div class="px-11 pb-11">
        <p class="py-5 text-h6">Salas</p>

        <v-data-table
          :search="search"
          :items-per-page="-1"
          :headers="unfeasibleRoomReader"
          :items="roomList"
          item-key="idx"
          hide-default-footer
          class="elevation-1"
        >
        </v-data-table>

        <p class="py-5 text-h6">Turmas</p>

        <v-data-table
          :search="search"
          :headers="unfeasibleClassHeaders"
          :items="classList"
          :items-per-page="-1"
          item-key="idx"
          hide-default-footer
          class="elevation-1"
        >
        </v-data-table>
      </div>
    </div>

    <div v-else>
      <!-- ============== solution default display ======================  -->
      <div v-show="tab == 0">
        <p v-if="title" class="mt-5 ml-8">{{ title }}</p>
        <section v-if="!roomDetail">
          <div class="menu">
            <v-icon
              v-if="back"
              class="back-icon ml-8 float-left"
              x-large
              @click="back"
            >
              mdi-arrow-left
            </v-icon>
            <div class="pt-2 ml-16">
              <p class="ml-16">{{ plan.title }}</p>
            </div>
            <div
              class="pt-2 pb-4 pointer text-button"
              v-if="show == 'class-list'"
              @click="changeList"
            >
              <v-icon class="float-left ml-8">mdi-door-closed</v-icon>
              <p class="float-left ml-2 mt-n1">
                Visualizar por Salas
              </p>
            </div>
            <div
              class="pt-2 pb-4 pointer text-button"
              v-if="show == 'room-list'"
              @click="changeList"
            >
              <v-icon class="float-left ml-8"
                >mdi-format-list-bulleted-square</v-icon
              >
              <p class="float-left ml-2 mt-n1">
                Visualizar por Disciplinas
              </p>
            </div>

            <div class="float-right mr-8" v-if="tabs.length > 0">
              <ExpandMenu
                :menus="tabs"
                :compact="!isMobile"
                @click-menu="handleTab"
              />
            </div>
          </div>

          <div class="pa-8">
            <!-- only shows if width > 570px -->
            <v-text-field
              v-if="!isMobile"
              class="search"
              dense
              v-model="search"
              outlined
              label="Pesquisar"
              append-icon="mdi-magnify"
            ></v-text-field>

            <div>
              <div v-show="!isMobile && show == 'room-list'">
                <v-data-table
                  :search="search"
                  :items-per-page="-1"
                  :headers="roomHeaders"
                  :items="roomList"
                  item-key="idx"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:item.all="{ item }">
                    <p class="mb-0">{{ item.capacidade }}</p>
                  </template>
                  <template v-slot:item.visualizar="{ item }">
                    <v-btn
                      @click="() => selectRoom(item.idx)"
                      elevation="2"
                      class="mb-0 blue btn-blue"
                    >
                      visualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </div>

              <v-data-table
                v-show="!isMobile && show == 'class-list'"
                :search="search"
                :headers="classHeaders"
                :items="classList"
                :items-per-page="-1"
                item-key="idx"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item.sala="{ item }">
                  <p
                    v-if="item.sala != 'Não Alocado'"
                    @click="() => selectRoom(item.roomIdx)"
                    class="color-blue pointer mb-0"
                  >
                    {{ item.sala }}
                  </p>
                  <p v-else>{{ item.sala }}</p>
                </template>
                <template v-slot:item.all="{ item }">
                  <p class="mb-0">{{ item.turma }}</p>
                </template>
              </v-data-table>
            </div>

            <!-- show mobile tables -->

            <v-text-field
              v-if="isMobile"
              class="search-mobile"
              dense
              v-model="search"
              outlined
              label="Pesquisar"
              append-icon="mdi-magnify"
            ></v-text-field>

            <div v-if="isMobile">
              <v-data-table
                v-if="show == 'class-list'"
                :search="search"
                :headers="classHeadersMobile"
                :items="classList"
                :items-per-page="-1"
                item-key="idx"
                hide-default-footer
                hide-default-header
                class="elevation-1"
              >
                <template v-slot:item.all="{ item }">
                  <div class="tt" @click="() => handleDetail(item)">
                    <div class="div-icon pt-2 float-left">
                      <v-icon class="m-div-icon">mdi-plus</v-icon>
                    </div>

                    <div class="float-left class-title">
                      <p class="mt-2">
                        {{ item.turma + " " + item.nome }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>

            <v-dialog
              v-model="detail.show"
              max-width="400"
              :overlay-opacity="0.7"
            >
              <div class="test">
                <div class="div-title">
                  <p class="detail-title">
                    {{ detail.data.nome }}
                  </p>
                  <p>
                    {{ detail.data.codigo }}
                  </p>
                  <p>turma {{ detail.data.turma }}</p>
                </div>
                <div class="div-content">
                  <div>
                    <p class="mb-0">sala alocada:</p>
                    <p
                      v-if="detail.data.sala != 'Não Alocado'"
                      @click="() => selectRoom(detail.data.roomIdx)"
                      class="text-h6 pointer"
                    >
                      {{ detail.data.sala }}
                      <span
                        ><v-icon class=" "
                          >mdi-arrow-right</v-icon
                        ></span
                      >
                    </p>
                    <p
                      v-if="detail.data.sala == 'Não Alocado'"
                      class="text-h6"
                    >
                      {{ detail.data.sala }}
                    </p>
                    <p class="mb-0">{{ detail.data.docente }}</p>
                    <p>
                      {{ detail.data.departamento }},
                      {{ detail.data.alunos }} alunos.
                    </p>
                    <p class="horario">{{ detail.data.horario }}</p>
                  </div>
                </div>
              </div>
            </v-dialog>

            <div v-if="isMobile">
              <v-data-table
                v-if="show == 'room-list'"
                :search="search"
                :headers="roomHeadersMobile"
                :items="roomList"
                :items-per-page="-1"
                item-key="idx"
                hide-default-footer
                hide-default-header
                class="elevation-1"
              >
                <template v-slot:item.all="{ item }">
                  <div class="tt" @click="() => selectRoom(item.idx)">
                    <div class="div-icon pt-2 float-left">
                      <v-icon class="m-div-icon">mdi-plus</v-icon>
                    </div>
                    <div class="float-left class-title">
                      <p class="mt-2">
                        {{
                          `${item.blocoSala}, ${item.tipo}. Capacidade de
                                                                                    ${item.capacidade} pessoas.`
                        }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
        </section>
        <section v-if="roomDetail">
          <v-icon
            class="back-icon ml-8 float-left"
            x-large
            @click="handleBack"
          >
            mdi-arrow-left
          </v-icon>
          <CalendarView :roomSolution="roomSolution" />
        </section>
      </div>

      <div v-show="tab == 3">
        <v-btn
          v-if="!isMobile"
          @click.stop="tab = 0"
          class="float-right mr-8"
          outlined
          color="blue"
          small
        >
          <v-icon left dark> mdi-arrow-left </v-icon>voltar a alocação
        </v-btn>
        <v-btn
          v-else
          @click.stop="tab = 0"
          class="float-right mr-4 mt-2"
          outlined
          color="blue"
          rounded
          small
        >
          <v-icon left dark> mdi-arrow-left </v-icon>
        </v-btn>
        <HandleSearchEmpty
          :loading="!tabs_data.ready"
          :matrix_rate="tabs_data.matrix_ocuppancy_rate"
          :cols="tabs_data.cols"
          :rows="tabs_data.rows"
          :matrix_search_empty="tabs_data.matrix_search_empty"
          :rooms_qtd="roomList.length"
          @click-plus="selectRoom"
        />
      </div>

      <div v-show="tab == 2">
        <v-btn
          @click.stop="tab = 0"
          class="float-right mr-8"
          outlined
          color="blue"
          small
        >
          <v-icon left dark> mdi-arrow-left </v-icon>voltar a alocação
        </v-btn>
        <HandleOcupacao
          :loading="!tabs_data.ready"
          :matrix_rate="tabs_data.matrix_ocuppancy_rate"
          :cols="tabs_data.cols"
          :rows="tabs_data.rows"
          :chart1="tabs_data.chart1"
          :chart2="tabs_data.chart2"
          :max_value="tabs_data.max_value"
          :times_with_max_value="tabs_data.times_with_max_value"
          :matrix_search="tabs_data.matrix_search_occupancy"
          :matrix_search_empty="tabs_data.matrix_search_empty"
          @click-plus="selectRoom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CalendarView from "./CalendarView.vue";
import NotFound from "@/components/NotFound.vue";
import ExpandMenu from "@/components/ExpandMenu.vue";
import HandleOcupacao from "@/handles/paas/HandleOcupacao.vue";
import HandleSearchEmpty from "@/handles/paas/HandleSearchEmpty.vue";
import { getOccupancyData } from "@/utils/PaasOccupancyRate";

import { handleReuseSolutionDirect } from "@/api/paas";
import latinize from "latinize";

export default {
  name: "PaasSolution",
  components: {
    CalendarView,
    NotFound,
    ExpandMenu,
    HandleOcupacao,
    HandleSearchEmpty
  },
  props: {
    title: String,
    plan: Object,
    back: Function,
    initRoom: Number,
    status: String,
    occupancyRate: {
      type: Boolean,
      default: false
    },
    searchEmptyRoom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      error: "",
      roomDetail: false,
      roomSolution: [],
      show: "class-list",
      search: "",
      roomList: [],
      classList: [],
      roomHeaders: [
        { text: "Bloco", value: "bloco", align: "start" },
        { text: "Sala", value: "sala" },
        { text: "Tipo", value: "tipo" },
        { text: "Capacidade", value: "all" },
        { text: "", value: "visualizar", align: "end" }
      ],
      roomHeadersMobile: [
        { text: "BlocoSala", value: "all", align: "start" }
      ],
      classHeaders: [
        { text: "Código", value: "codigo", align: "start" },
        { text: "Turma", value: "all" },
        { text: "Nome", value: "nome" },
        { text: "Horário", value: "horario" },
        { text: "Discentes", value: "alunos" },
        { text: "Departamento", value: "departamento" },
        { text: "Sala Alocada", value: "sala" },
        { text: "Docente", value: "docente" }
      ],
      classHeadersMobile: [
        { text: "Nome", value: "all", align: "start" }
      ],
      unfeasibleRoomReader: [
        { text: "Bloco", value: "bloco", align: "start" },
        { text: "Sala", value: "sala" },
        { text: "Capacidade", value: "capacidade" },
        { text: "Acessibilidade", value: "acessivel" }
      ],
      unfeasibleClassHeaders: [
        { text: "Código", value: "codigo", align: "start" },
        { text: "Turma", value: "turma" },
        { text: "Nome", value: "nome" },
        { text: "Docente", value: "docente" }
      ],
      detail: {
        show: false,
        data: []
      },
      tab: 0,
      stack: [],
      tabs_data: {
        ready: false
      }
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
    tabs() {
      let res = [];

      if (this.searchEmptyRoom)
        res.push({
          id: 2,
          title: "Sala disponível",
          icon: "mdi-magnify"
        });

      if (this.occupancyRate)
        res.push({
          id: 1,
          title: "Ocupação",
          icon: "mdi-chart-bar"
        });
      return res;
    }
  },
  methods: {
    handleTab(i) {
      this.tab = i + 1;
    },
    handleBack() {
      this.tab = this.stack.pop(); 
      this.roomDetail = false;
    },
    changeList() {
      this.show =
        this.show == "room-list" ? "class-list" : "room-list";
    },
    selectRoom(idx) {
      this.roomSolution = this.plan.solution[idx];
      this.roomDetail = true;
      this.detail.show = false;
      this.stack.push(this.tab);
      this.tab = 0;
    },
    handleDetail(item) {
      this.detail = {
        show: true,
        data: item
      };
    },
    openReutilizar() {
      handleReuseSolutionDirect(
        this.plan,
        "(reutilizada) Nova entrada"
      );
      this.$router.push({ path: "/paas-simulador" });
    },
    init() {
      this.roomList = this.plan.solution.map((el, idx) => {
        let classDataLatinaze = latinize(
          `${el.bloco} ${el.nome} ${el.tipo}`
        );
        return {
          bloco: el.bloco,
          sala: el.nome,
          tipo: el.tipo,
          blocoSala: el.bloco + " " + el.nome,
          capacidade: el.capacidade,
          idx: idx,
          acessivel: el.acessivel ? "sim" : "não",
          all: `${el.bloco} ${el.nome} ${el.tipo} ${classDataLatinaze}`
        };
      });

      let removeNotAlloc = this.roomList.findIndex(
        e => e.blocoSala == "Não Alocado"
      );
      if (removeNotAlloc != -1)
        this.roomList.splice(removeNotAlloc, 1);

      this.plan.solution.forEach((el, idx) => {
        el.classes.forEach(e => {
          let classDataLatinaze = latinize(
            `${e.nome} ${e.docente} ${el.bloco + " " + el.nome}`
          );

          this.classList.push({
            ...e,
            sala: el.bloco + " " + el.nome,
            roomIdx: idx,
            all: `${e.codigo} ${e.nome} ${e.docente} ${el.bloco +
              " " +
              el.nome} ${classDataLatinaze}`
          });
        });
      });

      if (this.plan.error != undefined) this.error = this.plan.error;

      this.classList.sort(function(a, b) {
        return a.nome.localeCompare(b.nome);
      });

      if (this.initRoom != undefined && this.initRoom > -1) {
        this.selectRoom(this.initRoom);
      }

      this.roomList = JSON.parse(JSON.stringify(this.roomList));
      this.classList = JSON.parse(JSON.stringify(this.classList));
    }
  },
  mounted() {
    this.init();
    setTimeout(() => {
      let data = getOccupancyData(this.plan);

      this.tabs_data = {
        ready: true,
        ...data
      };

    }, 2000);
  },
  created() {}
};
</script>

<style scoped>
.search {
  width: 30%;
}

.search-mobile {
  width: 100%;
}

.search-fild {
  border: 0px;
}

.back-icon {
  color: var(--v-primary-base);
}

.menu {
  width: 100%;
  min-height: 30px;
}

.pointer {
  cursor: pointer;
}

.color-blue {
  color: #2962ff;
}

.btn-blue {
  background: rgb(71, 118, 230);
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  font-size: 12px;
  color: #eaeaea;
}

.m-name {
  width: 80%;
}

.m-div-icon {
  margin-right: 100px;
  float: left;
  color: #eaeaea;
  width: 30px;
  height: 30px;
  font-size: 15px;
  border-radius: 15px;
  background: rgb(71, 118, 230);
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  /* background: linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%); */
}

.tt {
  display: flex;
  width: 100%;
}

.div-icon {
  width: 40px;
}

.class-title {
  width: 80%;
  text-align: left;
  font-size: 100%;
}

.m-icon {
  position: relative;
  right: 0;
}

/* detail card */
.detail-title {
  font-size: 100%;
  word-break: keep-all;
}

.test {
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(71, 118, 230, 1) 40%,
    white 0%
  );
  /* background-color: #2962FF; */
  min-height: 220px;
}

.div-title {
  padding: 10px;
  width: 40%;
  float: left;
  color: #eaeaea;
  /* background: rgb(71, 118, 230);
    background: linear-gradient(90deg, rgba(20,145,255,1) 0%, 50deg, rgba(71,118,230,1)); */
}

.horario {
  background: rgb(71, 118, 230);
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  width: 80px;
  color: #eaeaea;
  text-align: center;
  border-radius: 10px;
}

.div-content {
  padding: 10px;
  width: 60%;
  float: left;
}
</style>
