export const moduleComponents = {
  state: {
    current_component: "None"
  },

  mutations: {
    setCurrentComponent(state, payload) {
      state.current_component = payload.component;
    }
  },

  getters: {
    isNone: state => {
      return state.current_component === "None" || state.current_component === undefined;
    },

    isPaas: state => {
      return state.current_component === "Paas";
    },

    isPoh: state => {
      return state.current_component === "Poh";
    },
    isPublic: state => {
      return state.current_component === "Public";
    }
  }
};
