<template>
  <v-list dense nav>
    <v-list-item-group active-class="primary white--text">
      <slot />
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "Routes",
};
</script>