export const modulePaas = {
    state: {
      /** @type Array<{hash: String, status: String, date: Date, description: String}> */
      historico: [],
      requestData: [],
      editSaveList: [],
      paasInputPlaceHolder: {
        id: '',
        name: 'Nova Simulação',
        rooms: [],
        classes: [],
        roomTypes: [],
        departaments: [],
        capInfo: 10,
      }
    },
  
    actions: {
      addToHistoricoPAAS({ state, getters, rootGetters }, payload) {
        
        const now = new Date();
        const date = `${now.toLocaleDateString(
          "pt-BR"
        )} ${now.toLocaleTimeString("pt-BR")}`;
        const description = `Plano gerado com ${payload.numRooms} salas e ${payload.numClasses} disciplinas.`;
  
        // Try to find equal plan
        const index = state.historico.findIndex(
          el => el.hash === payload.hash
        );
  
        // If an equal plan is already in array, only update date/description
        if (
          index >= 0 &&
          state.historico[index].user === rootGetters.getIdVinculo
        ) {
          state.historico[index].description = description;
          state.historico[index].date = date;
        }
        // Else add new item
        else {
          const id =
            getters.getHistoricoPAAS.length === 0
              ? 1
              : getters.getHistoricoPAAS[0].id + 1;
  
          state.historico.unshift({
            id,
            hash: payload.hash,
            nome: payload.nome,
            status: payload.status,
            numRooms: payload.numRooms,
            numClasses: payload.numClasses,
            date: date,
            description: description,
            user: rootGetters.getIdVinculo
          });
        }
      },
  
      removeFromHistoricoPAAS({ state, rootGetters }, payload) {
        const foundIndex = state.historico.findIndex(
          el =>
            el.hash == payload.hash &&
            el.user == rootGetters.getIdVinculo
        );
        if (foundIndex > -1) state.historico.splice(foundIndex, 1);
      },

      setPaasInputPlaceholder({ state }, payload) {
        state.paasInputPlaceHolder = payload;
      },

      restorePaasInputPlaceholder({ state }) {
        state.paasInputPlaceHolder = {
          id: '',
          name: 'Nova Simulação',
          rooms: [],
          classes: [],
          roomTypes: [],
          departaments: [],
          capInfo: 10
        }
      },

      setRequestData({state}, payload) {
        let requests = JSON.parse(JSON.stringify(state.requestData));
        let found = requests.findIndex((e) => e.hash == payload.hash);
        if(found != -1){
          requests[found].classes = payload.classes;
          requests[found].rooms = payload.rooms;
        }else{
          requests.push(payload);
        }

        state.requestData = requests;
      },

      removeRequestData({state}, payload) {
        let requests = JSON.parse(JSON.stringify(state.requestData));
        let found = requests.findIndex((e) => e.hash == payload.hash);
        if(found != -1){
          requests.splice(found, 1);
        }
        state.requestData = requests;
      },

      // save edit list paas

      updateEditSave({state}, payload) {

        const now = new Date();
          const date = `${now.toLocaleDateString(
            "pt-BR"
          )} ${now.toLocaleTimeString("pt-BR")}`;

        let found = state.editSaveList.findIndex(e => e.id == payload.id);
        if((payload.id != undefined && payload.id != '') && found != -1) {

          delete payload.id;
          state.editSaveList[found].input = JSON.parse(JSON.stringify(payload))
          state.editSaveList[found].date = date;

        }else{

          delete payload.id;

          state.editSaveList.push({
            id: date,
            date: date,
            input: JSON.parse(JSON.stringify(payload))
          });
        }
      },  
      removeEditSaveByIdx({state}, payload) {
        state.editSaveList.splice(payload.idx, 1);      
        
      },  
    },
  
    mutations: {
      updateHistoricoPAAS(state, payload) {
        for (let i = 0; i < state.historico.length; i++) {
          if (state.historico[i].hash == payload.hash) {
            // Can't set directly at index because Vue can't detect this kind of change
            // see https://vuejs.org/v2/guide/reactivity.html#For-Arrays
            state.historico.splice(i, 1, payload);
          }
        }
      },
      updateWaitingPlansPOH(state, payload) {
        state.waitingPlans = payload;
      }
    },
  
    getters: {
      getHistoricoPAAS: (state, _getters, _rootState, rootGetters) => {
        return state.historico.filter(
          el => rootGetters.getIdVinculo === el.user
        );
      },
      getWaitingPAAS: (state, _getters, _rootState, rootGetters) => {
        return state.historico.filter(
          el => rootGetters.getIdVinculo === el.user
        ).length;
      },
      getPaasInputPlaceholder: state => {
        return state.paasInputPlaceHolder;
      },
      getRequestData: state => {
        return state.requestData;
      },
      getEditSaveList: state => {
        return state.editSaveList;
      }
    }
  };
  